<template>
  <div style="width: 95%;margin: 10px auto">
<!--    <div style="display: flex">-->
<!--      <div style="width: 18%;margin-right: 10px">-->
<!--      -->
<!--      </div>-->
<!--      <div style="width: 80%">-->

<!--      </div>-->
<!--    </div>-->

    <v-row>
      <v-col sm="2" lg="2" style="padding-bottom: 0;margin-bottom: 0">
        <v-menu v-if="$store.state.user.type !== 'report_guest'"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              :label="$t('date')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
            ></v-text-field>
          </template>
          <v-card>
            <strong>{{$t("select_span_meals_listing") }}</strong ><br />
            {{ $t("click_on_two_dates") }}<br />
            <v-date-picker v-model="date" range no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date);load();"> OK </v-btn>
            </v-date-picker>
          </v-card>
        </v-menu>
        <div v-if="$store.state.user.type === 'report_guest'" style="height: 100%">
          <span v-if="tab == 0" id="rest-days-main" style="display: flex;justify-content: space-between;align-items: center;height: 100%">
            <a style="position: relative" @click.prevent="selectDay('today', date[0])" :class="activeTab[0] ? 'activeTab' : ''" class="rest-days" href="">
              {{ $t('today')}} <span v-if="ukupnoPorudzbi && ukupnoPorudzbi.length > 0" style="position: absolute;top: -10px;right: -6px;background: red;color: rgb(255, 255, 255);padding: 1px 8px;border-radius: 10px;">{{ ukupnoPorudzbi ? ukupnoPorudzbi.length : ''}}</span>
            </a>
            <a @click.prevent="selectDay('tomorrow', date[0])" :class="activeTab[1] ? 'activeTab' : ''" class="rest-days" href="">{{ $t('tomorrow')}}</a>
            <a @click.prevent="selectDay('rest_days', date[0])" :class="activeTab[2] ? 'activeTab' : ''" class="rest-days" href="">{{ $t('days_rest')}}</a>
          </span>
          <v-menu v-if="tab == 1"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  :label="$t('date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
              ></v-text-field>
            </template>
            <v-card>
              <strong>{{$t("select_span_meals_listing") }}</strong ><br />
              {{ $t("click_on_two_dates") }}<br />
              <v-date-picker v-model="date" range no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date);load();"> OK </v-btn>
              </v-date-picker>
            </v-card>
          </v-menu>
        </div>
      </v-col>
      <v-col sm="2" lg="2" cols="" style="padding-bottom: 0;margin-bottom: 0">
      <v-autocomplete v-if="$store.state.user.type == 'admin'" style="padding: 17px 0 0 0"
        v-model="entity"
        :items="[
            {id: '', name: 'Svi'},
            {id: 'restaurant', name: 'Restorani'},
            {id: 'shop', name: 'Trgovina'},
        ]"
        dense
        @input="filterRestaurantByEntity"
        :label="translate('Изаберите тип')"
        item-text="name"
        item-value="id"
      >
        <v-btn color="primary" slot="prepend-item" small class="mx-4" @click="toogleRestorants1()">
          {{$t("display_all")}}
        </v-btn>
      </v-autocomplete>
      </v-col>
      <v-col sm="8" lg="8" cols="" style="padding-bottom: 0;margin-bottom: 0">
        <v-row style="margin-top: -2px">
          <v-autocomplete style="margin-top: 23px;width:23%;margin-right: 10px"
            v-model="status_id"
            :items="tab == 1 ? [
                {id: 0, name: 'all'},
                {id: 1, name :'ordered_meal1', alias: 'ordered_meal1'},
                {id: 2, name :'prepared_for_delivery1', alias: 'prepared_for_delivery1'},
                {id: 4, name :'ready', alias: 'ready'},
                {id: 6, name :'delivered_11', alias: 'delivered_11'},
                {id: 10, name :'payed', alias: 'payed'},
                {id: 7, name :'declined_by_restoran1', alias: 'declined_by_restoran1'},
                {id: 99, name :'undefined', alias: 'undefined'}
            ] : [
              {id: 0, name: 'all'},
              {id: 1, name: 'ordered_meal1'},
              {id: 2, name: 'prepared_for_delivery1'},
              {id: 3, name: 'booked_by_delivery'},
              {id: 4, name: 'ready'},
              {id: 5, name: 'taken_by_delivery1'},
              {id: 6, name: 'delivered_11'},
              {id: 10, name: 'payed'},
              {id: 7, name: 'declined_by_restoran1'},
              {id: 8, name: 'delivery_cancel'},
              {id: 9, name: 'order_not_taken1'},
            ]"
            dense
            :label="$t('group_meal2')"
            return-object
            :item-text="getItemText"
            item-value="id" @change="filterByStatus()">
          </v-autocomplete>
          <v-autocomplete v-if="tab != 1" style="margin-top: 23px;width:30%;margin-right: 10px"
            v-model="delivery_id"
            :items="[
              {id: 3, name: 'all'},
              {id: 0, name: translate('Достава на адресу')},
              {id: 1, name: translate('Преузимање у ресторану')},
              {id: 2, name: translate('Конзумација у ресторану')},
              // {id: 4, name: 'QR ' + translate('Столови')},
              {id: 5, name: translate('Брза пошта')},
            ]"
            dense
            :label="translate('Место испоруке')"
            return-object
            :item-text="getItemText"
            item-value="id" @change="filterByDelivery()">
          </v-autocomplete>
          <v-row class="px-0" id="pretraga-ikonice"  style="width:30%;margin-left: 10px;padding-right: 20px">
            <v-text-field style="width: 40%"
                class="pt-8"
                v-model="pretraga"
                append-icon="mdi-magnify"
                :label="$t('search')"
                single-line
                hide-details
                dense
            ></v-text-field>
            <div id="stampa" class="mt-7 ml-2 text-right">
              <a v-if="tab != 1" @click.prevent="table_display = 1" href=""><v-icon :style="table_display == 1 ? 'color:#000' : ''" style="font-size: 18px" class="mr-1 mt-3">mdi-table</v-icon></a>
              <a v-if="tab != 1" @click.prevent="table_display = 2" href=""><v-icon :style="table_display == 2 ? 'color:#000' : ''" style="font-size: 18px" class="mr-1 mt-3">mdi-tab</v-icon></a>
              <v-btn v-if="tab == 1" @click.prevent="openDesk()" :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" small class="mt-2 mr-2">
                <v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background" class="mr-2">mdi-desk</v-icon>
                <span id="stampaj">
                  {{ $t("table") }}
                </span>
              </v-btn>
              <v-btn  @click="print(printTable)" :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" small class="mt-2">
                <v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background" class="mr-2">mdi-printer</v-icon>
                <span id="stampaj">
                  {{ $t("print") }}
                </span>
              </v-btn>
            </div>
          </v-row>
        </v-row>
      </v-col>
      <div style="width: 100%">
      <v-autocomplete v-if="$store.state.user.type == 'admin'"
        v-model="restaurant_ids"
        :items="all_restaurants"
        dense
        filled
        multiple
        @input="filterRestaurant"
        :label="$t('select_restoran')"
        item-text="name"
        item-value="id"
        clear
        chips
        small-chips
      >
        <v-btn color="primary" slot="prepend-item" small class="mx-4" @click="toogleRestorants1()">
          {{$t("display_all")}}
        </v-btn>
      </v-autocomplete>
      </div>
      <v-card style="width: 100%" elevation="0">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab
            @click="
              printTable = item.print;
              name = item.tab;
              selectAllFirms(item.tab)
            "
            v-for="(item, indexX) in items1"
            :key="item.tab + indexX"
          >
            <v-icon style="margin-right: 7px">{{ item.icon }}</v-icon>
            {{ translate(item.tab) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items id="izv" v-model="tab">
          <!-- KUPCI -->
          <v-tab-item>
            <v-card v-if="$store.state.user.type === 'report_guest' || $store.state.user.type === 'restaurant'" flat>
              <div v-if="table_display == 1">
                <v-card-text>
                  <v-autocomplete v-if="noContractCompanies.length > 1"
                    v-model="search2"
                    :items="noContractCompanies"
                    multiple
                    dense
                    filled
                    @input="findMeals"
                    :label="$t('select_company')"
                    item-text="company"
                    item-value="id"
                    clear
                    chips
                    small-chips
                  >
                    <v-btn color="primary" slot="prepend-item" @click="toggle" small class="mx-4">
                      {{$t("display_all")}}
                    </v-btn>
                    <v-divider slot="prepend-item" class="mt-2" />
                  </v-autocomplete>
                  <template v-for="(m, i) in noContract">
                    <div class="d-flex align-items-center mb-4" style="justify-content: space-between;">
                      <h2 v-if="$store.state.user.type != 'report_guest' && $store.state.user.type !== 'restaurant'">{{ i }}</h2>
                      <h2 v-else></h2>
  <!--                    <div v-if="$store.state.user.type != 'report_guest' || $store.state.user.type !== 'restaurant'" class="text-right font-ikonice">-->
  <!--                      <v-icon style="margin-left: 11px">mdi-truck-fast</v-icon>  {{ $t('delivery_on_address')}}-->
  <!--                      <v-icon style="margin-left: 11px">mdi-gift</v-icon>  {{ $t('takeover')}}-->
  <!--                      <v-icon style="margin-left: 11px">mdi-silverware-variant</v-icon>  {{ $t('meal_in_restaurant')}}-->
  <!--                      <v-icon style="margin-left: 15px">mdi-cube-send</v-icon>  {{ $t('send_by_mail')}}-->
  <!--                    </div>-->
                    </div>
                    <template v-for="(meals, inx) in noContract[i]">
                      <h3 class="mb-2"><v-icon small>mdi-calendar</v-icon> {{ formatDate(inx) }}</h3>
                      <v-data-table
                        :search="pretraga"
                        :item-key="inx"
                        id="table14"
                        :headers="headersG"
                        :items="meals"
                        class="elevation-1 my-4"
                        mobile-breakpoint="0"
                      >
                        <template v-for="header in headersG" v-slot:[`header.${header.value}`]="{ header }">
                          {{ translate(header.text) }}
                          <span v-if="header.text == 'Poručeno' || header.text == 'Поручено'" class="d-flex flex-wrap" style="margin-top: -14px;justify-content: space-between;text-align: right;font-size: 10px;font-weight: 700;width: 280px">
                            <span style="width: 40%"></span>
                            <span style="width: 25%;font-weight: 700;margin-right: -32px">
                              {{ $t('amount') }}
                              {{ $store.state.restaurant.settings && $store.state.restaurant.settings.price ? "(" + $store.state.restaurant.settings.price + ")" : '' }}
                            </span>
                          </span>
                        </template>
                        <template v-slot:item.num="{ item, index }">
                          <span v-if="!item.total_price">#</span>
                          {{ item.menu !== "УКУПНО" ? translate(item.orders_guest[0].id) : "" }}
                        </template>
                        <template v-slot:item.name="{ item, index }">
                          <span style="display: block" :key="index" @click="showUsers(item)">{{ (item.orders_guest[0].delivery_type == 3 ? capitalizeFirstLetter(item.name) : capitalizeFirstLetter(item.name).charAt(0)) + '. ' + capitalizeFirstLetter(item.lastname) }}</span>
                          <span style="font-size: 11px;display: block;color: #333">{{ item.phone }}</span>
                        </template>
                        <template v-slot:item.address="{ item }">
                          <span class="order-address" style="text-align: left;display: block;width: 100%">
                             <span class="d-flex align-center" v-if="item.orders_guest[0].delivery_type == 4">
                               <img style="display: block;text-align: center;padding-right: 5px" src="https://app.ordera.app/gallery/qr-menu.png" width="38" alt="">
                               QR {{ $t('table') }}
                            </span>
                            <span v-if="item.orders_guest[0].delivery_type == 3">
                              <span class="location d-flex align-center" style="font-size: 12px;text-align: left">
                                <span>
                                  <v-icon v-if="item.orders_guest[item.orders_guest.length - 1].location" style="font-size: 23px;margin-right: 5px;color: #A9A9A9" small>mdi-cube-send </v-icon> {{ item.orders_guest[item.orders_guest.length - 1].location && item.orders_guest[item.orders_guest.length - 1].location.address ? item.orders_guest[item.orders_guest.length - 1].location.address.replace(', Bosnia and Herzegovina', '').replace(', Bosna i Hercegovina', '').replace(', Босна и Херцеговина', '') : '' }}
                                  <span style="display: block" v-if="item.orders_guest[0].delivery_type == 3 && item.orders_guest[item.orders_guest.length - 1].location && item.orders_guest[item.orders_guest.length - 1].location.city">{{ translate(item.orders_guest[item.orders_guest.length - 1].location.city.name) }}</span>
                                </span>
                              </span>
                            </span>
                            <span class="d-flex align-center" v-if="item.orders_guest[0].delivery_type == 2">
                              <v-icon style="margin-right: 7px;text-align: left;margin-right: 5px;color: #8B0000">mdi-silverware-variant</v-icon>
                              {{ $t('meal_in_restaurant') }}
                            </span>
                            <span class="d-flex align-center" v-if="item.orders_guest[0].delivery_type == 1">
                               <v-icon style="margin-right: 7px;display: block;text-align: left;margin-right: 5px;color: #FFD700">mdi-gift</v-icon>
                              {{ $t('takeover') }}
                            </span>
                            <span v-if="item.orders_guest[0].delivery_type == 0">
                              <span class="location d-flex align-center" style="font-size: 12px;text-align: left">
                                <v-icon v-if="item.orders_guest[item.orders_guest.length - 1].location" style="font-size: 23px;margin-right: 5px;color: #4CAF50" small>mdi-truck-fast </v-icon> {{ item.orders_guest[item.orders_guest.length - 1].location && item.orders_guest[item.orders_guest.length - 1].location.address ? item.orders_guest[item.orders_guest.length - 1].location.address.replace(', Bosnia and Herzegovina', '').replace(', Bosna i Hercegovina', '').replace(', Босна и Херцеговина', '') : '' }}
                              </span>
                            </span>
                          </span>
                        </template>
                        <template v-slot:item.time="{ item }">
                          <span class="location">
                            {{ formatTime(item.orders_guest[0].delivery_time) }}
                          </span>
                        </template>
                        <template v-slot:item.state="{ item }">
  <!--                        :style="item.orders_guest[0].order_guest_status.status == 1 ? 'background: #e0e0e0;color:#333' : item.orders_guest[0].order_guest_status.status == 2 ? 'background: #4caf50;color:#fff' : item.orders_guest[0].order_guest_status.status == 3 ? 'background: #2196f3;color:#fff' : item.orders_guest[0].order_guest_status.status == 4 ? 'background: #2196f3;color:#fff' : item.orders_guest[0].order_guest_status.status == 5 ? 'background: rgb(180 0 255);color:#fff' : item.orders_guest[0].order_guest_status.status == 6 ? 'background: #4caf50;color:#fff' : item.orders_guest[0].order_guest_status.status == 7 ? 'background: #4caf50;color:#fff' : item.orders_guest[0].order_guest_status.status == 8 ? 'background: #F45A4E;color:#fff' : item.orders_guest[0].order_guest_status.status == 9 ? 'background: #F45A4E;color:#fff' : ''"-->
                          <span  class="location">
                            <a class="status-change"
                               :style="item.orders_guest[0].state == 'on-hold' ? 'background: #e0e0e0;color:#333' : item.orders_guest[0].state == 'accepted' ? 'background: #4caf50;color:#fff' : item.orders_guest[0].state == 'booked-delivery' ? 'background: #2196f3;color:#fff' : item.orders_guest[0].state == 'ready' ? 'background: #2e7d32;color:#fff' : item.orders_guest[0].state == 'charged-delivery' ? 'background: rgb(180 0 255);color:#fff' : item.orders_guest[0].state == 'delivered' ? 'background: #4caf50;color:#fff' : item.orders_guest[0].state == 'rejected' ? 'background: #f45a4e;color:#fff' : item.orders_guest[0].state == 'delivery-canceled' ? 'background: #F45A4E;color:#fff' : item.orders_guest[0].state == 'client-refused' ? 'background: #F45A4E;color:#fff' : ''"
                               @click.prevent="openDialogChangeOrderStatus(item.orders_guest[0])" href="">
                              <span v-if="state[state.map((stat) => stat.name).indexOf(item.orders_guest[0].state)]">
                                {{ item.orders_guest[0].state ? $t(state[state.map((stat) => stat.name).indexOf(item.orders_guest[0].state)].alias) : '' }}
                              </span>
                               <span v-if="state[state.map((stat) => stat.id).indexOf(item.orders_guest[0].state)]">
                                {{ item.orders_guest[0].state ? $t(state[state.map((stat) => stat.id).indexOf(item.orders_guest[0].state)].alias) : '' }}
                              </span>
                            </a>
                            <span style="color: red;display: inline-block;text-align: left" v-if="item.orders_guest[0].state && item.orders_guest[0].state == 'deleted'">Obrisan obrok</span>
                          </span>
                        </template>
                        <template v-slot:item.qty="{ item }">
                          <template v-if="orders.order_guest_transactions && orders.order_guest_transactions.length < 3" v-for="(orders,index) in item.orders_guest">
                            <template v-for="(order, ii) in orders.order_guest_transactions">
                              <div class="d-flex justify-content-center align-content-center w-100 all-orders">
                                <div :title="order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : ''" class="all-orders-item mt-2">
                                  {{ order.quantity }}x <span class="text-uppercase">{{ order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : '' }}</span>
                                </div>
                                <div class="all-orders-qty mt-2">
                                  {{ order.meals || order.food ? order.meals ? (order.meals.price * order.quantity).toFixed(2) : (order.food.price * order.quantity).toFixed(2) : '' }}
                                </div>
                              </div>
                              <div v-if="order.prilog.length" v-for="(prilog, i) in order.prilog">
                                <div v-if="prilog.quantity > 0 && prilog.price > 0" style="border-bottom: 1px dashed #cfcfcf;color:#696969" class="d-flex mt-2 justify-content-center align-content-center w-100">
                                  <div style="width: 52%;font-size: 12px;padding-bottom: 2px;color: #818181">{{ prilog.quantity }}x {{ translate(prilog.food.name) }}</div>
                                  <div style="width: 47%;text-align: right;font-size: 12px;padding-bottom: 2px">{{ (prilog.quantity * prilog.price).toFixed(2) }}</div>
                                </div>
                              </div>
                            </template>
                            <!-- DUGME ZA OPEN DIALOG-->
                            <div style="position:relative;">
                              <a v-if="! item.total_price" :title="$t('order_details')" @click.prevent="openDialogDetails(orders, item, inx)" style="display: block;text-align: left;position: absolute;top: 23px;text-decoration: none" href="">
                                <v-icon style="color: #fb6d3b;padding: 2px;border-radius: 6px">mdi-printer</v-icon> {{ $t('order_details')}}
                              </a>
                            </div>
                            <!-- CENA DOSTAVE-->
                            <div style="border-bottom: 1px solid #cfcfcf;" v-if="canSeeDelivery(item.orders_guest, item)" class="d-flex justify-content-center align-content-center w-100">
                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
                              <div style="width: 70%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">
                                + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
                              </div>
                            </div>
                            <!-- CENA-->
                            <div class="d-flex justify-content-center py-4 align-content-center w-100">
                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 700;"></div>
                              <div style="width: 68%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 700">
                                <span v-if="! item.total_price">{{ priceMeals(item.orders_guest, item) }}</span>
                                <span v-else>{{ (item.price).toFixed(2) }}</span>
                              </div>
                            </div>
                          </template>
                          <template style="width: 100%" v-else>
                            <div v-if="item.orders_guest.length" class="all-order-else d-flex justify-content-center align-content-center w-100 all-orders">
                              <div class="all-orders-items">
                                <div v-if="item.orders_guest[0].order_guest_transactions && item.orders_guest[0].order_guest_transactions[0].meals || item.orders_guest[0].order_guest_transactions && item.orders_guest[0].order_guest_transactions[1].meals">
                                  <p class="mb-0" style="font-size: 13px;">{{ item.orders_guest[0].order_guest_transactions[0].meals.name }} </p>
                                  <p v-if="item.orders_guest[0].order_guest_transactions[1] && item.orders_guest[0].order_guest_transactions[1].meals" class="mb-0" style="font-size: 13px;">{{ item.orders_guest[0].order_guest_transactions[1].meals.name }} </p>
                                  <p class="mb-0" style="font-size: 13px;"> ... </p>
                                </div>
                                <div class="all-orders-items" v-if="item.orders_guest[0].order_guest_transactions && item.orders_guest[0].order_guest_transactions[0].food || item.orders_guest[0].order_guest_transactions && item.orders_guest[0].order_guest_transactions[1].food">
                                  <p v-if="item.orders_guest[0].order_guest_transactions[0].food" class="mb-0">{{ item.orders_guest[0].order_guest_transactions[0].quantity }}x {{ item.orders_guest[0].order_guest_transactions[0].food.name }} </p>
                                  <p v-if="item.orders_guest[0].order_guest_transactions[1].food" class="mb-0">{{ item.orders_guest[0].order_guest_transactions[1].quantity }}x {{ item.orders_guest[0].order_guest_transactions[1].food.name }} </p>
                                  <p class="mb-0"> ... </p>
                                </div>
                              </div>
                              <div class="all-orders-item-prilog">
                                <p class="mb-1">{{ item.orders_guest[0].order_guest_transactions && item.orders_guest[0].order_guest_transactions[0].meals || item.orders_guest[0].order_guest_transactions && item.orders_guest[0].order_guest_transactions[0].food ? item.orders_guest[0].order_guest_transactions[0].meals ? (item.orders_guest[0].order_guest_transactions[0].meals.price * item.orders_guest[0].order_guest_transactions[0].quantity).toFixed(2) : (item.orders_guest[0].order_guest_transactions[0].food.price * item.orders_guest[0].order_guest_transactions[0].quantity).toFixed(2) : '' }}</p>
                                <p class="mb-1">{{ item.orders_guest[0].order_guest_transactions && item.orders_guest[0].order_guest_transactions[1].meals || item.orders_guest[0].order_guest_transactions && item.orders_guest[0].order_guest_transactions[1].food ? item.orders_guest[0].order_guest_transactions[1].meals ? (item.orders_guest[0].order_guest_transactions[1].meals.price * item.orders_guest[0].order_guest_transactions[1].quantity).toFixed(2) : (item.orders_guest[0].order_guest_transactions[1].food.price * item.orders_guest[0].order_guest_transactions[1].quantity).toFixed(2) : '' }}</p>
                              </div>
                            </div>
                            <div style="position: relative">
                              <a :title="$t('order_details')" @click.prevent="openDialogDetails(orders, item, inx)" style="display: block;position: absolute;top: 15px;text-decoration: none" href="">
                                <v-icon style="color: #fb6d3b;padding: 2px;border-radius: 6px">mdi-printer</v-icon> {{ $t('order_details')}}
                              </a>
                            </div>
                            <!-- CENA DOSTAVE-->
                            <div style="border-bottom: 1px solid #cfcfcf;" v-if="canSeeDelivery(item.orders_guest, item)" class="d-flex justify-content-center align-content-center w-100">
                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
                              <div style="width: 67%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">
                                + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
                              </div>
                            </div>
                            <!-- CENA -->
                            <div class="d-flex justify-content-center py-4 align-content-center w-100">
                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 700;"></div>
                              <div style="width: 67%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 700">{{ priceMeals(item.orders_guest, item) }}</div>
                            </div>
                          </template>
                        </template>
                      </v-data-table>
                    </template>
                    <div v-if="$store.state.user.type == 'report_guest'" class="d-flex align-items-center mb-4" style="justify-content: space-between;">
                      <h2></h2>
                      <div class="text-right">
                        <v-icon style="margin-left: 15px;color: #4CAF50">mdi-truck-fast</v-icon> {{ $t('delivery_on_address')}}
                        <v-icon style="margin-left: 15px;color: #FFD700">mdi-gift</v-icon> {{ $t('takeover')}}
                        <v-icon style="margin-left: 15px;color: #8B0000">mdi-silverware-variant</v-icon> {{ $t('meal_in_restaurant')}}
                        <v-icon style="margin-left: 15px;color: #A9A9A9">mdi-cube-send</v-icon> {{ $t('send_by_mail')}}
                      </div>
                    </div>
                  </template>
                </v-card-text>
              </div>
              <div v-if="table_display == 2">
                <v-card-text>
                  <template v-for="(m, i) in noContract">
                    <div class="d-flex align-items-center mb-4" style="justify-content: space-between;">
                      <h2 v-if="$store.state.user.type != 'report_guest' && $store.state.user.type !== 'restaurant'">{{ i }}</h2>
                      <h2 v-else></h2>
<!--                      <div v-if="$store.state.user.type != 'report_guest' || $store.state.user.type !== 'restaurant'" class="text-right">-->
<!--                        <v-icon style="margin-left: 11px">mdi-truck-fast</v-icon>  {{ $t('delivery_on_address')}}-->
<!--                        <v-icon style="margin-left: 11px">mdi-gift</v-icon>  {{ $t('takeover')}}-->
<!--                        <v-icon style="margin-left: 11px">mdi-silverware-variant</v-icon>  {{ $t('meal_in_restaurant')}}-->
<!--                        <v-icon style="margin-left: 15px">mdi-cube-send</v-icon> {{ $t('send_by_mail')}}-->
<!--                      </div>-->
                    </div>
                    <template v-for="(meals, inx) in noContract[i]">
                      <h3 class="mb-2"><v-icon small>mdi-calendar</v-icon> {{ formatDate(inx) }}</h3>
                      <div class="tablet_view" style="display: flex;flex-wrap: wrap" :id="'table14'">
<!--                        :style="item.orders_guest[0].order_guest_status.status == 1 ? 'background: #b9b9b9;color:#333' : item.orders_guest[0].order_guest_status.status == 2 ? 'background: #4caf50;color:#fff' : item.orders_guest[0].order_guest_status.status == 3 ? 'background: #2196f3;color:#fff' : item.orders_guest[0].order_guest_status.status == 4 ? 'background: #2e7d32;color:#fff' : item.orders_guest[0].order_guest_status.status == 5 ? 'background: rgb(180 0 255);color:#fff' : item.orders_guest[0].order_guest_status.status == 6 ? 'background: #4caf50;color:#fff' : item.orders_guest[0].order_guest_status.status == 7 ? 'background: #f45a4e;color:#fff' : item.orders_guest[0].order_guest_status.status == 8 ? 'background: #F45A4E;color:#fff' : item.orders_guest[0].order_guest_status.status == 9 ? 'background: #F45A4E;color:#fff' : ''"-->
                        <div v-for="item in meals" class="tablet_view_item" style="width: 32.5%; margin: 4px;-webkit-box-shadow: 0px 0px 3px 1px rgba(219,219,219,1);-moz-box-shadow: 0px 0px 3px 1px rgba(219,219,219,1);box-shadow: 0px 0px 3px 1px rgba(219,219,219,1);border-radius: 5px" v-if="item.num != 'УКУПНО'">
                          <div style="display: flex;justify-content: space-between;align-items:center;margin-bottom: 20px;border-bottom: 1px dashed #cfcfcf;padding: 5px"
                          :style="item.orders_guest[0].state == 'on-hold' ? 'background: #e0e0e0;color:#333' : item.orders_guest[0].state == 'accepted' ? 'background: #4caf50;color:#fff' : item.orders_guest[0].state == 'booked-delivery' ? 'background: #2196f3;color:#fff' : item.orders_guest[0].state == 'ready' ? 'background: #2e7d32;color:#fff' : item.orders_guest[0].state == 'charged-delivery' ? 'background: rgb(180 0 255);color:#fff' : item.orders_guest[0].state == 'delivered' ? 'background: #4caf50;color:#fff' : item.orders_guest[0].state == 'rejected' ? 'background: #f45a4e;color:#fff' : item.orders_guest[0].state == 'delivery-canceled' ? 'background: #F45A4E;color:#fff' : item.orders_guest[0].state == 'client-refused' ? 'background: #F45A4E;color:#fff' : ''"
                          >
                            <span style="display: block;">
                                <span class="d-flex align-center" v-if="item.orders_guest[0].delivery_type == 4">
                                   <img style="display: block;text-align: center;width: 30%;margin: 0 auto;" src="https://app.ordera.app/gallery/qr-menu.png" width="35" alt="">
                                </span>
                               <span v-if="item.orders_guest[0].delivery_type == 3">
                                <v-icon :style="item.orders_guest[0].order_guest_status.status == 7 || item.orders_guest[0].order_guest_status.status == 8 || item.orders_guest[0].order_guest_status.status == 9 ? 'color:#fff' : 'color: #fb6d3b;'" style="margin-right: 7px;color: #fb6d3b">mdi-cube-send</v-icon>
                              </span>
                              <span v-if="item.orders_guest[0].delivery_type == 2">
                                <v-icon :style="item.orders_guest[0].order_guest_status.status == 7 || item.orders_guest[0].order_guest_status.status == 8 || item.orders_guest[0].order_guest_status.status == 9 ? 'color:#fff' : 'color: #fb6d3b;'" style="margin-right: 7px;color: #fb6d3b">mdi-silverware-variant</v-icon>
                              </span>
                              <span v-if="item.orders_guest[0].delivery_type == 1">
                                <v-icon :style="item.orders_guest[0].order_guest_status.status == 7 || item.orders_guest[0].order_guest_status.status == 8 || item.orders_guest[0].order_guest_status.status == 9 ? 'color:#fff' : 'color: #fb6d3b;'" style="margin-right: 7px;color: #fb6d3b">mdi-gift</v-icon>
                              </span>
                              <span v-if="item.orders_guest[0].delivery_type == 0">
                                  <span class="location" style="font-size: 12px">
                                  <v-icon :style="item.orders_guest[0].order_guest_status.status == 7 || item.orders_guest[0].order_guest_status.status == 8 || item.orders_guest[0].order_guest_status.status == 9 ? 'color:#fff' : 'color: #fb6d3b;'" v-if="item.orders_guest[item.orders_guest.length - 1].location" style="font-size: 24px;color: #fb6d3b" small>mdi-truck-fast </v-icon>
                                </span>
                              </span>
                            </span>
                            <span class="font-weight-bold" style="color: #fff">#{{ item.orders_guest[0].id}}</span>
                            <span style="color: #fff;font-weight: 700"><v-icon small style="color: #fff;">mdi-clock</v-icon> {{ formatTime(item.orders_guest[0].delivery_time) }}</span>
                            <span class="location">
<!--                              :style="item.orders_guest[0].order_guest_status.status == 1 ? 'background: #b9b9b9;color:#fff' : item.orders_guest[0].order_guest_status.status == 2 ? 'background: #4caf50;color:#fff' : item.orders_guest[0].order_guest_status.status == 3 ? 'background: #2196f3;color:#fff' : item.orders_guest[0].order_guest_status.status == 4 ? 'background: #2e7d32;color:#fff' : item.orders_guest[0].order_guest_status.status == 5 ? 'background: rgb(180 0 255);color:#fff' : item.orders_guest[0].order_guest_status.status == 6 ? 'background: #4caf50;color:#fff' : item.orders_guest[0].order_guest_status.status == 7 ? 'background: #f45a4e;color:#fff' : item.orders_guest[0].order_guest_status.status == 8 ? 'background: #F45A4E;color:#fff' : item.orders_guest[0].order_guest_status.status == 9 ? 'background: #F45A4E;color:#fff' : ''"-->
                              <a class="status-change"
                                 :style="item.orders_guest[0].state == 'on-hold' ? 'background: #e0e0e0;color:#333' : item.orders_guest[0].state == 'accepted' ? 'background: #4caf50;color:#fff' : item.orders_guest[0].state == 'booked-delivery' ? 'background: #2196f3;color:#fff' : item.orders_guest[0].state == 'ready' ? 'background: #2e7d32;color:#fff' : item.orders_guest[0].state == 'charged-delivery' ? 'background: rgb(180 0 255);color:#fff' : item.orders_guest[0].state == 'delivered' ? 'background: #4caf50;color:#fff' : item.orders_guest[0].state == 'rejected' ? 'background: #f45a4e;color:#fff' : item.orders_guest[0].state == 'delivery-canceled' ? 'background: #F45A4E;color:#fff' : item.orders_guest[0].state == 'client-refused' ? 'background: #F45A4E;color:#fff' : ''"
                                 @click.prevent="openDialogChangeOrderStatus(item.orders_guest[0])" href="" style="border: 1px solid #ffffff;">
                                {{ item.orders_guest[0].order_guest_status ? $t(state[state.map((stat) => stat.name).indexOf(item.orders_guest[0].state)].alias) : '' }}
                              </a>
                            </span>
                            <!-- DUGME ZA OPEN DIALOG-->
                            <div>
                              <a :title="$t('print')" @click="print('table19' + inx)" href="">
                                <v-icon :style="item.orders_guest[0].order_guest_status.status == 7 || item.orders_guest[0].order_guest_status.status == 8 || item.orders_guest[0].order_guest_status.status == 9 ? 'color:#fff' : 'color: #fb6d3b;'" style="padding: 2px;border-radius: 6px">mdi-printer</v-icon>
                              </a>
                              <a :title="$t('order_details')" @click.prevent="openDialogDetails(item.orders_guest[0], item, inx)" href="">
                                <v-icon :style="item.orders_guest[0].order_guest_status.status == 7 || item.orders_guest[0].order_guest_status.status == 8 || item.orders_guest[0].order_guest_status.status == 9 ? 'color:#fff' : 'color: #fb6d3b;'" style="color: #fb6d3b;padding: 2px;border-radius: 6px">mdi-ballot-outline</v-icon>
                              </a>
                            </div>
                          </div>
                          <template v-for="(orders,index) in item.orders_guest">
                            <template v-for="(order, ii) in orders.order_guest_transactions" style="padding: 5px">
                              <div style="width: 100%;padding: 5px;" class="d-flex justify-content-center align-content-center w-100 all-orders ">
                                <div :title="order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : ''" class="all-orders-item">
                                  <span style="color: #fb6d3b;font-weight: 700">{{ order.quantity }}</span>x <span class="text-uppercase">{{ order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : '' }}</span>
                                </div>
                                <div class="all-orders-qty">
                                  {{ order.meals || order.food ? order.meals ? (order.meals.price * order.quantity).toFixed(2) : (order.food.price * order.quantity).toFixed(2) : '' }}
                                </div>
                              </div>
                              <div v-if="order.prilog.length" v-for="(prilog, i) in order.prilog">
                                <div v-if="prilog.quantity > 0 && prilog.price > 0" style="border-bottom: 1px dashed #cfcfcf;color:#696969" class="d-flex justify-content-center align-content-center w-100">
                                  <div style="width: 52%;font-size: 12px;padding-bottom: 2px;color: #818181">{{ prilog.quantity }}x {{ translate(prilog.food.name) }}</div>
                                  <div style="width: 47%;text-align: right;font-size: 12px;padding-bottom: 2px">{{ (prilog.quantity * prilog.price).toFixed(2) }}</div>
                                </div>
                              </div>
                            </template>
                            <!-- CENA DOSTAVE-->
                            <div style="border-bottom: 1px solid #cfcfcf;" v-if="canSeeDelivery(item.orders_guest, item)" class="d-flex justify-content-center align-content-center w-100">
                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
                              <div style="width: 70%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">
                                + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
                              </div>
                            </div>
                            <!-- CENA-->
                            <div class="d-flex justify-content-center py-4 align-content-center w-100">
                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 700;"></div>
                              <div style="width: 69%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 700">{{ priceMeals(item.orders_guest, item) }}</div>
                            </div>
                          </template>
                          <div style="display: flex;justify-content: space-between;align-items: center;border-top: 1px dashed #cfcfcf;padding: 5px;">
                            <div style="padding: 5px">
                              <span style="display: block" @click="showUsers(item)">{{ capitalizeFirstLetter(item.name).charAt(0) + '. ' + capitalizeFirstLetter(item.lastname) }}</span>
                              <span style="font-size: 11px;display: block;color: #333">{{ item.phone }}</span>
                            </div>
                            <div>
                              <span class="order-address" style="text-align: left;display: block;width: 100%">
                                  <span v-if="item.orders_guest[0].delivery_type == 4">
                                     <img style="display: block;text-align: center;width: 30%;margin: 0 auto;" src="https://app.ordera.app/gallery/qr-menu.png" width="35" alt="">
                                    {{ $t('table') }}
                                  </span>
                                <span v-if="item.orders_guest[0].delivery_type == 3">
                                 {{ $t('send_by_mail') }}
                               </span>
                               <span v-if="item.orders_guest[0].delivery_type == 2">
                                 {{ $t('meal_in_restaurant') }}
                               </span>
                               <span v-if="item.orders_guest[0].delivery_type == 1">
                                 {{ $t('takeover') }}
                               </span>
                               <span v-if="item.orders_guest[0].delivery_type == 0">
                                 <span class="location" style="font-size: 12px">
                                  {{ item.orders_guest[item.orders_guest.length - 1].location && item.orders_guest[item.orders_guest.length - 1].location.address ? item.orders_guest[item.orders_guest.length - 1].location.address.replace(', Bosnia and Herzegovina', '').replace(', Bosna i Hercegovina', '').replace(', Босна и Херцеговина', '') : '' }}
                                 </span>
                               </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div v-if="$store.state.user.type == 'report_guest'" class="d-flex align-items-center mb-4" style="justify-content: space-between;">
                      <h2></h2>
                      <div class="text-right">
                        <v-icon style="margin-left: 15px;color: #4CAF50">mdi-truck-fast</v-icon> {{ $t('delivery_on_address')}}
                        <v-icon style="margin-left: 15px;color: #FFD700">mdi-gift</v-icon> {{ $t('takeover')}}
                        <v-icon style="margin-left: 15px;color: #8B0000">mdi-silverware-variant</v-icon> {{ $t('meal_in_restaurant')}}
                        <v-icon style="margin-left: 15px;color: #A9A9A9">mdi-cube-send</v-icon> {{ $t('send_by_mail')}}
                      </div>
                    </div>
                  </template>
                </v-card-text>
              </div>
            </v-card>
            <v-card v-else flat>
              <v-card-text>
                <v-autocomplete v-if="noContractCompanies.length > 1"
                  v-model="search2"
                  :items="noContractCompanies"
                  multiple
                  dense
                  filled
                  @input="findMeals"
                  :label="$t('select_company')"
                  item-text="company"
                  item-value="id"
                  clear
                  chips
                  small-chips
                >
                  <v-btn color="primary" slot="prepend-item" @click="toggle" small class="mx-4">
                    {{$t("display_all")}}
                  </v-btn>
                  <v-divider slot="prepend-item" class="mt-2" />
                </v-autocomplete>
                <template v-for="(m, i) in noContract">
                    <div class="d-flex align-items-center mb-1" style="justify-content: space-between;">
                      <h2 v-if="$store.state.user.type != 'report_guest'">
<!--                        {{ i }}-->
                      </h2>
                      <div v-if="$store.state.user.type != 'report_guest'" style="display: flex;align-items: center" class="text-right">
                        <v-icon style="margin-right: 5px;color: #4CAF50">mdi-truck-fast</v-icon>  {{ $t('delivery_on_address')}}
                        <v-icon style="margin-left: 11px;margin-right: 5px;color: #FFD700">mdi-gift</v-icon>  {{ $t('takeover')}}
                        <v-icon style="margin-left: 11px;margin-right: 5px;color: #8B0000">mdi-silverware-variant</v-icon>  {{ $t('meal_in_restaurant')}}
                        <img style="margin-left: 15px;margin-right: 4px" src="https://app.ordera.app/gallery/qr-menu.png" width="29" alt=""> QR {{ $t('table')}}
                        <v-icon style="margin-left: 10px;margin-right: 4px;color: #A9A9A9">mdi-cube-send</v-icon> {{ $t('send_by_mail')}}
                      </div>
                    </div>
                    <template v-for="(meals, inx) in noContract[i]">
                      <h3 class="mb-2"><v-icon small>mdi-calendar</v-icon> {{ formatDate(inx) }}</h3>
                      <v-data-table
                          :search="pretraga"
                          :item-key="inx"
                          id="table14"
                          :headers="headersG"
                          :items="meals"
                          :custom-sort="customSort"
                          class="elevation-1 my-4"
                      >
                        <template v-for="header in headersG" v-slot:[`header.${header.value}`]="{ header }">
                          {{ translate(header.text) }}
                          <span v-if="header.text == 'Poručeno' || header.text == 'Поручено'" class="d-flex flex-wrap" style="margin-top: -14px;justify-content: space-between;text-align: right;font-size: 10px;font-weight: 700;width: 280px">
                            <span style="width: 40%"></span>
                            <span style="width: 25%;font-weight: 700;margin-right: -32px;font-size: 0.75rem">
                              {{ $t('amount') }}
                              {{ $store.state.restaurant.settings && $store.state.restaurant.settings.price ? "(" + $store.state.restaurant.settings.price + ")" : '' }}
                            </span>
                          </span>
                        </template>
                        <template v-slot:item.id="{ item, index }">
                          {{ item.num !== "УКУПНО" ? index + 1 : '' }}
                        </template>
                        <template v-slot:item.num="{ item, index }">
                          {{ item.menu !== "УКУПНО" ? '#' + item.orders_guest[0].id : "" }}
                        </template>
                        <template v-slot:item.restaurant_id="{ item }">
                          <span style="color: dodgerblue;cursor: pointer" @click.prevent="openDialogRestoran(item.orders_guest[0].restaurant)">{{ item.orders_guest[0] && item.orders_guest[0].restaurant ? item.orders_guest[0].restaurant.name : '' }}</span>
                        </template>
                        <template v-slot:item.name="{ item, index }">
                          <span style="display: block" :key="index" @click="showUsers(item)">{{ capitalizeFirstLetter(item.name).charAt(0) + '. ' + capitalizeFirstLetter(item.lastname) }}</span>
                          <span style="font-size: 11px;display: block;color: #333">{{ item.phone }}</span>
                        </template>
                        <template v-slot:item.address="{ item }">
                          <span class="order-address" style="text-align: left;">
                              <span v-if="item.orders_guest[0].delivery_type == 4">
                                QR {{ ($t('table')).toLowerCase() }}
                                <img style="display: block;text-align: left;width: 30%;margin: 0 auto;" src="https://app.ordera.app/gallery/qr-menu.png" width="35" alt="">
                              </span>
                             <span v-if="item.orders_guest[0].delivery_type == 3">
                                <span class="location" style="font-size: 12px;text-align: left">
                                <v-icon v-if="item.orders_guest[item.orders_guest.length - 1].location" style="font-size: 23px;color: #A9A9A9" small>mdi-cube-send </v-icon>
                                {{ item.orders_guest[item.orders_guest.length - 1].location && item.orders_guest[item.orders_guest.length - 1].location.address ? item.orders_guest[item.orders_guest.length - 1].location.address.replace(', Bosnia and Herzegovina', '').replace(', Bosna i Hercegovina', '').replace(', Босна и Херцеговина', '') : '' }}
                              </span>
                            </span>
                            <span v-if="item.orders_guest[0].delivery_type == 2">
                              <v-icon style="margin-right: 7px;text-align: left;color: #8B0000">mdi-silverware-variant</v-icon>
                              {{ $t('meal_in_restaurant') }}
                            </span>
                            <span v-if="item.orders_guest[0].delivery_type == 1">
                              <v-icon style="margin-right: 7px;display: block;text-align: left;color: #FFD700">mdi-gift</v-icon>
                              {{ $t('takeover') }}
                            </span>
                            <span v-if="item.orders_guest[0].delivery_type == 0">
<!--                              {{ $t('delivery_on_address') }}-->
                                <span class="location" style="font-size: 12px;text-align: left">
                                <v-icon v-if="item.orders_guest[item.orders_guest.length - 1].location" style="font-size: 24px;color: #4CAF50" small>mdi-truck-fast </v-icon>
                                {{ item.orders_guest[item.orders_guest.length - 1].location && item.orders_guest[item.orders_guest.length - 1].location.address ? capitalizeFirstLetter(item.orders_guest[item.orders_guest.length - 1].location.address.replace(', Bosnia and Herzegovina', '').replace(', Bosna i Hercegovina', '').replace(', Босна и Херцеговина', '')) : '' }}
                              </span>
                            </span>
                          </span>
                        </template>
                        <template v-slot:item.time="{ item }">
                           <span class="location">
                            {{ formatTime(item.orders_guest[0].delivery_time) }}
                           </span>
                        </template>
                        <template v-slot:item.state="{ item }">
                          <span class="location">
                            <a class="status-change" :style="item.orders_guest[0].state == 'on-hold' ? 'background: #e0e0e0;color:#333' : item.orders_guest[0].state == 'accepted' ? 'background: #4caf50;color:#fff' : item.orders_guest[0].state == 'booked-delivery' ? 'background: #2196f3;color:#fff' : item.orders_guest[0].state == 'ready' ? 'background: #2e7d32;color:#fff' : item.orders_guest[0].state == 'charged-delivery' ? 'background: rgb(180 0 255);color:#fff' : item.orders_guest[0].state == 'delivered' ? 'background: #4caf50;color:#fff' : item.orders_guest[0].state == 'rejected' ? 'background: #f45a4e;color:#fff' : item.orders_guest[0].state == 'delivery-canceled' ? 'background: #F45A4E;color:#fff' : item.orders_guest[0].state == 'client-refused' ? 'background: #F45A4E;color:#fff' : ''"
                               @click.prevent="openDialogChangeOrderStatus(item.orders_guest[0])" href="">
                              <span v-if="item.orders_guest[0].state != 99">
                                {{ item.orders_guest[0].state && state[state.map((stat) => stat.name).indexOf(item.orders_guest[0].state)] ? $t(state[state.map((stat) => stat.name).indexOf(item.orders_guest[0].state)].alias) : '' }}
                              </span>
                              <span v-else>
                                {{ $t('undefined')}}
                              </span>
                            </a>
                          </span>
                        </template>
                        <template v-slot:item.qty="{ item }">
                          <template v-if="orders.order_guest_transactions && orders.order_guest_transactions.length < 3" v-for="(orders,index) in item.orders_guest">
                            <template v-for="(order, ii) in orders.order_guest_transactions">
                              <div class="d-flex justify-content-center align-content-center w-100 all-orders pt-2">
                                <div :title="order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : ''" class="all-orders-item">
                                  {{ order.quantity }}x <span class="text-uppercase">{{ order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : '' }}</span>
                                </div>
                                <div class="all-orders-qty">
                                  {{ order.meals || order.food ? order.meals ? (order.meals.price * order.quantity.toFixed(2)) : (order.food.price * order.quantity).toFixed(2) : '' }}
                                </div>
                              </div>
                              <div v-if="order.prilog.length" v-for="(prilog, i) in order.prilog">
                                <div v-if="prilog.quantity > 0 && prilog.price > 0" style="border-bottom: 1px dashed #cfcfcf;color:#696969" class="d-flex justify-content-center align-content-center w-100">
                                  <div class="font15" style="width: 52%;padding-bottom: 2px;color: #818181">{{ prilog.quantity }}x {{ translate(prilog.food.name) }}</div>
                                  <div class="font15" style="width: 47%;text-align: right;padding-bottom: 2px">{{ (prilog.quantity * prilog.price).toFixed(2) }}</div>
                                </div>
                              </div>
                            </template>
                            <!-- DUGME ZA OPEN DIALOG-->
                            <div v-if="! item.total_price" style="position:relative;">
                              <a :title="$t('order_details')" @click.prevent="openDialogDetails(orders, item, inx)" style="display: block;text-align: left;position: absolute;top: 13px;text-decoration: none" href="">
                                <v-icon style="color: #fb6d3b;padding: 2px;border-radius: 6px">mdi-printer</v-icon> {{ $t('order_details')}}
                              </a>
                            </div>
                            <!-- CENA DOSTAVE-->
                            <div style="border-bottom: 1px solid #cfcfcf;" v-if="canSeeDelivery(item.orders_guest, item)" class="d-flex justify-content-center align-content-center w-100">
                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
                              <div style="width: 70%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">
                                + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
                              </div>
                            </div>
                            <!-- CENA-->
                            <div class="d-flex justify-content-center py-4 align-content-center w-100">
                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 700;"></div>
                              <div style="width: 69%;text-align: right;padding-bottom: 2px;font-weight: 700">
                                <span class="font15" v-if="! item.total_price">{{ priceMeals(item.orders_guest, item) }}</span>
                                <span class="font15" v-else>{{ (item.price).toFixed(2) }}</span>
                              </div>
                            </div>
                          </template>
                          <template style="width: 100%" v-else>
                            <div class="all-order-else d-flex justify-content-center align-content-center w-100 all-orders pt-2">
                              <div class="all-orders-items">
                                <div v-if="item.orders_guest[0].order_guest_transactions[0].meals || item.orders_guest[0].order_guest_transactions[1].meals">
                                  <p class="mb-0" style="font-size: 13px;">{{ item.orders_guest[0].order_guest_transactions[0].meals.name }} </p>
                                  <p v-if="item.orders_guest[0].order_guest_transactions[1] && item.orders_guest[0].order_guest_transactions[1].meals" class="mb-0" style="font-size: 13px;">{{ item.orders_guest[0].order_guest_transactions[1].meals.name }} </p>
                                  <p class="mb-0" style="font-size: 13px;"> ... </p>
                                </div>
                                <div class="all-orders-items" v-if="item.orders_guest[0].order_guest_transactions[0].food || item.orders_guest[0].order_guest_transactions[1].food">
                                  <p v-if="item.orders_guest[0].order_guest_transactions[0].food" class="mb-0">{{ item.orders_guest[0].order_guest_transactions[0].quantity }}x <span class="text-uppercase">{{ item.orders_guest[0].order_guest_transactions[0].food.name }}</span> </p>
                                  <p v-if="item.orders_guest[0].order_guest_transactions[1].food" class="mb-0">{{ item.orders_guest[0].order_guest_transactions[1].quantity }}x <span class="text-uppercase">{{ item.orders_guest[0].order_guest_transactions[1].food.name }}</span> </p>
                                  <p class="mb-0"> ... </p>
                                </div>
                              </div>
                              <div class="all-orders-item-prilog">
                                <p class="mb-1 font15">{{ item.orders_guest[0].order_guest_transactions[0].meals || item.orders_guest[0].order_guest_transactions[0].food ? item.orders_guest[0].order_guest_transactions[0].meals ? (item.orders_guest[0].order_guest_transactions[0].meals.price * item.orders_guest[0].order_guest_transactions[0].quantity).toFixed(2) : (item.orders_guest[0].order_guest_transactions[0].food.price * item.orders_guest[0].order_guest_transactions[0].quantity).toFixed(2) : '' }}</p>
                                <p class="mb-1 font15">{{ item.orders_guest[0].order_guest_transactions[1].meals || item.orders_guest[0].order_guest_transactions[1].food ? item.orders_guest[0].order_guest_transactions[1].meals ? (item.orders_guest[0].order_guest_transactions[1].meals.price * item.orders_guest[0].order_guest_transactions[1].quantity).toFixed(2) : (item.orders_guest[0].order_guest_transactions[1].food.price * item.orders_guest[0].order_guest_transactions[1].quantity).toFixed(2) : '' }}</p>
                              </div>
                            </div>
                            <div style="position: relative">
                              <a :title="$t('order_details')" @click.prevent="openDialogDetails(orders, item, inx)" style="display: block;position: absolute;top: 13px;text-decoration: none" href="">
                                <v-icon style="color: #fb6d3b;padding: 2px;border-radius: 6px">mdi-printer</v-icon> {{ $t('order_details')}}
                              </a>
                            </div>
                            <!-- CENA DOSTAVE-->
                            <div style="border-bottom: 1px solid #cfcfcf;" v-if="canSeeDelivery(item.orders_guest, item)" class="d-flex justify-content-center align-content-center w-100">
                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
                              <div style="width: 67%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">
                                + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
                              </div>
                            </div>
                            <!-- CENA DOSTAVE-->
                            <div class="d-flex justify-content-center py-4 align-content-center w-100">
                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 700;"></div>
                              <div class="font15" style="width: 67%;text-align: right;padding-bottom: 2px;font-weight: 700">{{ priceMeals(item.orders_guest, item) }}</div>
                            </div>
                          </template>
                        </template>
                      </v-data-table>
                    </template>
                    <div v-if="$store.state.user.type == 'report_guest'" class="d-flex align-items-center mb-4" style="justify-content: space-between;">
                      <h2></h2>
                      <div class="text-right">
                        <v-icon style="margin-left: 15px">mdi-truck-fast</v-icon> {{ $t('delivery_on_address')}}
                        <v-icon style="margin-left: 15px">mdi-gift</v-icon> {{ $t('takeover')}}
                        <v-icon style="margin-left: 15px">mdi-silverware-variant</v-icon> {{ $t('meal_in_restaurant')}}
                        <v-icon style="margin-left: 15px">mdi-cube-send</v-icon> {{ $t('send_by_mail')}}
                      </div>
                    </div>
                  </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- STOLOVI -->
          <v-tab-item >
            <v-data-table
                :search="pretraga"
                id="table123"
                :headers="headersTable"
                :items="tables || []"
                class="elevation-1 my-4"
                mobile-breakpoint="0"
            >
              <template v-for="header in headersTable" v-slot:[`header.${header.value}`]="{ header }">
                <span :style="header.text == 'Статус' || header.text == 'Status' ? 'padding-left:75px' : ''">{{ translate(header.text) }}</span>
              </template>
              <template v-slot:item.id="{ item }">
                  #{{ item.id }}
              </template>
              <template v-slot:item.name="{ item, index }">
                <span style="display: block" :key="index" @click="showUsers(item)">{{ capitalizeFirstLetter(item.users.name) }}</span>
              </template>
              <template v-slot:item.date="{ item }">
                <div class="text-left">
                  {{ formatDate(item.date) }}
                </div>
              </template>
              <template v-slot:item.delivery_time="{ item }">
                <span class="text-left">
                  {{ formatTime(item.delivery_time) }}
                </span>
              </template>
              <template v-slot:item.state="{ item }">
                <span  class="location">
                  <a class="status-change"
                     :style="item.state == 'on-hold' ? 'background: #B0BEC5;color:#333' : item.state == 'accepted' ? 'background: #4CAF50;color:#fff' : item.state == 'booked-delivery' ? 'background: #2196f3;color:#fff' : item.state == 'ready' ? 'background: #FFA500;color:#fff' : item.state == 'charged-delivery' ? 'background: rgb(180 0 255);color:#fff' : item.state == 'delivered' ? 'background: #2196F3;color:#fff' : item.state == 'rejected' ? 'background: #F44336;color:#fff' : item.state == 'delivery-canceled' ? 'background: #F45A4E;color:#fff' : item.state == 'client-refused' ? 'background: #F45A4E;color:#fff' : item.state == 'payed' ? 'background: #2196F3;color:#fff' : ''"
                     @click.prevent="openDialogChangeOrderStatusTable(item)" href="">
                    {{ item.state && stateTable[stateTable.map((stat) => stat.name).indexOf(item.state)] ? $t(stateTable[stateTable.map((stat) => stat.name).indexOf(item.state)].alias) : '' }}
                  </a>
                  <span style="color: red;display: inline-block;text-align: left" v-if="item.state && item.state == 'deleted'">Obrisan obrok</span>
                </span>
              </template>
              <template v-slot:item.qty="{ item }">
                <template v-if="item.order_guest_transactions && item.order_guest_transactions.length < 3">
                  <template v-for="(order, ii) in item.order_guest_transactions">
                    <div class="d-flex justify-content-center align-content-center w-100 all-orders">
                      <div :title="order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : ''" class="all-orders-item mt-2">
                        {{ order.quantity }}x <span class="text-uppercase">{{ order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : '' }}</span>
                      </div>
                      <div class="all-orders-qty mt-2">
                        {{ order.meals || order.food ? order.meals ? order.meals.price * order.quantity : (order.food.price * order.quantity) : '' }}
                      </div>
                    </div>
                    <div v-if="order.prilog.length" v-for="(prilog, i) in order.prilog">
                      <div v-if="prilog.quantity > 0 && prilog.price > 0" style="border-bottom: 1px dashed #cfcfcf;color:#696969" class="d-flex mt-2 justify-content-center align-content-center w-100">
                        <div style="width: 52%;font-size: 12px;padding-bottom: 2px;color: #818181">{{ prilog.quantity }}x {{ translate(prilog.food.name) }}</div>
                        <div style="width: 47%;text-align: right;font-size: 12px;padding-bottom: 2px">{{ prilog.quantity * prilog.price }}</div>
                      </div>
                    </div>
                  </template>
                  <!-- DUGME ZA OPEN DIALOG-->
                  <div class="test_print" style="position:relative;">
                    <a :title="$t('order_details')" @click.prevent="openDialogDetailsTable(item.order_guest_transactions, item)" style="display: block;text-align: left;position: absolute;top: 23px;text-decoration: none" href="">
                      <v-icon style="color: #fb6d3b;padding: 2px;border-radius: 6px">mdi-printer</v-icon> {{ $t('order_details')}}
                    </a>
                  </div>
                  <!-- CENA DOSTAVE-->
                  <div style="border-bottom: 1px solid #cfcfcf;" v-if="canSeeDelivery(item.order_guest_transactions, item)" class="d-flex justify-content-center align-content-center w-100">
                    <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
                    <div style="width: 70%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">
                      + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
                    </div>
                  </div>
                  <!-- CENA-->
                  <div class="d-flex justify-content-center py-4 align-content-center w-100">
                    <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 700;"></div>
                    <div style="width: 68%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 700">
                      <span>{{ priceMealsTable(item, item) }}</span>
                      <span>{{ item.price }}</span>
                    </div>
                  </div>
                </template>
                <template style="width: 100%" v-else>
                  <div v-if="item.order_guest_transactions.length" class="all-order-else d-flex justify-content-center align-content-center w-100 all-orders">
                    <div class="all-orders-items">
                      <div v-if="item.order_guest_transactions[0] && item.order_guest_transactions[0].meals || item.order_guest_transactions && item.order_guest_transactions[1] && item.order_guest_transactions[1].meals">
                        <p class="mb-0" style="font-size: 13px;">{{ item.order_guest_transactions[0].meals.name }} </p>
                        <p v-if="item.order_guest_transactions[1] && item.order_guest_transactions[1].meals" class="mb-0" style="font-size: 13px;">{{ item.order_guest_transactions[1].meals.name }} </p>
                        <p class="mb-0" style="font-size: 13px;"> ... </p>
                      </div>
                      <div class="all-orders-items" v-if="item.order_guest_transactions && item.order_guest_transactions[0].food || item.order_guest_transactions && item.order_guest_transactions[1] && item.order_guest_transactions[1].food">
                        <p v-if="item.order_guest_transactions[0].food" class="mb-0">{{ item.order_guest_transactions[0].quantity }}x {{ item.order_guest_transactions[0].food.name }} </p>
                        <p v-if="item.order_guest_transactions[1] && item.order_guest_transactions[1].food" class="mb-0">{{ item.order_guest_transactions[1].quantity }}x {{ item.order_guest_transactions[1].food.name }} </p>
                        <p class="mb-0"> ... </p>
                      </div>
                    </div>
                    <div class="all-orders-item-prilog">
                      <p class="mb-1">{{ item.order_guest_transactions && item.order_guest_transactions[0].meals || item.order_guest_transactions && item.order_guest_transactions[0].food ? item.order_guest_transactions[0].meals ? item.order_guest_transactions[0].meals.price * item.order_guest_transactions[0].quantity : item.order_guest_transactions[0].food.price * item.order_guest_transactions[0].quantity : '' }}</p>
                      <p class="mb-1">{{ item.order_guest_transactions && item.order_guest_transactions[1] && item.order_guest_transactions[1].meals || item.order_guest_transactions && item.order_guest_transactions[1] && item.order_guest_transactions[1].food ? item.order_guest_transactions[1].meals ? item.order_guest_transactions[1].meals.price * item.order_guest_transactions[1].quantity : item.order_guest_transactions[1].food.price * item.order_guest_transactions[1].quantity : '' }}</p>
                    </div>
                  </div>
                  <div style="position: relative">
                    <a :title="$t('order_details')" @click.prevent="openDialogDetailsTable(item.order_guest_transactions, item)" style="display: block;position: absolute;top: 15px;text-decoration: none" href="">
                      <v-icon style="color: #fb6d3b;padding: 2px;border-radius: 6px">mdi-printer</v-icon> {{ $t('order_details')}}
                    </a>
                  </div>
                  <!-- CENA DOSTAVE-->
                  <div style="border-bottom: 1px solid #cfcfcf;" v-if="canSeeDelivery(item.order_guest_transactions, item)" class="d-flex justify-content-center align-content-center w-100">
                    <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
                    <div style="width: 67%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">
                      + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
                    </div>
                  </div>
                  <!-- CENA DOSTAVE-->
                  <div class="d-flex justify-content-center py-4 align-content-center w-100">
                    <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 700;"></div>
                    <div style="width: 67%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 700">{{ priceMealsTable(item, item) }}</div>
                  </div>
                </template>
              </template>
            </v-data-table>
          </v-tab-item>
          <!-- NAMIRNICE -->
          <v-tab-item>
            <v-card v-if="$store.state.user.type === 'report_guest'" flat>
              <v-card-text>
                <v-autocomplete v-if="noContractCompanies.length > 1"
                  v-model="search2"
                  :items="noContractCompanies"
                  multiple
                  dense
                  filled
                  @input="findMeals"
                  :label="$t('select_company')"
                  item-text="company"
                  item-value="id"
                  clear
                  chips
                  small-chips
                >
                  <v-btn color="primary" slot="prepend-item" @click="toggle" small class="mx-4">
                    {{$t("display_all")}}
                  </v-btn>
                  <v-divider slot="prepend-item" class="mt-2" />
                </v-autocomplete>
                <template v-for="(m, i) in noContract">
                <div class="d-flex align-items-center mb-4" style="justify-content: space-between;">
                  <h2 v-if="$store.state.user.type != 'report_guest'">{{ i }}</h2>
                  <div v-if="$store.state.user.type != 'report_guest'" class="text-right">
                    <v-icon style="margin-left: 11px;font-size: 10px">mdi-truck-fast</v-icon>  {{ $t('delivery_on_address')}}
                    <v-icon style="margin-left: 11px">mdi-gift</v-icon>  {{ $t('takeover')}}
                    <v-icon style="margin-left: 15px">mdi-silverware-variant</v-icon>  {{ $t('meal_in_restaurant')}}
                    <v-icon style="margin-left: 15px">mdi-cube-send</v-icon>  {{ $t('send_by_mail')}}
                  </div>
                </div>
                <template v-for="(meals, inx) in noContract[i]">
                  <h3 class="mb-2 mt-2" style="display: flex; justify-content: space-between">
                    <span>
                      <v-icon small>mdi-calendar</v-icon> {{ formatDate(inx) }}
                    </span>
                    <span>{{ meals ? $t('orders') + ': ' + (meals.length - 1) : '' }} </span>
                  </h3>
                  <v-data-table
                    :search="pretraga"
                    :item-key="inx"
                    id="table10"
                    :headers="headersG"
                    :items="meals"
                    class="elevation-1 mt-4"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                  >
                    <template v-for="header in headersG" v-slot:[`header.${header.value}`]="{ header }">
                      {{ translate(header.text) }}
                      <span v-if="header.text == 'Poručeno' || header.text == 'Поручено'" class="d-flex flex-wrap" style="margin-top: -14px;justify-content: space-between;text-align: right;font-size: 10px;font-weight: 700;width: 280px">
                        <span style="width: 40%"></span>
                        <span style="width: 20%;font-weight: 700">
                          {{ $t('amount') }}
                          {{ $store.state.restaurant.settings && $store.state.restaurant.settings.price ? "(" + $store.state.restaurant.settings.price + ")" : '' }}
                        </span>
                        </span>
                    </template>
                    <template v-slot:item.num="{ item, index }">
                      <span v-if="! item.total_price">#</span>
                      {{ item.menu !== "УКУПНО" ? item.orders_guest[0].id : "" }}
                    </template>
                    <template v-slot:item.name="{ item, index }">
                      <span :key="index" @click="showUsers(item)">{{ capitalizeFirstLetter(item.name).charAt(0) + '. ' + capitalizeFirstLetter(item.lastname) }}</span>x
                    </template>
                    <template v-slot:item.address="{ item }">
                      <span style="text-align: left !important;" class="order-address">
                        <span v-if="item.orders_guest[0].delivery_type == 3">
                           <span class="location" style="font-size: 12px;text-align: left">
                             <v-icon style="font-size: 18px" small>mdi-cube-send </v-icon> {{ item.orders_guest[item.orders_guest.length - 1].location && item.orders_guest[item.orders_guest.length - 1].location.address ? item.orders_guest[item.orders_guest.length - 1].location.address.replace(', Bosnia and Herzegovina', '').replace(', Bosna i Hercegovina', '').replace(', Босна и Херцеговина', '') : '' }}
                           </span>
                        </span>
                        <span v-if="item.orders_guest[0].delivery_type == 2">
                           <v-icon style="margin-right: 8px;display: block;text-align: left">mdi-silverware-variant</v-icon>{{ $t('meal_in_restaurant') }}
                        </span>
                        <span v-if="item.orders_guest[0].delivery_type == 1">
                         <v-icon style="margin-right: 8px;display: block;text-align: center">mdi-gift</v-icon> {{ $t('takeover') }}
                        </span>
                        <span v-if="item.orders_guest[0].delivery_type == 0">
                          <v-icon style="font-size: 18px;margin-right: 8px" small>mdi-truck-fast </v-icon>{{ $t('delivery_on_address') }}
                          <span style="font-weight: 500;display: block;text-align: left">
                             {{ item.orders_guest[item.orders_guest.length - 1].location && item.orders_guest[item.orders_guest.length - 1].location.address ? item.orders_guest[item.orders_guest.length - 1].location.address.replace(', Bosnia and Herzegovina', '').replace(', Bosna i Hercegovina', '').replace(', Босна и Херцеговина', '') : '' }}
                          </span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.time="{ item }">
                      {{ formatTime(item.orders_guest[0].delivery_time) }}
                    </template>
                    <template v-slot:item.state="{ item }">
                      <span>
                        <a class="status-change" :style="item.orders_guest[0].state == 'on-hold' ? 'background: #e0e0e0;color:#333' : item.orders_guest[0].state == 'accepted' ? 'background: #4caf50;color:#fff' : item.orders_guest[0].state == 'booked-delivery' ? 'background: #2196f3;color:#fff' : item.orders_guest[0].state == 'ready' ? 'background: #2e7d32;color:#fff' : item.orders_guest[0].state == 'charged-delivery' ? 'background: rgb(180 0 255);color:#fff' : item.orders_guest[0].state == 'delivered' ? 'background: #4caf50;color:#fff' : item.orders_guest[0].state == 'rejected' ? 'background: #f45a4e;color:#fff' : item.orders_guest[0].state == 'delivery-canceled' ? 'background: #F45A4E;color:#fff' : item.orders_guest[0].state == 'client-refused' ? 'background: #F45A4E;color:#fff' : ''"
                           @click.prevent="openDialogChangeOrderStatus(item.orders_guest[0].order_guest_status)" href="">
                           {{ item.orders_guest[0].order_guest_status ? $t(state[state.map((stat) => stat.name).indexOf(item.orders_guest[0].state)].alias) : '' }}
                        </a>
                      </span>
                    </template>
                    <template v-slot:item.qty="{ item }">
                      <template v-if="orders.order_guest_transactions && orders.order_guest_transactions.length < 3" v-for="(orders,index) in item.orders_guest">
                        <template v-for="(order, ii) in orders.order_guest_transactions">
                          <div class="d-flex justify-content-center align-content-center w-100 all-orders">
                            <div :title="order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : ''" class="all-orders-item">
                              {{ order.quantity }}x {{ order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : '' }}
                            </div>
                            <div class="all-orders-qty">
                              {{ order.meals || order.food ? order.meals ? order.meals.price * order.quantity : order.food.price *  order.quantity : '' }}
                            </div>
                          </div>
                          <div v-if="order.prilog.length" v-for="(prilog, i) in order.prilog">
                            <div v-if="prilog.quantity > 0 && prilog.price > 0" style="border-bottom: 1px dashed #cfcfcf;color:#696969" class="d-flex justify-content-center align-content-center w-100">
                              <div style="width: 50%;font-size: 12px;padding-bottom: 2px;color: #818181">{{ prilog.quantity }}x {{ translate(prilog.food.name) }}</div>
                              <div style="width: 47%;text-align: right;font-size: 12px;padding-bottom: 2px">{{ prilog.quantity * prilog.price }}</div>
                            </div>
                          </div>
                        </template>
                        <!-- DUGME ZA OPEN DIALOG-->
                        <div style="position:relative;">
                          <a v-if="! item.total_price" :title="$t('order_details')" @click.prevent="openDialogDetails(orders, item, inx)" style="display: block;text-align: left;position: absolute;top: 12px;text-decoration: none" href="">
                            <v-icon style="color: #fb6d3b;padding: 2px;border-radius: 6px">mdi-printer</v-icon> {{ $t('order_details')}}
                          </a>
                        </div>
                        <!-- CENA DOSTAVE-->
                        <div style="border-bottom: 1px solid #cfcfcf;" v-if="canSeeDelivery(item.orders_guest, item)" class="d-flex justify-content-center align-content-center w-100">
                          <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
                          <div style="width: 70%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">
                            + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
                          </div>
                        </div>
                        <!-- CENA-->
                        <div class="d-flex justify-content-center py-4 align-content-center w-100">
                          <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 700;"></div>
                          <div style="width: 69%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 700">
                            <span v-if="! item.total_price">{{ priceMeals(item.orders_guest, item) }}</span>
                            <span v-else>{{ item.price }}</span>
                          </div>
                        </div>
                      </template>
                      <template style="width: 100%" v-else>
                        <div v-if="item.name != 'UKUPNO'" class="all-order-else d-flex justify-content-center align-content-center w-100 all-orders">
                          <div class="all-orders-items">
                            <div v-if="item.orders_guest[0].order_guest_transactions[0].meals || item.orders_guest[0].order_guest_transactions[1].meals">
                              <p class="mb-0" style="font-size: 13px;">{{ item.orders_guest[0].order_guest_transactions[0].meals ? item.orders_guest[0].order_guest_transactions[0].meals.name : '' }} </p>
                              <p class="mb-0" style="font-size: 13px;">{{ item.orders_guest[0].order_guest_transactions[1].meals ? item.orders_guest[0].order_guest_transactions[1].meals.name : '' }} </p>
                              <p class="mb-0" style="font-size: 13px;"> ... </p>
                            </div>
                            <div class="all-orders-items" v-if="item.orders_guest[0].order_guest_transactions[0].food || item.orders_guest[0].order_guest_transactions[1].food">
                              <p class="mb-0">{{ item.orders_guest[0].order_guest_transactions[0].quantity }}x {{ item.orders_guest[0].order_guest_transactions[0].food ? item.orders_guest[0].order_guest_transactions[0].food.name : '' }} </p>
                              <p class="mb-0">{{ item.orders_guest[0].order_guest_transactions[1].quantity }}x {{ item.orders_guest[0].order_guest_transactions[1].food ? item.orders_guest[0].order_guest_transactions[1].food.name : '' }} </p>
                              <p class="mb-0"> ... </p>
                            </div>
                          </div>
                          <div class="all-orders-item-prilog">
                            <p class="mb-1">{{ item.orders_guest[0].order_guest_transactions[0].meals || item.orders_guest[0].order_guest_transactions[0].food ? item.orders_guest[0].order_guest_transactions[0].meals ? item.orders_guest[0].order_guest_transactions[0].meals.price * item.orders_guest[0].order_guest_transactions[0].quantity : item.orders_guest[0].order_guest_transactions[0].food.price * item.orders_guest[0].order_guest_transactions[0].quantity : '' }}</p>
                            <p class="mb-1">{{ item.orders_guest[0].order_guest_transactions[1].meals || item.orders_guest[0].order_guest_transactions[1].food ? item.orders_guest[0].order_guest_transactions[1].meals ? item.orders_guest[0].order_guest_transactions[1].meals.price * item.orders_guest[0].order_guest_transactions[1].quantity : item.orders_guest[0].order_guest_transactions[1].food.price * item.orders_guest[0].order_guest_transactions[1].quantity : '' }}</p>
                          </div>
                        </div>
                        <div v-if="item.name != 'UKUPNO'" style="position: relative">
                          <a :title="$t('order_details')" @click.prevent="openDialogDetails(orders, item, inx)" style="display: block;position: absolute;top: 12px;text-decoration: none" href="">
                            <v-icon style="color: #fb6d3b;padding: 2px;border-radius: 6px">mdi-printer</v-icon> {{ $t('order_details')}}
                          </a>
                        </div>
                        <!-- CENA DOSTAVE-->
                        <div style="border-bottom: 1px solid #cfcfcf;" v-if="canSeeDelivery(item.orders_guest, item) && item.name != 'UKUPNO'" class="d-flex justify-content-center align-content-center w-100">
                          <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
                          <div style="width: 67%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">
                            + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
                          </div>
                        </div>
                        <!-- CENA DOSTAVE-->
                        <div v-if="item.name != 'UKUPNO'" class="d-flex justify-content-center py-4 align-content-center w-100">
                          <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 700;"></div>
                          <div style="width: 67%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 700">{{ priceMeals(item.orders_guest, item) }}</div>
                        </div>
                      </template>
                    </template>
                  </v-data-table>
                </template>
                <div v-if="$store.state.user.type == 'report_guest'" class="d-flex align-items-center mb-4 ikonice-font" style="justify-content: space-between;">
                  <h2></h2>
                  <div class="text-left" style="font-size: 9px">
                    <v-icon :title="$t('delivery_on_address')" style="font-size: 17px">mdi-truck-fast</v-icon> {{ $t('delivery_on_address')}}
                    <v-icon :title="$t('takeover')" style="margin-left: 15px;font-size: 17px">mdi-gift</v-icon> {{ $t('takeover')}}
                    <v-icon :title="$t('meal_in_restaurant')" style="margin-left: 15px;font-size: 17px">mdi-silverware-variant</v-icon> {{ $t('meal_in_restaurant')}}
                    <v-icon :title="$t('send_by_mail')" style="margin-left: 15px;font-size: 17px">mdi-cube-send</v-icon> {{ $t('send_by_mail')}}
                  </div>
                </div>
              </template>
              </v-card-text>
            </v-card>
            <v-card v-else flat>
              <!-- <v-btn
              @click="print('table3')"
              color="primary"
              small
              class="mt-2"
              ><v-icon>mdi-printer</v-icon>{{ translate("Штампај") }}</v-btn
            > -->
              <v-card-text>
                <v-data-table
                  :search="pretraga"
                  id="table3"
                  :headers="headers"
                  :items="desserts3"
                  :items-per-page="-1"
                  class="elevation-1"
                  hide-default-footer
                  disable-pagination
                >
                  <template
                    v-for="header in headers"
                    v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ translate(header.text) }}
                  </template>
                  <template
                    v-for="values in headers"
                    v-slot:[`item.${values.value}`]="{ item }"
                  >
                    {{ translate(item[values.value]) }}
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                  <template v-slot:item.shift_one="{ item }">
                    {{ translate(item.shift_one.toFixed(5)) }}
                  </template>
                  <template v-slot:item.shift_two="{ item }">
                    {{ translate(item.shift_two.toFixed(5)) }}
                  </template>
                  <template v-slot:item.shift_three="{ item }">
                    {{ translate(item.shift_three.toFixed(5)) }}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ translate(item.total.toFixed(5)) }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- JELA -->
          <v-tab-item>
            <v-card>
              <v-card-text>
                <div class="table-wrapper">
                <v-data-table
                  :search="pretraga"
                  id="table2"
                  :headers="headersJ"
                  :items="desserts2"
                  class="elevation-1"
                  hide-default-footer
                  disable-pagination
                  mobile-breakpoint="0"
                >
                  <template
                    v-for="header in headersJ"
                    v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ translate(header.text) }}
                    <span v-if="header.text == 'Прилог'">
                      <input type="checkbox" v-model="prilog">
                    </span>
                  </template>
                  <template
                    v-for="values in headersJ"
                    v-slot:[`item.${values.value}`]="{ item }"
                  >
                    <span :title="'ID: ' + item.id">{{ translate(item[values.value]) }}</span>
                  </template>
                  <template
                    v-for="values in headersJ"
                    v-slot:item.num="{ item, index }"
                  >
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                </v-data-table>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- OBROCI -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                    v-model="search4"
                    :items="desserts4.filter((i) => i.company)"
                    dense
                    filled
                    multiple
                    @input="obrok"
                    :label="$t('select_company')"
                    item-text="company"
                    item-value="id"
                    clear
                    chips
                    small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toogleFirmsObroci()"
                      small
                      class="mx-4"
                  >
                    {{$t("display_all")}}
                  </v-btn>
                </v-autocomplete>
<!--                :items="filterMealsTotal"-->
                <div id="table4">
                  <v-data-table v-if="obroci.length && search.length != 0"
                      :search="pretraga"
                      id="table1"
                      :headers="headersM"
                      :items="filterObrok"
                      class="elevation-1"
                      hide-default-footer
                      disable-pagination
                      mobile-breakpoint="0"
                  >
                    <template
                        v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }"
                    >
                      {{ translate(header.text) }}
                    </template>
                    <template v-slot:item.menu="{ item }">
                      <span @click="showMeal(item.id)">{{
                          translate(item.name)
                        }}</span>
                    </template>
                    <template v-slot:item.shift_one="{ item }">
                      {{ translate(item.shift_one) }}
                    </template>
                    <template v-slot:item.shift_two="{ item }">
                      {{ translate(item.shift_two) }}
                    </template>
                    <template v-slot:item.shift_three="{ item }">
                      {{ translate(item.shift_three) }}
                    </template>
                    <template v-slot:item.total="{ item }">
                      {{ translate(item.total) }}
                    </template>
                    <template v-slot:item.num="{ item, index }">
                      {{ item.name && item.name !== "УКУПНО" ? index + 1 : "" }}
                    </template>
                  </v-data-table>
                </div>
                <v-data-table v-if="search.length == 0"
                  :search="pretraga"
                  id="table1"
                  :headers="headersM"
                  :items="desserts"
                  class="elevation-1"
                  hide-default-footer
                  disable-pagination
                  mobile-breakpoint="0"
                >
                  <template
                    v-for="header in headers"
                    v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ translate(header.text) }}
                  </template>
                  <template v-slot:item.menu="{ item }">
                    <span @click="showMeal(item.id)">{{
                      translate(item.menu)
                    }}</span>
                  </template>
                  <template v-slot:item.shift_one="{ item }">
                    {{ translate(item.shift_one) }}
                  </template>
                  <template v-slot:item.shift_two="{ item }">
                    {{ translate(item.shift_two) }}
                  </template>
                  <template v-slot:item.shift_three="{ item }">
                    {{ translate(item.shift_three) }}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ translate(item.total) }}
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- PREUZETO -->
          <v-tab-item>
            <v-card flat>
              <!-- <v-btn
              @click="print('table5')"
              color="primary"
              small
              class="mt-2"
              ><v-icon>mdi-printer</v-icon>{{ translate("Штампај") }}</v-btn
            > -->
              <v-card-text>
                <v-data-table
                  :search="pretraga"
                  id="table5"
                  :headers="headers"
                  :items="total"
                  :items-per-page="-1"
                  class="elevation-1"
                  hide-default-footer
                  disable-pagination
                  mobile-breakpoint="0"
                >
                  <template
                    v-for="header in headers"
                    v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ translate(header.text) }}
                  </template>
                  <template
                    v-for="values in headers"
                    v-slot:[`item.${values.value}`]="{ item }"
                  >
                    {{ translate(item[values.value]) }}
                  </template>
                  <template
                    v-for="values in headers"
                    v-slot:item.num="{ item, index }"
                  >
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- KORISNICI -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                  v-model="search3"
                  :items="desserts4.filter((i) => i.company)"
                  dense
                  filled
                  @input="findUsers"
                  :label="$t('select_company')"
                  item-text="company"
                  item-value="id"
                  clear
                  chips
                  small-chips
                ></v-autocomplete>
              </v-card-text>
              <v-data-table
                :headers="headersU"
                :items="users"
                sort-by="status"
                id="table7"
                class="elevation-1"
                dense
                hide-default-footer
                mobile-breakpoint="0"
                disable-pagination>
                <template
                  v-for="header in headersU"
                  v-slot:[`header.${header.value}`]="{ header }"
                >
                  {{ translate(header.text) }}
                </template>
                <template
                  v-for="values in headersU"
                  v-slot:[`item.${values.value}`]="{ item }"
                >
                  {{ translate(item[values.value]) }}
                </template>
                <template v-slot:item.email="{item}">
                  {{item.email}}
                </template>
                <template
                  v-for="values in headersU"
                  v-slot:item.num="{ item, index }">
                  {{ item.id == 'УКУПНО:' ? '' : index + 1 }}
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <!-- PRIHODI -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-data-table
                  :search="pretraga"
                  :headers="headersCena"
                  :items="incomeReport"
                  :items-per-page="-1"
                  class="elevation-1"
                  item-key="day"
                  show-expand
                  hide-default-footer
                  disable-pagination
                  mobile-breakpoint="0"
                >
                  <template v-for="header in headersCena" v-slot:[`header.${header.value}`]="{ header }">
                    {{ translate(header.text) }}
                    <span v-if="header.text == 'Износ'">{{ $store.state.restaurant.settings.price }}</span>
                  </template>
                  <template v-for="values in headersCena" v-slot:[`item.${values.value}`]="{ item }">
                    {{ translate(item[values.value]) }}
                  </template>
                  <template v-slot:item.date="{ item }">
                    <span v-if="item.date == 'UKUPNO'">
                      {{ $t('totalU')}}:
                    </span>
                    <span v-else>{{ formatDateR(item.date) }}.</span>
                  </template>
                  <template #item.data-table-expand="{item, expand, isExpanded}">
                    <template v-if="item.children && item.children.length">
                      <v-btn icon
                             @click="expand(!isExpanded)"
                             class="v-data-table__expand-icon"
                             :class="{'v-data-table__expand-icon--active' : isExpanded}">
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                  </template>
                  <template v-slot:expanded-item="{item, isExpanded, expand}">
                    <template v-if="item.children">
                      <td :colspan="headersDete.length" class="px-0">
                        <v-data-table
                          dense
                          :headers="headersDete"
                          class="grey lighten-4"
                          hide-default-footer
                          disable-pagination
                          item-key="id"
                          :items="item.children || []"
                        >
                          <template v-for="header in headersDete" v-slot:[`header.${header.value}`]="{ header }">
                            {{ translate(header.text) }}
                            <span v-if="header.text == 'Износ'">{{ $store.state.restaurant.settings.price }}</span>
                          </template>
                          <template v-slot:item.state="{ item, index }">
                            <span style="border: 1px solid #cfcfcf;padding: 3px 10px;border-radius: 19px">{{ $t(state.find(id => id.name == item.state).alias) }}</span>
                          </template>
                          <template v-slot:item.date="{ item, index }">
                            {{moment(item.delivery_time, "h:mm A").format("HH:mm") }}
                          </template>
                          <template v-slot:item.price="{ item }">
                            <div style="width: 40%;padding-right: 25px;text-align: right">{{ item.price.toFixed(1) }}</div>
                          </template>
                          <template v-slot:item.action="{ item }">
                            <a v-if="item.action != 0" :title="$t('order_details')" href="" @click.prevent="openDialogAmount(item)">
                              <v-icon style="font-size: 17px" color="primary">mdi-format-list-bulleted</v-icon>
                            </a>
                          </template>
                        </v-data-table>
                      </td>
                    </template>
                  </template>
                  <template style="display: flex" v-slot:item.price="{ item }">
                    <span style="display: block;text-align: right;width: 28%">{{ item.price }}</span>
                    <span style="width: 72%"></span>
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item.name && item.name !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- FIRME DOSTAVA -->
          <v-tab-item>
            <v-data-table
                :search="pretraga"
                :headers="headersCompanies"
                :items="dessertsDeliveryCompany"
                :items-per-page="-1"
                class="elevation-1"
                item-key="day"
                hide-default-footer
                disable-pagination
                mobile-breakpoint="0"
            >
              <template v-for="header in headersCena" v-slot:[`header.${header.value}`]="{ header }">
                {{ translate(header.text) }}
              </template>
              <template v-slot:item.report="{ item, index }">
                <a @click.prevent="openCompanyDeliveryReport(item)">
                  <v-icon style="font-size: 17px" color="primary">mdi-format-list-bulleted</v-icon>
                </a>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
    <v-dialog v-model="dialog_restoran" max-width="50%" class="p-2">
      <v-card class="pb-3">
        <v-card-title style="background: #fff" class="position-relative">
          <v-btn color="#FB6D3B" text style="padding: 0 12px;min-width: 30px;border-radius: 25px;position: absolute;top: 5px;color: #333 !important;right: 5px;border: 1px solid #cfcfcf" @click="dialog_restoran = false">
            {{ translate("X") }}
          </v-btn>
        </v-card-title>
        <h3 class="text-center">{{ restoran_details.name }}</h3>
        <section class="p-3 mt-6">
          <p class="ml-3 py-2 mb-0 text-center" style="font-size: 15px;"> <v-icon>mdi-pin</v-icon>{{ restoran_details.city ? restoran_details.city.name : '' }}, {{ restoran_details.address }}</p>
          <p class="ml-3 py-2 mb-0 text-center" style="font-size: 15px;"> <v-icon>mdi-phone</v-icon> {{ restoran_details.phone }}</p>
          <p class="ml-3 py-2 mb-4 text-center" style="font-size: 15px;"> <v-icon>mdi-email</v-icon> {{ restoran_details.email }}</p>
        </section>
      </v-card>
    </v-dialog>
    <v-dialog v-model="change_order_status_popup" :max-width="containerClass" class="p-2">
      <v-card>
        <v-card-title style="background: #fff" class="position-relative">
          <v-btn color="#FB6D3B" text style="padding: 0 12px;min-width: 30px;border-radius: 25px;position: absolute;top: 5px;color: #333 !important;right: 5px;border: 1px solid #cfcfcf" @click="change_order_status_popup = false">
            {{ translate("X") }}
          </v-btn>
        </v-card-title>
        <v-col cols="12">
          <p id="promena_statusa" style="margin-top: -20px;padding-left: 10px; font-weight: 700;text-align: left; display: flex;justify-content: space-between">
            {{ $t('change_status')}}  #{{ changeOrderStatus.id }}
<!--            <span class="pr-13" style="color: red" v-if="statuses.find(id => id.id == 7)">Rezervisamo</span>-->
          </p>
          <div class="text-center">
            <div id="order-status" style="display: flex;flex-wrap: wrap;justify-content: end;">
              <a id="promena-statusa" v-if="[1,2,3,4,6,10,7,8,9].includes(val.id)" @click.prevent="changeOrderState(changeOrderStatus, val)" v-for="val in state" style="width: 22.5%;text-decoration: none;margin: 12px" href="">
                  <span class="dialog-status" v-if="val.id == 1" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #e0e0e0;color:#fff' : 'border:1px solid #e0e0e0;color:#333'">{{ $t(val.alias) }}</span>
                  <span class="dialog-status" v-if="val.id == 2" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #4caf50;color:#fff' : 'border:1px solid #4caf50;color:#4caf50'">{{ $t(val.alias) }}</span>
                  <span class="dialog-status" v-if="val.id == 3" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #2196f3;color:#fff' : 'border:1px solid #2196f3;color:#2196f3'">{{ $t(val.alias) }}</span>
                  <span class="dialog-status" v-if="val.id == 4" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #2e7d32;color:#fff' : 'border:1px solid #2e7d32;color:#2e7d32'">{{ $t(val.alias) }}</span>
                  <span class="dialog-status" v-if="val.id == 5" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: rgb(180 0 255);color:#fff' : 'border:1px solid rgb(180 0 255);color:rgb(180 0 255)'">{{ $t(val.alias) }}</span>
                  <span class="dialog-status" v-if="val.id == 6" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #2e7d32;color:#fff' : 'border:1px solid #2e7d32;color:#2e7d32'">{{ $t(val.alias) }}</span>
                  <span class="dialog-status" v-if="val.id == 10" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #195b1c;color:#fff' : 'border:1px solid #195b1c;color:#2e7d32'">{{ $t(val.alias) }}</span>
                  <span class="dialog-status" v-if="val.id == 7" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #F45A4E;color:#fff' : 'border:1px solid #F45A4E;color:#F45A4E'">{{ $t(val.alias) }}</span>
                  <span class="dialog-status" v-if="val.id == 8" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #F45A4E;color:#fff' : 'border:1px solid #F45A4E;color:#F45A4E'">{{ $t(val.alias) }}</span>
                  <span class="dialog-status" v-if="val.id == 9" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #F45A4E;color:#fff' : 'border:1px solid #F45A4E;color:#F45A4E'">{{ $t(val.alias) }}</span>
<!--                {{ $t(val.name) }}-->
              </a>
            </div>
<!--            <span class="text-left" style="position: absolute;bottom: 50px;left:50px" v-if="changeOrderStatus && changeOrderStatus.order_guest_status && changeOrderStatus.order_guest_status.user">-->
<!--              Dostavljac:-->
<!--              <span class="font-weight-bold">{{ changeOrderStatus.order_guest_status.user.name }}-->
<!--              {{ changeOrderStatus.order_guest_status.user.lastname }}-->
<!--              </span><br>-->
<!--             {{$t('phone') }}:-->
<!--              <span class="font-weight-bold">{{ changeOrderStatus.order_guest_status.user.phone }}</span>-->
<!--            </span>-->
          </div>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="change_order_status_popup_table" :max-width="containerClassTable" class="p-2">
      <v-card>
        <v-card-title style="background: #fff" class="position-relative">
          <v-btn color="#FB6D3B" text style="padding: 0 12px;min-width: 30px;border-radius: 25px;position: absolute;top: 5px;color: #333 !important;right: 5px;border: 1px solid #cfcfcf" @click="change_order_status_popup_table = false, desks_details_user = ''">
            {{ translate("X") }}
          </v-btn>
        </v-card-title>
        <v-col cols="12">
          <p id="promena_statusa" style="margin-top: -20px;padding-left: 10px; font-weight: 700;text-align: left; display: flex;justify-content: space-between">
            {{ $t('change_status')}}  #{{ changeOrderStatus.id }}
          </p>
          <div class="text-center">
            <p style="display: flex;justify-content: space-between;width: 60%;margin: 0 auto;padding: 5px;border-bottom: 1px solid #cfcfcf">
              <span>{{ $t('table')}}</span>
              <span>{{ $t('date')}}</span>
              <span>{{ $t('time')}}</span>
            </p>
            <p v-if="changeOrderStatus" style="display: flex;justify-content: space-between;width: 60%;margin: 0 auto;padding: 5px;border-bottom: 1px solid #cfcfcf">
              <span v-if="changeOrderStatus.users">
                {{ changeOrderStatus.users.name + ' ' + changeOrderStatus.users.lastname }}
              </span>
              <span v-else>
                  {{ desks_details_user.name + ' ' + desks_details_user.lastname }}
              </span>
              <span>{{ formatDate(changeOrderStatus.date) }}</span>
              <span>{{ formatTime(changeOrderStatus.delivery_time) }}</span>
            </p>
            <div id="order-status" style="display: flex;flex-wrap: wrap;justify-content: center;">
              <a id="promena-statusa" v-if="[1,2,4,6,10,7].includes(val.id)" @click.prevent="changeOrderState(changeOrderStatus, val)" v-for="val in stateTable" style="width: 46%;text-decoration: none;margin: 5px" href="">
                <span class="dialog-status" v-if="val.id == 1" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #B0BEC5;color:#000' : 'border:1px solid #B0BEC5;color:#333'"><v-icon style="color: #000" class="pr-2">mdi mdi-timer-sand-complete</v-icon> {{ $t(val.alias) }}</span>
                <span class="dialog-status" v-if="val.id == 2" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #4CAF50;color:#fff' : 'border:1px solid #4CAF50;color:#4caf50'"><v-icon style="color: #4caf50;" class="pr-2">mdi-silverware-fork-knife</v-icon> {{ $t(val.alias) }}</span>
                <span class="dialog-status" v-if="val.id == 4" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #FFA500;color:#fff' : 'border:1px solid #FFA500;color:#FFA500'"><v-icon style="color: #FFA500;" class="pr-2">mdi mdi-truck</v-icon> {{ $t(val.alias) }}</span>
                <span class="dialog-status" v-if="val.id == 6" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #2196F3;color:#fff' : 'border:1px solid #2196F3;color:#2196F3'"><v-icon style="color: #2196F3;" class="pr-2">mdi mdi-currency-usd</v-icon> {{ $t(val.alias) }}</span>
                <span class="dialog-status" v-if="val.id == 10" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #FFD700;color:#fff' : 'border:1px solid #FFD700;color:#FFD700'"><v-icon style="color: #FFD700;" class="pr-2">mdi mdi-credit-card-outline</v-icon> {{ $t(val.alias) }}</span>
                <span class="dialog-status" v-if="val.id == 7" :style="changeOrderStatus.state == val.id || changeOrderStatus.status == val.id || changeOrderStatus.state == val.name ? 'background: #F44336;color:#fff' : 'border:1px solid #F44336;color:#F44336'"><v-icon style="color: #F44336;" class="pr-2">mdi mdi-alert-octagon</v-icon> {{ $t(val.alias) }}</span>
              </a>
            </div>
          </div>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_desk" :max-width="containerClassTable" class="p-2">
      <v-card>
        <v-card-title style="background: #fff" class="position-relative">
          <v-btn color="#FB6D3B" text style="padding: 0 12px;min-width: 30px;border-radius: 25px;position: absolute;top: 5px;color: #333 !important;right: 5px;border: 1px solid #cfcfcf" @click="dialog_desk = false">
            {{ translate("X") }}
          </v-btn>
        </v-card-title>
        <v-col cols="12">
          <p id="promena_statusa" style="margin-top: -20px;padding-left: 10px; font-weight: 700;text-align: center;">
            {{ translate('Преглед столова') }}  {{ date[0] }}
          </p>
          <div class="text-center" style="display: flex;flex-wrap: wrap;padding: 15px">
             <div style="width: 25%;padding: 5px;" v-for="desk in desks">
               <div style="padding: 10px;border-radius: 7px">
                 <span @click.prevent="openDialogDetailsTable2(desk.user.orders_guest, desk.user)" v-if="desk.user && desk.user.orders_guest && desk.user.orders_guest.length > 0" style="padding: 5px;width:100%;display:inline-block;border-radius: 13px;margin: 0 auto" :style="desk.user.orders_guest[0].state == 'on-hold' ? 'background: #B0BEC5;color:#333' : desk.user.orders_guest[0].state == 'accepted' ? 'background: #4CAF50;color:#fff' : desk.user.orders_guest[0].state == 'booked-delivery' ? 'background: #2196f3;color:#fff' : desk.user.orders_guest[0].state == 'ready' ? 'background: #FFA500;color:#fff' : desk.user.orders_guest[0].state == 'charged-delivery' ? 'background: rgb(180 0 255);color:#fff' : desk.user.orders_guest[0].state == 'delivered' ? 'background: #2196F3;color:#fff' : desk.user.orders_guest[0].state == 'rejected' ? 'background: #F44336;color:#fff' : desk.user.orders_guest[0].state == 'delivery-canceled' ? 'background: #F45A4E;color:#fff' : desk.user.orders_guest[0].state == 'client-refused' ? 'background: #F45A4E;color:#fff' : desk.user.orders_guest[0].state == 'payed' ? 'background: #2196F3;color:#fff' : ''">
<!--                   {{ desk.user.orders_guest[0].state ? $t(stateTable[stateTable.map((stat) => stat.name).indexOf(desk.user.orders_guest[0].state)].alias) : '' }}-->
                   <a style="text-decoration: none;display: block;" :style="desk.user.orders_guest[0].state == 'on-hold' ? 'background: #B0BEC5;color:#333' : desk.user.orders_guest[0].state == 'accepted' ? 'background: #4CAF50;color:#fff' : desk.user.orders_guest[0].state == 'booked-delivery' ? 'background: #2196f3;color:#fff' : desk.user.orders_guest[0].state == 'ready' ? 'background: #FFA500;color:#fff' : desk.user.orders_guest[0].state == 'charged-delivery' ? 'background: rgb(180 0 255);color:#fff' : desk.user.orders_guest[0].state == 'delivered' ? 'background: #2196F3;color:#fff' : desk.user.orders_guest[0].state == 'rejected' ? 'background: #F44336;color:#fff' : desk.user.orders_guest[0].state == 'delivery-canceled' ? 'background: #F45A4E;color:#fff' : desk.user.orders_guest[0].state == 'client-refused' ? 'background: #F45A4E;color:#fff' : desk.user.orders_guest[0].state == 'payed' ? 'background: #2196F3;color:#fff' : ''" href="" >{{ desk.user ? desk.user.lastname : ''}}</a>
                 </span>
                 <span v-else style="border:1px solid #8a8a8a;border-radius: 13px;margin: 0 auto;width:100%;display:inline-block;padding: 5px">
                   {{ desk.user ? desk.user.lastname : ''}}
                 </span>
               </div>
             </div>
          </div>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_desk_detail" max-width="550px" class="p-2">
      <v-card style="box-shadow: none;">
        <v-card-title style="background: #fff;" class="position-relative">
          <v-btn color="#FB6D3B" text style="padding: 0 12px;min-width: 30px;border-radius: 25px;position: absolute;top: 5px;color: #333 !important;right: 5px;border: 1px solid #cfcfcf" @click="dialog_desk_detail = false">
            {{ translate("X") }}
          </v-btn>
        </v-card-title>
        <v-col cols="12">
          <p id="promena_statusa" style="margin-top: -20px;padding-left: 10px; font-weight: 700;text-align: center;">
            <span style="display: block;margin-bottom: 5px;position: absolute;left: 4px;top:3px"><img style="width: 50px;" :src="'https://restorani.bitlab.host/img/o.png'" alt="image"/></span>
            {{ translate('Наруџбе ') }} -
            <span class="ml-3 mt-3 text-center" style="font-size: 15px;font-weight: 700">
              {{ desks_details_user.name }}  {{ desks_details_user.lastname }}
            </span>
          </p>
          <div style="display: flex;flex-wrap: wrap;padding: 15px;width: 100%">
            <div style="padding: 5px;width: 100%;position: relative;margin-bottom: 6px;border:1px solid #cfcfcf;border-radius: 7px" v-for="desk in desks_details">
              <div style="display: flex;justify-content: space-between">
                <span id="ord_num" style="font-size: 30px;padding: 0;margin-top: 6px;display: inline-block"># {{ desk.id }}</span>
                <h3 class="text-left" style="font-size: 15px;font-weight: 400;text-align: center;margin-top: 15px">
                  <a href="#" @click.prevent="openDialogChangeOrderStatusTable(desk)" style="border-radius: 13px;padding: 4px 20px;margin: 0 auto" :style="desk.state == 'on-hold' ? 'background: #B0BEC5;color:#333' : desk.state == 'accepted' ? 'background: #4CAF50;color:#fff' : desk.state == 'booked-delivery' ? 'background: #2196f3;color:#fff' : desk.state == 'ready' ? 'background: #FFA500;color:#fff' : desk.state == 'charged-delivery' ? 'background: rgb(180 0 255);color:#fff' : desk.state == 'delivered' ? 'background: #2196F3;color:#fff' : desk.state == 'rejected' ? 'background: #F44336;color:#fff' : desk.state == 'delivery-canceled' ? 'background: #F45A4E;color:#fff' : desk.state == 'client-refused' ? 'background: #F45A4E;color:#fff' : desk.state == 'payed' ? 'background: #2196F3;color:#fff' : ''">
                    {{ desk.state && stateTable[stateTable.map((stat) => stat.name).indexOf(desk.state)] ? $t(stateTable[stateTable.map((stat) => stat.name).indexOf(desk.state)].alias) : '' }}
                  </a>
                  <span class="font-weight-bold"><v-icon>mdi mdi-calendar-range</v-icon> {{ formatDate(desk.date) }} <v-icon>mdi mdi-clock-time-three-outline</v-icon> {{ formatTime(desk.delivery_time) }}</span>
                </h3>
                <v-btn style="" @click="print('table18')" :style="'border-radius: 8px;background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" small class="mt-2">
                  <span :title=" $t('print')" ><v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background" class="mr-2">mdi-printer</v-icon></span>
                </v-btn>
              </div>
              <div style="padding: 10px;border-radius: 7px;">
                <div style="position: relative;box-shadow: none;" v-for="order in desk.order_guest_transactions">
                  <div v-if="order">
                    <div :style="order.meals || order.food ? 'border-bottom: 1px dashed #cfcfcf;padding-top: 5px' : ''" style="width: 100%;" class="d-flex justify-content-center align-content-center w-100">
                      <div :title="order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : ''" style="width: 75%;padding-bottom: 5px;font-size: 15px;color: #000000;font-weight: 500">
                        {{ order.meals || order.food ? order.quantity + 'x ' : '' }}
                        <span style="text-transform:uppercase;">{{ order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : '' }}</span>
                      </div>
                      <div style="width: 25%;text-align: right;font-size: 12px;padding-bottom: 2px">
                        {{ order.meals || order.food ? order.meals ? (order.meals.price * order.quantity) : (order.food.price * order.quantity) : '' }}
                      </div>
                    </div>
                    <div v-if="order.prilog.length" v-for="prilog in order.prilog">
                      <div style="color:#696969;width: 100%;text-transform:lowercase" class="d-flex justify-content-center align-content-center">
                        <div style="width: 56%;font-size: 12px;padding-bottom: 2px;color: #818181"> {{ prilog.quantity }}x {{ prilog.food && prilog.food.name ? translate(prilog.food.name) : '' }}</div>
                        <div style="width: 45%;text-align: right;font-size: 12px;padding-bottom: 2px">{{ (prilog.quantity * prilog.price) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-content-center" style="border-top: 1px dashed #cfcfcf;padding-top: 3px">
                  <div style="width: 55%;font-size: 15px;padding-bottom: 2px;font-weight: 700;">{{ $t('totalU')}} {{ $store.state.restaurant.settings && $store.state.restaurant.settings.price ? "(" + $store.state.restaurant.settings.price + ")" : '' }}:</div>
                  <div v-if="desks_details" style="width: 45%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 700">
                    {{ priceMealsTable(desk, desks_details) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_detail" :max-width="ordersClass" class="p-2">
      <v-card style="position: relative" v-if="dialog_detail || detail_data_all">
<!--        <v-card-title style="background: #fff" class="position-relative">-->
          <v-btn text style="padding: 0 12px;min-width: 30px;border-radius: 25px;position: absolute;top: 11px;color: #333 !important;right: 5px;border: 1px solid #888888" @click="dialog_detail = false">
            {{ translate("X") }}
          </v-btn>
          <v-btn style="position: absolute;top: 7px;right: 40px" @click="print('table18')" :style="'border-radius: 8px;background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" small class="mt-2">
<!--              <span id="stampaj">{{ $t("print") }}</span>-->
              <span :title=" $t('print')" ><v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background" class="mr-2">mdi-printer</v-icon></span>
          </v-btn>
<!--        </v-card-title>-->
        <v-col cols="12" id="table18">
          <h3 class="mt-1 text-center" style="font-size: 15px;font-weight: 400;text-align: center">
              <span style="display: block;margin-bottom: 5px;position: absolute;left: 4px;top:3px"><img style="width: 50px;" :src="'https://restorani.bitlab.host/img/o.png'" alt="image"/></span>
<!--               <span style="display: block;margin-bottom: 5px">{{ $t('orders_id')}}</span>-->
              <span id="ord_num" style="font-size: 30px;padding: 0 40px;margin-top: 6px;display: inline-block">#{{ detail_data.id }}</span>
          </h3>
<!--          <div class="mt-3 px-4 mx-2" style="width: 95%;border-bottom: 1px dashed #cfcfcf;"></div>-->
<!--          <p class="text-center pt-1 mb-0">НАРУЏБА ЈЕ СПРЕМНА ?</p>-->
<!--          <p class="text-center ">Не заборавите да кликнете на дугме "Испоручено" у апликацији</p>-->
          <div class="mt-3 px-4 mx-2" style="width: 95%;border-bottom: 1px dashed #cfcfcf;"></div>
          <h2 class="ml-3 mt-3 text-center" style="font-size: 15px;font-weight: 400">
            {{ firstLetter(detail_data_all.name) }} {{ detail_data.delivery_type == 3 ? capitalizeFirstLetter(detail_data_all.lastname) : capitalizeFirstLetter(detail_data_all.lastname) }}
            <span class="ml-3 mb-0 text-center" style="font-size: 15px;"> <v-icon>mdi-phone</v-icon> {{ detail_data_all.phone }}</span>
            <span class="ml-3 mb-0 text-center" v-if="$store.state.user.type == 'admin'"><v-icon>mdi-email</v-icon>  {{ detail_data_all.email }}</span>
          </h2>
          <p class="ml-3 mt-0 mb-0 text-center" v-if="detail_data_all.orders_guest[0].delivery_type == 0 && detail_data.location && detail_data.location.address" style="font-size: 15px;">
            <v-icon>mdi mdi-map-marker</v-icon> {{ detail_data.location ? translate(detail_data.location.address).replace(', Bosnia and Herzegovina', '').replace(', Bosna i Hercegovina', '').replace(', Босна и Херцеговина', '') : '' }}
            <span v-if="detail_data.location.firm">{{ $t('firm')}}: {{ detail_data.location ? translate(detail_data.location.firm) : '' }} </span>
            <span v-if="detail_data.location.city"> {{ detail_data.location.city ? '| ' + translate(detail_data.location.city.name) : '' }}</span>
          </p>
          <p class="ml-3 mt-0 mb-0 text-center" v-if="detail_data_all.orders_guest[0].delivery_type == 0 || detail_data_all.orders_guest[0].delivery_type == 3 && detail_data.location" style="font-size: 15px;">
            <span class="ml-3 mb-0 text-center" v-if="detail_data.delivery_type == 3" style="font-size: 15px;"> {{ detail_data.location ? translate(detail_data.location.address) : '' }}{{ detail_data.location && detail_data.location.city ? ', ' + translate(detail_data.location.city.name) : ''  }}</span>
            <span v-if="detail_data.location && detail_data.location.apartment">{{ $t('apartment')}}: {{ detail_data.location && detail_data.location.apartment != null ? detail_data.location.apartment : '' }}</span>
            <span v-if="detail_data.location && detail_data.location.floor"> {{ $t('floor')}}: {{ detail_data.location ? detail_data.location.floor : '' }}</span>
          </p>
          <p class="ml-3 mt-0 mb-0 text-center font15">
            {{ $t('ordered_1')}}: {{ formatDateTime(detail_data.created_at) }}
            <span v-if="detail_data_all.orders_guest[0].delivery_type != 3" class="font-weight-bold"> | {{ $t('for') }}: {{ formatDate(detail_data_date) }} {{ formatTime(detail_data.delivery_time) }}</span>
          </p>
          <v-card-text>
            <template v-for="order in detail_data.order_guest_transactions">
              <div :style="order.meals || order.food ? 'border-bottom: 1px dashed #cfcfcf;border-top: 1px dashed #cfcfcf;padding-top: 5px' : ''" style="width: 100%;" class="d-flex justify-content-center align-content-center w-100">
                <div :title="order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : ''" style="width: 75%;padding-bottom: 5px;font-size: 15px;color: #000000;font-weight: 500">
                  {{ order.meals || order.food ? order.quantity + 'x ' : '' }}
                  <span style="text-transform:uppercase;">{{ order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : '' }}</span>
                </div>
                <div class="font15" style="width: 25%;text-align: right;padding-bottom: 2px">
                  {{ order.meals || order.food ? order.meals ? (order.meals.price * order.quantity).toFixed(2) : (order.food.price * order.quantity).toFixed(2) : '' }}
                </div>
              </div>
              <div v-if="order.prilog.length" v-for="prilog in order.prilog">
                <div style="color:#696969;width: 100%;text-transform:lowercase" class="d-flex justify-content-center align-content-center">
                  <div style="width: 56%;font-size: 12px;padding-bottom: 2px;color: #818181"> {{ prilog.quantity }}x {{ translate(prilog.food.name) }}</div>
                  <div class="font15" style="width: 45%;text-align: right;padding-bottom: 2px">{{ (prilog.quantity * prilog.price).toFixed(2) }}</div>
                </div>
              </div>
            </template>
            <div v-if="canSeeDelivery(detail_data_all.orders_guest, detail_data)" style="border-bottom: 1px solid #cfcfcf;"  class="d-flex justify-content-center align-content-center w-100">
              <div style="width: 50%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
              <div style="width: 50%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">
                + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
              </div>
            </div>
            <div class="d-flex justify-content-center align-content-center" style="border-top: 1px dashed #cfcfcf">
                <div style="width: 55%;font-size: 15px;padding-bottom: 2px;font-weight: 700;">{{ $t('totalU')}} {{ $store.state.restaurant.settings && $store.state.restaurant.settings.price ? "(" + $store.state.restaurant.settings.price + ")" : '' }}:</div>
                <div v-if="detail_data_all" class="font15" style="width: 45%;text-align: right;padding-bottom: 2px;font-weight: 700">
                    {{ priceMeals(detail_data_all.orders_guest, detail_data) }}
                </div>
            </div>
            <h4 style="border-top: 1px solid #cfcfcf" v-if="detail_data.comments && detail_data.comments.length" class="pt-2 my-3">{{ $t('comments')}}:</h4>
            <div v-if="detail_data.comments && detail_data.comments.length">
              <ul style="width: 100%">
                <li :style="comment.restaurant_id ? 'text-align: right;justify-content:end' : 'text-align: left;justify-content:start'" style="list-style: none;display: flex;position: relative;margin: 5px 0;width: 100%;" v-for="comment in detail_data.comments">
                  <span v-if="! comment.restaurant_id" style="padding: 5px 20px;border-radius: 15px;background: #f5f5f5;">
                    <span>{{ comment.comment }}</span>
                    <span style="font-size: 10px; margin-left: 15px">{{ comment.date }}</span>
                  </span>
                  <span v-if="comment.restaurant_id" style="padding: 5px 20px;border-radius: 15px;background: #ffd5b8;">
                    <span style="font-size: 10px; margin-right: 15px">{{ comment.date }}</span>
                    <span>{{ comment.comment }}</span>
                  </span>
                </li>
              </ul>
            </div>
            <div class="mt-3" v-if="$store.state.restaurant.settings.comments_restaurant" style="text-align: right">
              <textarea placeholder="Unesite vašu poruku..." v-model="message_text" style="border: 1px solid #cfcfcf;padding: 5px" name="" id="" cols="95" rows="2"></textarea>
              <div>
                <button @click.prevent="sendMessage(detail_data)" style="border: none;padding: 5px 40px;background: #fb6d3b;color: #fff;border-radius: 15px">{{ $t('send') }}</button>
              </div>
            </div>
          </v-card-text>
        </v-col>
      </v-card>
      </v-dialog>
    <v-dialog v-model="dialog_detail_table" :max-width="ordersClass" class="p-2">
      <v-card style="position: relative" v-if="dialog_detail_table || detail_data_all_table">
        <v-btn text style="padding: 0 12px;min-width: 30px;border-radius: 25px;position: absolute;top: 11px;color: #333 !important;right: 5px;border: 1px solid #888888" @click="dialog_detail_table = false">
          {{ translate("X") }}
        </v-btn>
        <v-btn style="position: absolute;top: 7px;right: 40px" @click="print('table18')" :style="'border-radius: 8px;background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" small class="mt-2">
          <span :title=" $t('print')" ><v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background" class="mr-2">mdi-printer</v-icon></span>
        </v-btn>
        <v-col cols="12" id="table18">
          <h3 class="mt-1 text-center" style="font-size: 15px;font-weight: 400;text-align: center">
            <span style="display: block;margin-bottom: 5px;position: absolute;left: 4px;top:3px"><img style="width: 50px;" :src="'https://restorani.bitlab.host/img/o.png'" alt="image"/></span>
            <span id="ord_num" style="font-size: 30px;padding: 0 40px;margin-top: 6px;display: inline-block">#{{ detail_data_all_table.id }}</span>
            <br>
            <span @click.prevent="openDialogChangeOrderStatusTable(detail_data_all_table)" style="border-radius: 13px;padding: 4px 20px;margin: 0 auto;cursor: pointer" :style="detail_data_all_table.state == 'on-hold' ? 'background: #B0BEC5;color:#333' : detail_data_all_table.state == 'accepted' ? 'background: #4CAF50;color:#fff' : detail_data_all_table.state == 'booked-delivery' ? 'background: #2196f3;color:#fff' : detail_data_all_table.state == 'ready' ? 'background: #FFA500;color:#fff' : detail_data_all_table.state == 'charged-delivery' ? 'background: rgb(180 0 255);color:#fff' : detail_data_all_table.state == 'delivered' ? 'background: #2196F3;color:#fff' : detail_data_all_table.state == 'rejected' ? 'background: #F44336;color:#fff' : detail_data_all_table.state == 'delivery-canceled' ? 'background: #F45A4E;color:#fff' : detail_data_all_table.state == 'client-refused' ? 'background: #F45A4E;color:#fff' : detail_data_all_table.state == 'payed' ? 'background: #2196F3;color:#fff' : ''">
              {{ detail_data_all_table.state && stateTable[stateTable.map((stat) => stat.name).indexOf(detail_data_all_table.state)] ? $t(stateTable[stateTable.map((stat) => stat.name).indexOf(detail_data_all_table.state)].alias) : '' }}
            </span>
          </h3>
          <div class="mt-3 px-4 mx-2" style="width: 95%;border-bottom: 1px dashed #cfcfcf;"></div>
          <h2 class="ml-3 mt-3 text-center" style="font-size: 15px;font-weight: 400">
            {{ detail_data_all_table.name }} {{ detail_data_table.delivery_type == 3 ? detail_data_all_table.lastname : firstLetter(detail_data_all_table.lastname) }}
            <span class="ml-3 mb-0 text-center" v-if="$store.state.user.type == 'admin'"><v-icon>mdi-email</v-icon>  {{ detail_data_all_table.email }}</span>
          </h2>
          <p class="ml-3 mt-0 mb-0 text-center" style="font-size: 15px;">
<!--            <v-icon>mdi mdi-calendar-range</v-icon> {{ $t('ordered_1')}}: {{ formatDateTime(detail_data_all_table.created_at) }}-->
            <span v-if="detail_data_all_table.delivery_type != 3" class="font-weight-bold"><v-icon>mdi mdi-calendar-range</v-icon> {{ formatDate(detail_data_all_table.date) }} <v-icon>mdi mdi-clock-time-three-outline</v-icon> {{ formatTime(detail_data_all_table.delivery_time) }}</span>
          </p>
          <v-card-text>
            <template v-for="order in detail_data_all_table.order_guest_transactions">
              <div :style="order.meals || order.food ? 'border-bottom: 1px dashed #cfcfcf;border-top: 1px dashed #cfcfcf;padding-top: 5px' : ''" style="width: 100%;" class="d-flex justify-content-center align-content-center w-100">
                <div :title="order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : ''" style="width: 75%;padding-bottom: 5px;font-size: 15px;color: #000000;font-weight: 500">
                  {{ order.meals || order.food ? order.quantity + 'x ' : '' }}
                  <span style="text-transform:uppercase;">{{ order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : '' }}</span>
                </div>
                <div class="font15" style="width: 25%;text-align: right;padding-bottom: 2px">
                  {{ order.meals || order.food ? order.meals ? (order.meals.price * order.quantity).toFixed(2) : (order.food.price * order.quantity).toFixed(2) : '' }}
                </div>
              </div>
              <div v-if="order.prilog.length" v-for="prilog in order.prilog">
                <div style="color:#696969;width: 100%;text-transform:lowercase" class="d-flex justify-content-center align-content-center">
                  <div style="width: 56%;font-size: 12px;padding-bottom: 2px;color: #818181"> {{ prilog.quantity }}x {{ prilog.food && prilog.food.name ? translate(prilog.food.name) : '' }}</div>
                  <div class="font15" style="width: 45%;text-align: right;padding-bottom: 2px">{{ (prilog.quantity * prilog.price).toFixed(2) }}</div>
                </div>
              </div>
            </template>
            <div v-if="canSeeDelivery(detail_data_all_table.order_guest_transactions, detail_data_table)" style="border-bottom: 1px solid #cfcfcf;"  class="d-flex justify-content-center align-content-center w-100">
              <div style="width: 50%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>
              <div style="width: 50%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">
                + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}
              </div>
            </div>
            <div class="d-flex justify-content-center align-content-center" style="border-top: 1px dashed #cfcfcf">
              <div style="width: 55%;font-size: 15px;padding-bottom: 2px;font-weight: 700;">{{ $t('totalU')}} {{ $store.state.restaurant.settings && $store.state.restaurant.settings.price ? "(" + $store.state.restaurant.settings.price + ")" : '' }}:</div>
              <div v-if="detail_data_all_table" class="font15" style="width: 45%;text-align: right;padding-bottom: 2px;font-weight: 700">
                {{ priceMealsTable(detail_data_all_table, detail_data_table) }}
              </div>
            </div>
            <h4 style="border-top: 1px solid #cfcfcf" v-if="detail_data_table.comments && detail_data_table.comments.length" class="pt-2 my-3">{{ $t('comments')}}:</h4>
            <div v-if="detail_data_table.comments && detail_data_table.comments.length">
              <ul style="width: 100%">
                <li :style="comment.restaurant_id ? 'text-align: right;justify-content:end' : 'text-align: left;justify-content:start'" style="list-style: none;display: flex;position: relative;margin: 5px 0;width: 100%;" v-for="comment in detail_data_table.comments">
                  <span v-if="! comment.restaurant_id" style="padding: 5px 20px;border-radius: 15px;background: #f5f5f5;">
                    <span>{{ comment.comment }}</span>
                    <span style="font-size: 10px; margin-left: 15px">{{ comment.date }}</span>
                  </span>
                  <span v-if="comment.restaurant_id" style="padding: 5px 20px;border-radius: 15px;background: #ffd5b8;">
                    <span style="font-size: 10px; margin-right: 15px">{{ comment.date }}</span>
                    <span>{{ comment.comment }}</span>
                  </span>
                </li>
              </ul>
            </div>
            <div class="mt-3" v-if="$store.state.restaurant.settings.comments_restaurant" style="text-align: right">
              <textarea placeholder="Unesite vašu poruku..." v-model="message_text" style="border: 1px solid #cfcfcf;padding: 5px" name="" id="" cols="95" rows="2"></textarea>
              <div>
                <button @click.prevent="sendMessage(detail_data_table)" style="border: none;padding: 5px 40px;background: #fb6d3b;color: #fff;border-radius: 15px">{{ $t('send') }}</button>
              </div>
            </div>
          </v-card-text>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_amount" max-width="50%" class="p-2">
      <v-card style="position: relative">
        <v-btn text style="padding: 0 12px;min-width: 30px;border-radius: 25px;position: absolute;top: 5px;color: #333 !important;right: 5px;border: 1px solid #888888" @click="dialog_amount = false">
          {{ translate("X") }}
        </v-btn>
<!--        <v-btn style="position: absolute;top: 50px;right: 5px" @click="print('table13')" :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" small class="mt-2">-->
<!--          <span id="stampaj">{{ $t("print") }}</span>-->
<!--        </v-btn>-->
        <v-col cols="12" id="table13">
          <h3 class="mt-1 text-center" style="font-size: 15px;font-weight: 400">
            <span style="display: block;margin-bottom: 5px"><img style="width: 50px;" :src="'https://restorani.bitlab.host/img/o.png'" alt="image"/></span>
          </h3>
          <p class="px-4">{{ $t('user_id') }}: {{ amountItems.user_id }} | {{ $t('id_order') }}: {{ amountItems.id }}</p>
          <p class="px-4" style="display: flex;justify-content: space-between" v-if="amountItems">
            <span>{{ parseDatum(amountItems.date) }}</span>
            <span style="border: 1px solid #cfcfcf;padding: 3px 10px;border-radius: 19px">{{ $t(state.find(id => id.name == amountItems.state).alias) }}</span>
          </p>
          <div class="mt-3 px-4 mx-2" style="width: 95%;border-bottom: 1px dashed #cfcfcf;"></div>
          <v-card-text>
            <template v-for="order in amountItems.porudzbe">
              <p style="border-bottom: 1px solid #cfcfcf;display: flex;justify-content: space-between;align-items: center;margin-bottom: 0">
                <span style="width: 100%">
                  <span style="display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #cfcfcf;flex-wrap: wrap">
                     <span style="width: 65%">
                      <span style="font-weight: 700">{{ order.quantity }}x</span> {{ order.meals ? order.meals.name : order.food.name }}
                    </span>
                    <span style="width: 15%;text-align: right">{{ order.price }}</span>
                    <span style="font-weight: 700;width: 15%;text-align: right">{{ (order.price * order.quantity).toFixed(2) }} {{ $store.state.restaurant.settings.price }}</span>
                  </span>
                  <span style="display: flex;padding-left: 7px;color: #737373;width: 100%;justify-content: space-between;border-bottom: 1px dashed #cfcfcf;flex-wrap: wrap" v-for="pri in order.prilog">
                    <span style="width: 70%">- {{ pri.food.name }} </span>
                    <span style="width: 15%;text-align: right">{{ pri.price }}</span>
                    <span style="font-weight: 700;width: 15%;text-align: right">{{ (order.price * order.quantity).toFixed(2) }} {{ $store.state.restaurant.settings.price }}</span>
                  </span>
                </span>
              </p>
<!--              <span style="display: block;text-align: right;margin-top: 0;margin-bottom: 5px">-->
<!--                <span style="font-weight: 700">{{ order.price * order.quantity }} {{ $store.state.restaurant.settings.price }}</span>-->
<!--              </span>-->
            </template>
            <p style="display: flex;justify-content: space-between" class="mt-3">
              <span>{{ $t('totalU') }}: </span>
              <span class="font15" style="font-weight: 700">{{ totalDetailPrice(amountItems.porudzbe)}} {{ $store.state.restaurant.settings.price }}</span>
            </p>
          </v-card-text>
        </v-col>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.status"
      :timeout="2000"
      top
      rounded="pill"
      color="#C20606"
    >
      {{ translate(snackbar.text) }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.status = false"
        >
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="60%" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img v-if="$store.state.restaurant"
                    :src="
                      selectedMeal.image
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          $store.state.restaurant.id +
                          '/medium/' +
                          selectedMeal.image.name
                        : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ml-sm-n16 my-sm-2 mt-sm-16">
                  <v-row align="end" class="fill-height">
                    <v-card elevation="0" class="pa-5">
                      <v-card-title
                        ><h3>ID: {{ selectedMeal.id }} <br> {{ translate(selectedMeal.name) }}</h3></v-card-title>

                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <template v-for="(eat, i) in selectedMeal.foods">
                            <v-col cols="12" class="font-weight-bold pt-2">
                              <v-row no-gutters justify="space-between">
                                <span
                                  ><h3>{{ translate(eat.name) }}</h3></span
                                >
                              </v-row>
                            </v-col>
                            <v-col cols="12">{{
                              translate(eat.description)
                            }}</v-col>
                          </template>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="60%" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog2 = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img
                    :src="
                      selectedUser.image_path
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          selectedUser.image_path
                        : 'https://st3.depositphotos.com/13159112/17145/v/600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ml-sm-n16 my-sm-2 mt-sm-16">
                  <v-row align="end" id="korisnik" class="fill-height">
                    <v-card elevation="0" class="pa-5">
                      <v-card-title
                        ><h3>
                          {{
                            translate(selectedUser.name).charAt(0) +
                            ". " +
                            translate(selectedUser.lastname)
                            +' ('+ selectedUser.id+')'
                          }}
                        </h3></v-card-title
                      >

                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <v-col cols="12" class="font-weight-bold pt-2">
                            <v-row no-gutters justify="space-between">
                              <span><h3>{{ selectedUser.email }}</h3></span>
                            </v-row>
                          </v-col>
<!--                          <v-col cols="12"-->
<!--                            >{{ $t("select_notifications") }}-->
<!--                            {{-->
<!--                              selectedUser.notifications-->
<!--                                ? translate(selectedUser.notifications)-->
<!--                                : $t("not_available")-->
<!--                            }}</v-col-->
<!--                          >-->
                          <v-col cols="12"> {{ selectedUser.phone }}</v-col>
                          <v-col cols="12">
                            {{ selectedUser.external_code }}</v-col
                          >
                          <v-col cols="12">
                            {{ selectedUser.address }}
                          </v-col>
<!--                          <v-col cols="12">-->
<!--                            {{-->
<!--                              selectedUser.is_transferable-->
<!--                                ? $t("can_change_location")-->
<!--                                : ""-->
<!--                            }}-->
<!--                          </v-col>-->
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCompanyDelivery" max-width="90%" scrollable>
      <v-card>
        <v-card-text style="padding: 0;margin: 0">
          <v-card elevation="0">
            <v-card-title class="primary">
              <span style="color:#fff">{{ companies_delivery_firms.name }} | ID: {{ companies_delivery_firms.company_id }}</span>
              <v-spacer></v-spacer>
              <v-btn style="color:#fff" icon @click="dialogCompanyDelivery = false">
                <v-icon x-medium>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4" v-if="delivery_company_monthly">
              <v-data-table
                  :headers="headersCompaniesMonthly"
                  :items="delivery_company_monthly"
                  class="elevation-1"
              >
                <template v-slot:item.state="{ item, index }">
                  {{ $t(statuses[item.state].name) }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import moment from "moment";
import conf from "../config";
import { db, onSnapshot, doc, collection, query, orderBy, limit } from "../plugins/firebase";
import {capitalize} from "vuetify-loader/lib/util";

export default {
  data() {
    return {
      audio: null,
      restaurant_ids: [],
      conf: conf,
      reportG: '',
      totalMeals: [],
      name: "Намирнице",
      pretraga: "",
      delivery_id: "",
      message_text: '',
      search3: "",
      search4: "",
      search5: "",
      search2: "",
      search: "",
      table_display: 1,
      moment: moment,
      date: [new Date().toISOString().substr(0, 10)],
      dates: null,
      menu: false,
      dialog_restoran: false,
      restoran_details: '',
      dialog_amount: false,
      activeTab: [
          true,
          false,
          false,
      ],
      today: true,
      tommorow: false,
      rest_days: false,
      dialog_detail: false,
      dialog_detail_table: false,
      dialog_desk_detail: false,
      change_order_status_popup: false,
      change_order_status_popup_table: false,
      report: null,
      report2: null,
      reservedItems: [],
      reportPrices: [],
      reservedItemsData: '',
      selectedMeal: {
        foods: [],
        name: "",
        image: {
          name: "",
        },
      },
      selectedUser: {
        name: "test",
        lastname: "test",
        image_path: "test",
      },
      total: [],
      printTable: "table14",
      companies: [],
      meals: [],
      users: [],
      obroci: [],
      prilog: false,
      dialog: false,
      dialog2: false,
      dialogCompanyDelivery: false,
      noContract: '',
      noContractNextDay: '',
      noContractOriginal: '',
      noContractOriginalNextDay: '',
      companies_delivery: '',
      companies_delivery_firms: '',
      entity: '',
      snackbar: {
        status: "",
        text: "",
      },
      statuses: [
        {id: 0, name: 'deleted'},
        {id: 1, name: 'ordered_meal1'},
        {id: 2, name: 'prepared_for_delivery1'},
        {id: 3, name: 'booked_by_delivery'},
        {id: 4, name: 'ready'},
        {id: 5, name: 'taken_by_delivery1'},
        {id: 6, name: 'delivered_11'},
        {id: 7, name: 'declined_by_restoran1'},
        {id: 8, name: 'delivery_cancel'},
        {id: 9, name: 'order_not_taken1'},
      ],
      state: [
        {id: 0, name :'deleted', alias: 'delete'},
        {id: 1, name :'on-hold', alias: 'ordered_meal1'},
        {id: 2, name :'accepted', alias: 'prepared_for_delivery1'},
        {id: 3, name :'booked-delivery', alias: 'booked_by_delivery'},
        {id: 4, name :'ready', alias: 'ready'},
        {id: 5, name :'charged-delivery', alias: 'taken_by_delivery1'},
        {id: 6, name :'delivered', alias: 'delivered_11'},
        {id: 10, name :'payed', alias: 'payed'},
        {id: 7, name :'rejected', alias: 'declined_by_restoran1'},
        {id: 8, name :'delivery-canceled', alias: 'delivery_cancel'},
        {id: 9, name :'client-refused', alias: 'order_not_taken1'},
        {id: 99, name :'undefined', alias: 'undefined'}
      ],
      stateTable: [
        {id: 0, name :'deleted', alias: 'delete'},
        {id: 1, name :'on-hold', alias: 'ordered_meal1'},
        {id: 2, name :'accepted', alias: 'prepared_for_delivery1'},
        {id: 4, name :'ready', alias: 'ready'},
        {id: 6, name :'delivered', alias: 'delivered_11'},
        {id: 10, name :'payed', alias: 'payed'},
        {id: 7, name :'rejected', alias: 'declined_by_restoran1'},
        {id: 99, name :'undefined', alias: 'undefined'}
      ],
      incomeReport: [],
      status_id: '',
      headers: [
        {text: this.$t('r_b'), value: "num",},
        { text: this.$t('grocery'), value: "menu" },
        { text: this.$t('1st_shift'), value: "shift_one" },
        { text: this.$t('2nd_shift'), value: "shift_two" },
        { text: this.$t('3rd_shift'), value: "shift_three" },
        { text: this.$t('total'), value: "total" },
      ],
      headersM: [
        {text: this.$t('r_b'), value: "num",},
        { text: this.$t('meal'), value: "menu" },
        // { text: this.$t('1st_shift'), value: "shift_one" },
        // { text: this.$t('2nd_shift'), value: "shift_two" },
        // { text: this.$t('3rd_shift'), value: "shift_three" },
        { text: this.$t('total'), value: "total" },
      ],
      headersU: [
        { text: this.$t('r_b'), value: "num",},
        { text: "ID", value: "id",},
        { text: this.$t('name'), value: "name",},
        { text: this.$t('last_name'), value: "lastname",},
        { text: this.$t('email'), value: "email",},
        // { text: this.$t('time'), value: "date",},
        // { text: "Обављена наруџба", value: "ordered",},
        { text: this.$t('ordered'), value: "order_count",},
        { text: this.$t('taken'), value: "order_payed",},
      ],
      headersP: [
        {text: this.$t('r_b'), value: "num",},
        { text: this.$t('meal'), value: "menu" },
        { text: this.$t('1st_shift'), value: "shift_one" },
        { text: this.$t('2nd_shift'), value: "shift_two" },
        { text: this.$t('3rd_shift'), value: "shift_three" },
        { text: this.$t('total'), value: "total" },
      ],
      headersJ: [
        { text: this.$t('r_b'), value: "num",},
        { text: this.$t('m'), value: "menu" },
        // { text: this.$t('1st_shift'), value: "shift_one" },
        // { text: this.$t('2nd_shift'), value: "shift_two" },
        // { text: this.$t('3rd_shift'), value: "shift_three" },
        { text: this.$t('total'), value: "total" },
        { text: this.$t('attachment_meal'), value: "prilog", sortable: false },
      ],
      headersG: [
        { text: this.$t('ID'), value: "id", sortable: true},
        { text: this.$t('r_b'), value: "num", sortable: true},
        { text: this.$t('name_surname'), value: "name", sortable: true },
        { text: this.$t('address'), value: "address", sortable: true },
        { text: this.$t('time'), value: "time", sortable: true },
        { text: this.$t('ordered'), value: "qty", sortable: false },
        { text: this.$t('status'), value: "state", sortable: true },
      ],
      headersTable: [
        { text: this.$t('r_b'), value: "id", sortable: true},
        { text: this.$t('name'), value: "name", sortable: true },
        { text: this.$t('table'), value: "lastname", sortable: true },
        { text: this.$t('date'), value: "date", sortable: true },
        { text: this.$t('time'), value: "delivery_time", sortable: true },
        { text: this.$t('ordered'), value: "qty", sortable: true },
        { text: this.$t('status'), value: "state", sortable: true },
      ],
      headersR: [
        { text: this.$t('r_b'), value: "num",},
        { text: this.$t('name_surname'), value: "name" },
        { text: this.$t('address'), value: "address" },
        { text: this.$t('time'), value: "time" },
        { text: this.$t('status'), value: "state" },
        { text: this.$t('qty_meal') + " (" + this.$store.state.restaurant !== null && this.$store.state.restaurant.settings ? this.$store.state.restaurant.settings.price : '' + ")", value: "qty" },
      ],
      headersN: [
        { text: this.$t('r_b'), value: "num", align: 'start',},
        { text: this.$t('id_order'), value: "id", align: 'start',},
        { text: this.$t('delivery_time'), value: "time", align: 'start', },
        { text: this.$t('meal_meal'), value: "name", align: 'start', },
        { text: this.$t('price'), value: "price", align: 'right', },
        { text: this.$t('quantity'), value: "quantity", align: 'right', },
        { text: this.$t('total') + " (" + this.$store.state.restaurant !== null && this.$store.state.restaurant.settings ? this.$store.state.restaurant.settings.price : '' + ")", value: "qty", align: 'right', },
      ],
      headersS: [
        { text: this.$t('r_b'), value: "num", },
        { text: this.$t('meal'), value: "name" },
        { text: this.$t('1st_shift'), value: "shift_one" },
        { text: this.$t('2nd_shift'), value: "shift_two" },
        { text: this.$t('3rd_shift'), value: "shift_three" },
        { text: this.$t('total'), value: "total" },
      ],
      headersK: [
        { text: this.$t('r_b'), value: "num", },
        { text: this.$t('restaurant'), value: "restoran" },
        { text: this.$t('meal_num'), value: "meal_num" },
      ],
      headersCena: [
        { text: this.$t('day'), value: "date" },
        { text: this.$t('orderID'), value: "order_number", },
        { text: this.$t('amount'), value: "price" },
        // { text: this.$t('order_details'), value: "action" },
        { text: '', value: 'data-table-expand' },
      ],
      headersDete: [
        { text: this.$t('orderID'), value: "order_number", },
        { text: this.$t('time'), value: "date" },
        { text: this.$t('status'), value: "state" },
        { text: this.$t('amount'), value: "price" },
        { text: this.$t('order_details'), value: "action" },
      ],
      headersCompanies: [
        { text: this.$t('firm'), value: "name", },
        { text: this.$t('reserve_orders'), value: "total_rezervisane" },
        { text: this.$t('taken_orders'), value: "total_preuzete" },
        { text: this.$t('delivered_orders'), value: "total_isporucene" },
        { text: this.$t('return'), value: "total_vracene" },
        { text: this.$t('total'), value: "total" },
        { text: this.$t('details'), value: "report" },
      ],
      headersCompaniesMonthly: [
        { text: 'ID porudzbe', value: "id", },
        { text: 'Ime dostavljaca', value: "user" },
        { text: 'Status', value: "state" },
        { text: 'Adresa', value: "address" },
        { text: 'Ukupna cena', value: "price" },
        { text: 'Vreme isporuke', value: "delivery_time" },
      ],
      childRestorans: '',
      changeOrderStatus: '',
      tab: null,
      items1: [
        {
          tab: this.$t('guests'),
          content: "Tab 9 Content",
          print: "table9",
          icon: "mdi-shopping-outline",
        },
        {
          tab: this.$t('orders') + ' ' + this.$t('table'),
          content: "Tab 123 Content",
          print: "table123",
          icon: "mdi-desk",
        },
        {
          tab: this.$t('groceri'),
          content: "Tab 3 Content",
          print: "table3",
          icon: "mdi-corn",
        },
        {
          tab: this.$t('meals_main'),
          content: "Tab 2 Content",
          print: "table2",
          icon: "mdi-food-drumstick",
        },
        {
          tab: this.$t('meals'),
          content: "Tab 1 Content",
          print: "table1",
          icon: "mdi-bowl-mix",
        },
        // {
        //   tab: this.$t('pak'), // skloniti
        //   content: "Tab 3 Content",
        //   print: "table4",
        //   icon: "mdi-shopping-outline",
        // },
        // {
        //   tab: this.$t('o'), // skoloniti
        //   content: "Tab 5 Content",
        //   print: "table6",
        //   icon: "mdi-format-list-bulleted-type",
        // },
        {
          tab: this.$t('taken'),
          content: "Tab 4 Content",
          print: "table5",
          icon: "mdi-truck-delivery",
        },
        {
          tab: this.$t('users'),
          content: "Tab 7 Content",
          print: "table7",
          icon: "mdi-face",
        },
        // {
        //   tab: this.$t('t_o'), // skloniti
        //   content: "Tab 8 Content",
        //   print: "table8",
        //   icon: "mdi-check-underline-circle",
        // },
        // {
        //   tab: this.$t('r_o'), // skloniti
        //   content: "Tab 9 Content",
        //   print: "table9",
        //   icon: "mdi-shopping-outline",
        // },
        // {
        //   tab: this.$t('un'), // skloniti
        //   content: "Tab 10 Content",
        //   print: "table10",
        //   icon: "mdi-shopping-outline",
        // },
        // {
        //   tab: "Ресторани",
        //   content: "Tab 9 Content",
        //   print: "table9",
        //   icon: "mdi-shopping-outline",
        // },
        {
          tab: this.$t('income'),
          content: "Tab 19 Content",
          print: "table19",
          icon: "mdi-currency-usd",
        },
        {
          tab: this.$t('companies_delivery'),
          content: "Tab 20 Content",
          print: "table20",
          icon: "mdi-domain",
        },
      ],
      detail_data: '',
      detail_data_table: '',
      detail_data_all: '',
      detail_data_all_table: '',
      detail_data_date: '',
      detail_data_date_table: '',
      choosedDate: '',
      amountItems: '',
      delivery_company_monthly_report: '',
      delivery_company_monthly: '',
      originalNoContract: '',
      all_restaurants_original: [],
      all_restaurants: [],
      tables: '',
      dialog_desk: false,
      desks: '',
      desks_details: '',
      desks_details_order: '',
      desks_details_user: '',
    };
  },
  watch: {
    tab() {
      if (this.tab == 1) {
        this.getTableData()
      }
    }
  },
  created() {
    // axios.post("/companies/delivery-companies-by-restaurant", {'restaurant': this.$store.state.restaurant.id}).then(({data}) => {
    //   console.log(data)
    // });
    if (this.$store.state.user && this.$store.state.user.type == 'admin' && this.$store.state.user.restaurants) {
      this.$store.state.user.restaurants.filter(item => {
        if (item.settings && item.settings.lice_bez_ugovora) {
          this.all_restaurants.push(item)
          this.all_restaurants_original.push(item)
        }
      })
      this.headersG.splice(1, 0,  { text: this.$t('restaurant'), value: "restaurant_id", sortable: true },)
      this.$forceUpdate()
    }
    // axios.get('/menus/mob-app?from=2023-10-17&to=2023-10-17&type=rucak').then(({data}) => {
    //
    // })
    this.status_id = {id: 1, name: 'ordered_meal1'}
    this.load();

    if(this.$store.state.user && this.$store.state.user.type == 'report_guest') {
      this.items1 = []
      this.headers = this.headersG
      this.reportG = this.noContractCompanies
      this.name = "Гости"
      this.tab = 0
      this.today = true
      this.tommorow = false
      this.rest_days = false
      this.activeTab[0] = true
      this.activeTab[1] = false
      this.activeTab[2] = false
      this.printTable = "table8"
      this.items1.push(
          {
            tab: this.$t('orders'),
            content: "Tab 9 Content",
            print: "table8",
            icon: "mdi-calendar",
          },
          {
            tab: this.$t('report'),
            content: "Tab 9 Content",
            print: "table10",
            icon: "mdi-calendar",
          },
      )
      this.selectDay('today', this.date[0])
    }

    // axios.get('/orders/delivery-user-orders?user_id=' + this.$store.state.user.id + '&status=' + 1).then(({data}) => {
    //
    // })
    // axios.get('/orders/guest-orders/' + 702 + '/show').then(({data}) => {
    //
    // })
  },

  methods: {
    openDesk() {
      this.dialog_desk = true
    },
    openDialogRestoran(item) {
      this.dialog_restoran = true
      this.restoran_details = item
    },
    getNotifications() {

      const collectionRef = collection(db, `restaurants/${this.$store.state.restaurant.id}/orders`)
      //const q = query(collectionRef, orderBy('created_at', 'desc'));

      onSnapshot(collectionRef, (querySnapshot) => {
        this.$store.commit('SET_NOTIFICATION', [])
        let message = null

        querySnapshot.docChanges().forEach(item => {
          if(item.type === 'added') {
            if(!message) {
              message = item.doc.data()

              this.$store.commit('INCREASE_NOTIFICATION', message)

              this.audio = new Audio('./sounds/order-notification.mp3');
              this.audio.addEventListener("canplaythrough", this.playAudio());

              this.$store.commit('SET_SNACKBAR', true)
            }
          }
        })
      });
    },
    playAudio() {
      this.audio.play().catch(e => this.playAudioRe())
    },
    playAudioRe() {
        window.addEventListener('click', () => {
          this.audio.play()
        }, { once: true })
    },
    customSort: function(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'time') {
          const timeA = a.orders_guest[0]['delivery_time'];
          const timeB = b.orders_guest[0]['delivery_time'];
          // Validate and parse time strings
          const regex = /^(\d{2}):(\d{2}):(\d{2})$/;
          const matchA = timeA.match(regex);
          const matchB = timeB.match(regex);

          if (matchA && matchB) {
            const dateA = new Date(0, 0, 0, matchA[1], matchA[2], matchA[3]);
            const dateB = new Date(0, 0, 0, matchB[1], matchB[2], matchB[3]);
            // Compare in ascending order
            return dateA - dateB;
          } else {
            console.warn('Invalid time format or missing delivery time for some items.');
            return 0;
          }
        } else {
          if (index[0] == 'restaurant_id') {
            return a.orders_guest[0].restaurant.name.toLowerCase().localeCompare(b.orders_guest[0].restaurant.name.toLowerCase());
          }
        }
      });
      return items;
    },
    filterRestaurantByEntity() {
      let podaci = []
      if (this.entity == 'shop') {
        this.all_restaurants_original.filter(item => {
          if (item.entity_type == this.entity) {
            podaci.push(item)
          }
        })
      }
      if (this.entity == 'restaurant') {
        this.all_restaurants_original.filter(item => {
          if (item.entity_type == this.entity) {
            podaci.push(item)
          }
        })
      }
      if (this.entity == '') {
          podaci = this.all_restaurants_original
      }
      this.all_restaurants = podaci
    },
    sendMessage(detail_data) {
      let data = {}
      data['user_id'] = detail_data.user_id
      data['order_guest_id'] = detail_data.order_number
     // data['restaurant_id'] = detail_data.restaurant_id
      data['comment'] = this.message_text
      data['date'] = moment().format('YYYY-MM-DD')
      // axios.post('/reports/send-comment-restaurant', data).then(({data}) => {
      //   if (data) {
      //     this.detail_data.comments.push(data)
      //     this.message_text = ''
      //   }
      // })
      axios.post('/orders/send-comment-guest', data).then(({data}) => {
        if (data) {
          this.detail_data.comments.push(data)
          this.message_text = ''
        }
      })
    },
    filterRestaurant() {
       this.axiosNoContractMultiple()
    },
    openCompanyDeliveryReport(item) {
      this.companies_delivery_firms = item
      this.dialogCompanyDelivery = true

      let dates = {}
      dates['date'] = moment(this.date[0]).format("YYYY-MM-DD")
      dates['date2'] = moment(this.date[1]).format("YYYY-MM-DD")
      dates['company_id'] = item.company_id
      axios.post("/orders/get-delivery-company-monthly", dates).then(({data}) => {
        let podaci = []
        data.filter(items => {
          if (items.company == item.company_id) {
            podaci.push(items)
          }
        })
        this.delivery_company_monthly = podaci
      })
    },
    getTableData() {
      axios.get('/v2/reports/daily/qr-table/' + this.$store.state.restaurant.id  + "?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")).then(({data}) => {
        data.orders.filter(item => {
            item.name = item.users.name
            item.lastname = item.users.lastname
        })
        this.tables = data.orders
        this.desks = data.desks
      })
      this.$forceUpdate()
    },
    getTableDataMultiple() {
      axios.get('/v2/reports/daily/qr-table-multiple?date=' +
          moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD") + "&restaurants=" + this.restaurant_ids).then(({data}) => {
        data.orders.filter(item => {
          item.name = item.users.name
          item.lastname = item.users.lastname
        })
        this.tables = data.orders
        this.desks = data.desks
      })
      this.$forceUpdate()
    },
    filterByDelivery() {
      this.noContract = this.noContractOriginal
      if (this.delivery_id.id == 3) {
        this.noContract = this.noContractOriginal
      }
        // else if (this.delivery_id.id == 4) {
      //   axios.get('/reports/daily/qr-table/' + this.$store.state.restaurant.id  + "?date=" +
      //       moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" +
      //       moment(this.date[1]).format("YYYY-MM-DD")).then(({data}) => {
      //         data.filter(item => {
      //           if (item.state == this.stateTable.find(id => id.id == item.state).id) {
      //             item.state = this.state.find(id => id.id).name
      //           }
      //         })
      //     this.tables = data
      //   })
      // }
      else {
        let mealFood = {}
        for (let i in this.noContract) {
          mealFood[i] = {}
          for(let n in this.noContract[i]) {
            mealFood[i][n] = []
            this.noContract[i][n].filter(itm => {
              itm.orders_guest.filter(it => {
                if(it.delivery_type == this.delivery_id.id) {
                  mealFood[i][n].push(itm)
                }
              })
            })
          }
        }
        this.noContract = mealFood
      }
    },
    totalDetailPrice(items) {
      let price = 0
      if (items) {
        items.filter(item => {
          price += item.price * item.quantity
          if (item.prilog.length) {
            item.prilog.filter(pr => {
              console.log(pr.price)
              if (pr.quantity > 0) {
                price += pr.price * pr.quantity
              } else {
                price += pr.price
              }
            })
          }
        })
      }

      return price
    },
    openDialogAmount(item) {
      this.dialog_amount = true
      let arr = []
      item.order_guest_transactions.filter(i => {
        arr.push(i)
      })
      item.porudzbe = arr
      this.amountItems = item
    },
    formatDateR(date) {
      return moment(date).format('DD.MM')
    },
    parseDatum(date) {
      return moment(date).format('DD.MM.YYYY')
    },
    selectDay(type, day) {
      if(type == 'today') {
        this.today = true
        this.tommorow = false
        this.rest_days = false
        this.activeTab[0] = true
        this.activeTab[1] = false
        this.activeTab[2] = false
        this.choosedDate = moment(day).format("YYYY-MM-DD")
        this.axiosNoContractNextDay(day)
      }
      if(type == 'tomorrow') {
        this.tommorow = true
        this.today = false
        this.rest_days = false
        this.activeTab[0] = false
        this.activeTab[1] = true
        this.activeTab[2] = false
        let nextDay = moment(day).add(1, 'days')
        this.choosedDate = moment(nextDay).format("YYYY-MM-DD")
        this.axiosNoContractNextDay(nextDay)
      }
      if(type == 'rest_days') {
        this.today = false
        this.tommorow = false
        this.rest_days = true
        this.activeTab[0] = false
        this.activeTab[1] = false
        this.activeTab[2] = true
        let restDay = moment(day).add(2, 'days')
        this.choosedDate = moment(restDay).format("YYYY-MM-DD")
        this.axiosNoContractNextDay(restDay)
      }
      this.$forceUpdate()
    },
    firstLetter(lastname) {
      if (lastname) {
        return capitalize(lastname[0]) + '.'
      }
    },
    openDialogDetails(orders, data, datum) {
      this.dialog_detail = true
      this.detail_data = orders
      this.detail_data_all = data
      this.detail_data_date = datum
    },
    openDialogDetailsTable(orders, data, datum) {
      this.dialog_detail_table = true
      this.detail_data_table = orders
      this.detail_data_all_table = data
      this.detail_data_date_table = datum
    },
    openDialogDetailsTable2(orders, order) {
      this.dialog_desk_detail = true
      this.desks_details = orders
      this.desks_details_user = order
      this.$forceUpdate()
    },
    openDialogChangeStatus() {

    },
    openDialogChangeOrderStatus(item) {
      this.change_order_status_popup = true
      this.changeOrderStatus = item
    },
    openDialogChangeOrderStatusTable(item) {
      this.change_order_status_popup_table = true
      this.changeOrderStatus = item
    },
    getItemText(item) {
      return this.$t(`${item.name}`);
    },
    filterByStatus() {
      if (this.$store.state.user.type == 'report_guest') {
        let active = ''
        this.activeTab.filter((item,index) => {
          if (item) {
            active = index
          }
        })
        if(active == 0) {
          this.choosedDate = moment(this.date[0]).format("YYYY-MM-DD")
          this.axiosNoContractNextDay(this.date[0])
        }
        if(active == 1) {
          let nextDay = moment(this.date[0]).add(1, 'days')
          this.choosedDate = moment(nextDay).format("YYYY-MM-DD")
          this.axiosNoContractNextDay(nextDay)
        }
        if(active == 2) {
          let restDay = moment(this.date[0]).add(2, 'days')
          this.choosedDate = moment(restDay).format("YYYY-MM-DD")
          this.axiosNoContractNextDay(restDay)
        }
      } else {
        if (this.restaurant_ids.length > 0) {
          this.axiosNoContractMultiple()
          this.getTableDataMultiple()
        } else {
          this.axiosNoContract()
        }
      }
    },
    formatDate(date) {
      return moment(date).format('DD.MM.')
    },
    formatDateTime(date) {
      return moment(date).format('DD.MM. hh:mm')
    },
    totalUserQtu(shift) {
      let total = 0;
      shift.filter(item => {
        total += parseFloat(item.quantity)
      })

      return total > 0 ? total : 0
    },
    capitalizeFirstLetter(str) {
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    },
    getAllFirmsForReservedMeal() {
      axios.get('/reports/daily/reserved-meals/' + this.$store.state.restaurant.id + '/firms').then((data) => {
        this.reservedItems = data.data
      })
    },
    reservedMeals() {
      axios.get(
          "/reports/daily/restaurant/" +
          this.$store.state.restaurant.id +
          "/rezervni-obroci?company=" + this.search5.join() + "&date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      ).then((data) => {
        this.reservedItemsData = data.data
      })
    },
    obrokMultiple() {
      let rep = ''
      this.obroci = ''
      axios.get(
          "/reports/daily/multiple-restaurant-guest/obrok?company=" + this.search4 + "&date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD") + "&restaurants=" + this.restaurant_ids
      ).then((res) => {
        rep = res.data;
        let arr2 = [];
        if (res.data) {
          for (let company in res.data) {
            arr2.push({ company: company, foods: [] });
            for (let shift in res.data[company]) {
              for (let shift2 in res.data[company][shift]) {
                let index = arr2.findIndex((i) => i.company === company);
                let index2 = arr2[index].foods.findIndex(
                    (i) => i.name === shift2
                );
                if (index2 > -1) {
                  arr2[index].foods[index2].shift_one += shift === "1-smena" ? rep[company][shift][shift2].quantity : 0;
                  arr2[index].foods[index2].shift_one_payed += shift === "1-smena" ? rep[company][shift][shift2].quantity_payed : 0;
                  arr2[index].foods[index2].shift_two += shift === "2-smena" ? rep[company][shift][shift2].quantity : 0;
                  arr2[index].foods[index2].shift_two_payed += shift === "2-smena" ? rep[company][shift][shift2].quantity_payed : 0;
                  (arr2[index].foods[index2].shift_three += shift === "3-smena" ? rep[company][shift][shift2].quantity : 0),
                      (arr2[index].foods[index2].shift_three_payed += shift === "3-smena" ? rep[company][shift][shift2].quantity_payed : 0),
                      (arr2[index].foods[index2].total += rep[company][shift][shift2].quantity),
                      (arr2[index].foods[index2].total_payed += rep[company][shift][shift2].quantity_payed);
                } else {
                  arr2[index].foods.push({
                    name: shift2,
                    id: rep[company][shift][shift2].id,
                    shift_one:
                        shift === "1-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_one_payed:
                        shift === "1-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    shift_two:
                        shift === "2-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_two_payed:
                        shift === "2-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    shift_three:
                        shift === "3-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_three_payed:
                        shift === "3-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    total: rep[company][shift][shift2].quantity,
                    total_payed: rep[company][shift][shift2].quantity_payed,
                  });
                }
              }
            }
          }
          arr2.forEach((company, index) => {
            company.foods.push({
              name: "УКУПНО",
              shift_one: company.foods.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0),
              shift_one_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_one_payed;
              }, 0),
              shift_two: company.foods.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0),
              shift_two_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_two_payed;
              }, 0),
              shift_three: company.foods.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
              shift_three_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_three_payed;
              }, 0),
              total:
                  company.foods.reduce((sum, item) => {
                    return sum + item.shift_one;
                  }, 0) +
                  company.foods.reduce((sum, item) => {
                    return sum + item.shift_two;
                  }, 0) +
                  company.foods.reduce((sum, item) => {
                    return sum + item.shift_three;
                  }, 0),
              total_payed:
                  company.foods.reduce((sum, item) => {
                    return sum + item.shift_one_payed;
                  }, 0) +
                  company.foods.reduce((sum, item) => {
                    return sum + item.shift_two_payed;
                  }, 0) +
                  company.foods.reduce((sum, item) => {
                    return sum + item.shift_three_payed;
                  }, 0),
            });
          });
        }
        this.obroci = arr2
      });
    },
    obrok() {
      let rep = ''
      this.obroci = ''
      axios.get(
        "/reports/daily/restaurant-guest/" +
        this.$store.state.restaurant.id +
        "/obrok?company=" + this.search4 + "&date=" +
        moment(this.date[0]).format("YYYY-MM-DD") +
        "&date2=" +
        moment(this.date[1]).format("YYYY-MM-DD")
      ).then((res) => {
        rep = res.data;
        let arr2 = [];
        if (res.data) {
          for (let company in res.data) {
            arr2.push({ company: company, foods: [] });
            for (let shift in res.data[company]) {
              for (let shift2 in res.data[company][shift]) {
                let index = arr2.findIndex((i) => i.company === company);
                let index2 = arr2[index].foods.findIndex(
                    (i) => i.name === shift2
                );
                if (index2 > -1) {
                  arr2[index].foods[index2].shift_one += shift === "1-smena" ? rep[company][shift][shift2].quantity : 0;
                  arr2[index].foods[index2].shift_one_payed += shift === "1-smena" ? rep[company][shift][shift2].quantity_payed : 0;
                  arr2[index].foods[index2].shift_two += shift === "2-smena" ? rep[company][shift][shift2].quantity : 0;
                  arr2[index].foods[index2].shift_two_payed += shift === "2-smena" ? rep[company][shift][shift2].quantity_payed : 0;
                  (arr2[index].foods[index2].shift_three += shift === "3-smena" ? rep[company][shift][shift2].quantity : 0),
                  (arr2[index].foods[index2].shift_three_payed += shift === "3-smena" ? rep[company][shift][shift2].quantity_payed : 0),
                  (arr2[index].foods[index2].total += rep[company][shift][shift2].quantity),
                  (arr2[index].foods[index2].total_payed += rep[company][shift][shift2].quantity_payed);
                } else {
                  arr2[index].foods.push({
                    name: shift2,
                    id: rep[company][shift][shift2].id,
                    shift_one:
                        shift === "1-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_one_payed:
                        shift === "1-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    shift_two:
                        shift === "2-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_two_payed:
                        shift === "2-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    shift_three:
                        shift === "3-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_three_payed:
                        shift === "3-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    total: rep[company][shift][shift2].quantity,
                    total_payed: rep[company][shift][shift2].quantity_payed,
                  });
                }
              }
            }
          }
          arr2.forEach((company, index) => {
            company.foods.push({
              name: "УКУПНО",
              shift_one: company.foods.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0),
              shift_one_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_one_payed;
              }, 0),
              shift_two: company.foods.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0),
              shift_two_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_two_payed;
              }, 0),
              shift_three: company.foods.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
              shift_three_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_three_payed;
              }, 0),
              total:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three;
                }, 0),
              total_payed:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three_payed;
                }, 0),
            });
          });
        }
        this.obroci = arr2
      });
    },
    priceDelivery(meals) {
      let price = 0
      if (meals) {
        meals.filter(meal => {
          price += meal.quantity * meal.price
        })
      }
      return price
    },
    formatTime(time) {
      if(time) {
        let formatedTime = time.split(':')
        return formatedTime[0] + ':' + formatedTime[1]
      }
    },
    changeOrderState(item, value) {
      item.state = value.id
      axios.post('/reports/change-order-status', item).then(({data}) => {
        if(data) {
          this.change_order_status_popup = false
          if (item.order_guest_status) {
            item.order_guest_status = data.data
            item.state = data.data.status
          }
          if (this.tab == 1) {
            item.state = data.data.status
            this.change_order_status_popup_table = false
          }
        }
      })
    },
    canSeeDelivery(items, item) {
      let can = false;
      if (items && items.length) {
        items.filter(it => {
          if(it.delivery_type == 3) {
            can = true
          }
        })
      }

      return can
    },

    priceMeals(meal,item) {
      if (!meal.total) {
        let total = 0;
        let totalP = 0;
        meal.filter(or => {
          if (or.order_guest_transactions) {
            or.order_guest_transactions.filter(m => {
              if (m.prilog && m.prilog.length) {
                m.prilog.filter(pr => {
                  totalP += (parseFloat(pr.price) * pr.quantity)
                })
              }
              if (m.meals && m.meals.price) {
                total += (parseFloat(m.meals.price) * m.quantity)
              } else if (m.food && m.food.price) {
                total += (parseFloat(m.food.price) * m.quantity)
              }
            })
          }
        })
        if(this.$store.state.restaurant.settings.guest.restoran_delivery && this.canSeeDelivery(meal, item)) {
          let deliveryPrice = this.$store.state.restaurant.settings.guest.delivery_price ? this.$store.state.restaurant.settings.guest.delivery_price : 0
          return total + totalP + parseFloat(deliveryPrice)
        }
        return (total + totalP).toFixed(2)
      }
    },
    priceMealsTable(meal,item) {
      if (!meal.total) {
        let total = 0;
        let totalP = 0;
        if (meal.order_guest_transactions) {
          meal.order_guest_transactions.filter(m => {
            if (m.prilog && m.prilog.length) {
              m.prilog.filter(pr => {
                totalP += (parseFloat(pr.price) * pr.quantity)
              })
            }
            if (m.meals && m.meals.price) {
              total += (parseFloat(m.meals.price) * m.quantity)
            } else if (m.food && m.food.price) {
              total += (parseFloat(m.food.price) * m.quantity)
            }
          })
        }

        if(this.$store.state.restaurant.settings.guest.restoran_delivery && this.canSeeDelivery(meal, item)) {
          let deliveryPrice = this.$store.state.restaurant.settings.guest.delivery_price ? this.$store.state.restaurant.settings.guest.delivery_price : 0
          return total + totalP + parseFloat(deliveryPrice)
        }
        return total + totalP
      }
    },
    toogleFirms() {
      if (this.search.length > 0) {
        this.search = []
      } else
      if (this.search.length == 0) {
        this.search = []
        this.desserts4.filter(item => {
          this.search.push(item.company)
        })
      }
    },
    toogleRestorants1() {
      if (this.restaurant_ids.length == 0) {
        this.all_restaurants.filter(item => {
          this.restaurant_ids.push(item.id)
          this.noContract = ''
        })
      } else {
        this.restaurant_ids = []
      }
      this.axiosNoContractMultiple()
    },
    toogleRestorants() {
      if ((this.$store.state.user && this.$store.state.user.restaurants)) {
        if (this.restaurant_ids.length > 0) {
          let orders = {}
          if (this.noContract && this.noContract.orders) {
            orders['orders'] = {}
            for (let i in this.noContract.orders) {
              if (this.noContract.orders[i]) {
                this.noContract.orders[i].filter(item => {
                  if(this.restaurant_ids.includes(item.orders_guest[0].restaurant_id)) {
                    orders['orders'][i] = item
                  }
                })
              }
            }
          }
          this.originalNoContract = orders
        } else {
          this.originalNoContract = this.noContract
        }
      }
    },
    toogleFirmsObroci() {
      if (this.search4.length > 0) {
        this.search4 = []
      } else
      if (this.search4.length == 0) {
        this.search4 = []
        this.desserts4.filter(item => {
          this.search4.push(item.company)
        })
        if (this.restaurant_ids.length > 0) {
          this.obrokMultiple()
        } else {
          this.obrok()
        }
      }
    },
    toogleFirmsReserve() {
      if (this.search5.length > 0) {
        this.search5 = []
      } else
      if (this.search5.length == 0) {
        this.search5 = []
        this.desserts6.filter(item => {
          this.search5.push(item.company)
        })
      }
    },
    selectAllFirms(item) {
      if(this.$store.state.user.type == 'report_guest') {
        if (item == 'Наруџбе') {
          this.selectDay('today', this.date[0])
        }
      }
      if(item == 'Оброци ') {
        this.search = []
        this.desserts4.filter(item => {
          this.search.push(item.company)
        })
      }
      // if(item == 'Резервни оброци') {
      //   this.getAllFirmsForReservedMeal()
      // }
    },
    showMeal(id) {
      axios.get("/meals/" + id).then((res) => {
        this.selectedMeal = res.data;
        this.dialog = true;
      });
    },
    showUsers(user) {
      this.selectedUser = user;
      this.dialog2 = true;
    },
    toggle() {
      if (
        this.search2.length === this.desserts4.filter((i) => i.company).length
      ) {
        this.search2 = [];
      } else {
        this.search2 = []
        this.search2 = this.desserts4
          .filter((i) => i.company)
          .map((i) => i.company);
      }

      this.findMeals();
    },
    findUsers() {
      axios
        .get(
          "/reports/daily/company-guest/" +
            this.search3 +
            "/korisnici?date=" +
            // "/userlist?date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")
        )
        .then((res) => {
          let ids = []
          // res.data[Object.keys(res.data)].meals.filter(item => {
          //   Object.values(item.users).filter(meal => {
          //     ids.push(meal)
          //   })
          // })
          // res.data[Object.keys(res.data)].users.filter(user => {
          //   ids.filter(i => {
          //     if (i[0]) {
          //       if(user.id == i[0].id) {
          //         user.ordered_val = i[0].quantity
          //       }
          //     }
          //   })
          // })
          let order_count_total = 0;
          let order_payed_total = 0;
          res.data.users.filter(user => {
            order_count_total += user.order_count
            order_payed_total += user.order_payed
          })
          res.data.users.push({
            'id': 'УКУПНО:',
            'order_count': order_count_total,
            'order_payed': order_payed_total
          })
          this.users = res.data.users;
          let allUsers = this.groupBy(this.users, 'id')
          let usr = [];
          for (let i in allUsers) {
            if(i != 'УКУПНО:') {
              let oq = 0
              let op = 0
              let un = '';
              let ul = '';
              let em = '';
              allUsers[i].filter(item => {
                un = item.name
                ul = item.lastname
                em = item.email
                oq += item.order_count
                op += item.order_payed
              })
              usr.push({
                'id': i,
                'name': un,
                'lastname': ul,
                'email': em,
                'order_count': oq,
                'order_payed': op
              })
            }
          }
          let order_count_total1 = 0;
          let order_payed_total1 = 0;
          usr.filter(user => {
            order_count_total1 += user.order_count
            order_payed_total1 += user.order_payed
          })
          usr.push({
            'id': 'УКУПНО:',
            'order_count': order_count_total1,
            'order_payed': order_payed_total1
          })
          this.users = usr
        });
    },
    findMeals() {
      if (this.search2.length) {
        axios
          .get(
            "/reports/daily/company/" +
              this.search2.join() +
              "/userlist-guest?date=" +
              moment(this.date[0]).format("YYYY-MM-DD") +
              "&date2=" +
              moment(this.date[1]).format("YYYY-MM-DD")
          )
          .then((res) => {
            this.meals = res.data;
          });
      }
    },
    print(id, type = false) {
      console.log(id)
      if (id === "table6") {
        const WinPrint = window.open(
          "",
          "",
          "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );

        WinPrint.document.write(`<head>`);

        for (let index in this.meals) {
          const prtHtml = document.getElementById(`table6_${index}`).innerHTML;
          let stylesHtml = "";
          for (const node of [
            ...document.querySelectorAll('link[rel="stylesheet"], style'),
          ]) {
            stylesHtml += node.outerHTML;
          }

          WinPrint.document.write(` ${stylesHtml}`);
        }

        WinPrint.document.write(`<style>
          table {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
            max-width: 100vW;
          }
          td, th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
          }
          tr:nth-child(even) {
          background-color: #f1f1f1;
          }
          </style>
          </head>`);

        for (let index in this.meals) {
          const prtHtml = document.getElementById(`table6_${index}`).innerHTML;
          let stylesHtml = "";
          for (const node of [
            ...document.querySelectorAll('link[rel="stylesheet"], style'),
          ]) {
            stylesHtml += node.outerHTML;
          }

          WinPrint.document.write(`
            <body>
              ${
                this.$store.state.restaurant.name
              }: ${index}   ${moment(
            this.date[0]
          ).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
              <strong>${prtHtml}</strong>
            </body>`);

          WinPrint.document.write('<p style="page-break-after:always;"></p>');
        }

        // WinPrint.document.close();
        // WinPrint.focus();
        // WinPrint.print();
        // WinPrint.close();
        WinPrint.document.close();
        WinPrint.focus();

        WinPrint.onload = function () {
          WinPrint.print();
          WinPrint.close();
        }
      } else {
        const prtHtml = document.getElementById(id).innerHTML;
        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }
        // //console.log(stylesHtml);
        // Open the print window
        const WinPrint = window.open(
          "",
          "",
          "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );

        WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
              <style>
             table {
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
                max-width: 100vW;
            }

            td, th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }

            tr:nth-child(even) {
              background-color: #f1f1f1;
            }
            </style>

            </head>
            <body>
              ${
                this.$store.state.restaurant.name
              }: ${this.name}   ${moment(
            this.date[0]
        ).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
              <strong>${prtHtml}</strong>
            </body>
          </html>`);

        // WinPrint.document.close();
        // WinPrint.focus();
        // WinPrint.print();
        // WinPrint.close();
        WinPrint.document.close();
        WinPrint.focus();

        WinPrint.onload = function () {
          WinPrint.print();
          WinPrint.close();
        }
      }
    },
    sort(o) {
      let keys = Object.keys(o).sort((a,b) => new Date(b).getTime() - new Date(a).getTime());
      let r = {};
      for (let k of keys) r[k] = o[k];
      return r;
    },
    axiosNoContract() {
      let mealFood = []
      let dates1 = {}
      let totalOfTotalPrice = 0;
      this.incomeReport = []
      axios.get("/reports/daily/company/" +
          this.$store.state.restaurant.id +
          "/no-contract?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + '&status=' + this.status_id.id).then((res) => {
        let datum = ''
        let totalNarudzbe = 0
        let totalCena = 0
        for (let i in res.data) {
          dates1[i] = ''
          dates1[i] = this.sort(res.data[i])
          for (let n in dates1[i]) {
            let price = 0
            let id = ''
            let porudzbe = []
            let children = []
            totalNarudzbe += dates1[i][n].length
            dates1[i][n].filter(order => {
              order.orders_guest.filter(o => {
                datum = o.date
                id = o.id
                o.order_number = o.id
                children.push(o)
                porudzbe.push(o.order_guest_transactions)
                let cena = 0
                o.order_guest_transactions.filter(ord => {
                  //ord.porudzba = o
                  if (ord.prilog.length) {
                    ord.prilog.filter(pr => {
                      if (pr.quantity > 0) {
                        price += pr.price * pr.quantity
                        totalCena += pr.price * pr.quantity
                        cena += pr.price * pr.quantity
                      } else {
                        price += pr.price
                        totalCena += pr.price
                        cena += pr.price
                      }
                    })
                  }
                  mealFood.push(ord)
                  price += ord.price * ord.quantity
                  cena += ord.price * ord.quantity
                  totalCena += ord.price * ord.quantity
                })
                o.price = cena
              })
              order.orders = mealFood
            })
            this.incomeReport.push({
              'day': moment(datum).format('DD'),
              'date': datum,
              'order_id': id,
              'order_number': dates1[i][n].length,
              'price': price.toFixed(2),
              'orders': porudzbe,
              'children': children,
            })
            dates1[i][n].push({
              'orders_guest': [{
                'id': this.translate('УКУПНО'),
                'order_guest_transactions': []
              }],
              'num': 'УКУПНО',
              'total_price': true,
              'name': '',
              'lastname': '',
              'total': 'УКУПНО',
              'price': price
            })
            totalOfTotalPrice += price
          }
        }
        this.incomeReport.push({
          'date': 'UKUPNO',
          'order_number': totalNarudzbe,
          'price': totalCena.toFixed(2),
          'action': 0,
        })
        console.log(totalOfTotalPrice)
        this.noContract = dates1;
        //this.noContract.orders['totalOfTotal'] = totalOfTotalPrice
        this.noContractOriginal = dates1;
      })
    },
    axiosNoContractMultiple() {
      let mealFood = []
      let dates1 = {}
      axios.get("/reports/daily/company/multiple-no-contract?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + '&status=' + this.status_id.id + "&restaurants=" + this.restaurant_ids).then((res) => {
        for (let i in res.data) {
          dates1[i] = ''
          dates1[i] = this.sort(res.data[i])
          for (let n in dates1[i]) {
            dates1[i][n].filter(order => {
              order.orders_guest.filter(o => {
                o.order_guest_transactions.filter(ord => {
                  mealFood.push(ord)
                })
              })
              order.orders = mealFood
            })
          }
        }
        let datum = ''
        let totalNarudzbe = 0
        let totalCena = 0
        for (let i in res.data) {
          dates1[i] = ''
          dates1[i] = this.sort(res.data[i])
          for (let n in dates1[i]) {
            let price = 0
            let id = ''
            let porudzbe = []
            let children = []
            totalNarudzbe += dates1[i][n].length
            dates1[i][n].filter(order => {
              order.orders_guest.filter(o => {
                datum = o.date
                id = o.id
                o.order_number = o.id
                children.push(o)
                porudzbe.push(o.order_guest_transactions)
                let cena = 0
                o.order_guest_transactions.filter(ord => {
                  //ord.porudzba = o
                  if (ord.prilog.length) {
                    ord.prilog.filter(pr => {
                      if (pr.quantity > 0) {
                        price += pr.price * pr.quantity
                        totalCena += pr.price * pr.quantity
                        cena += pr.price * pr.quantity
                      } else {
                        price += pr.price
                        totalCena += pr.price
                        cena += pr.price
                      }
                    })
                  }
                  mealFood.push(ord)
                  price += ord.price * ord.quantity
                  cena += ord.price * ord.quantity
                  totalCena += ord.price * ord.quantity
                })
                o.price = cena
              })
              // order.orders = mealFood
            })
            dates1[i][n].push({
              'orders_guest': [{
                'id': this.translate('УКУПНО'),
                'order_guest_transactions': []
              }],
              'num': 'УКУПНО',
              'total_price': true,
              'name': '',
              'lastname': '',
              'total': 'УКУПНО',
              'price': price
            })
          }
        }
        this.noContract = dates1;
        this.noContractOriginal = dates1;
      })
      this.getTableDataMultiple()
    },
    axiosNoContractNextDay(day) {
      let mealFood = []
      let dates1 = {}
      this.noContract = ''
      axios.get("/reports/daily/company/" +
          this.$store.state.restaurant.id +
          "/no-contract-next-day?date=" + moment(day).format("YYYY-MM-DD") + "&date2=" + moment(day).format("YYYY-MM-DD") + '&status=' + this.status_id.id).then((res) => {
        for (let i in res.data) {
          let datum = ''
          dates1[i] = ''
          dates1[i] = this.sort(res.data[i])
          let price = 0
          for(let n in dates1[i]) {
            dates1[i][n].filter(order => {
              order.orders_guest.filter(o => {
                o.order_guest_transactions.filter(ord => {
                  if (ord.prilog.length) {
                    ord.prilog.filter(pr => {
                      if (pr.quantity > 0) {
                        price += pr.price * pr.quantity
                      } else {
                        price += pr.price
                      }
                    })
                  }
                  price += ord.price * ord.quantity
                  mealFood.push(ord)
                })
              })
              order.orders = mealFood
            })
            dates1[i][n].push({
              'orders_guest': [{
                'id': this.translate('УКУПНО'),
                'order_guest_transactions': []
              }],
              'num': 'УКУПНО',
              'total_price': true,
              'name': '',
              'lastname': '',
              'total': 'UKUPNO',
              'price': price
            })
          }
          if(this.choosedDate) {
            datum = dates1[i][this.choosedDate]
            if(this.rest_days) {

            }
          }
          this.noContractOriginal = dates1;
          if(this.today) {
            dates1[i] = ''
            dates1[i] = {}
            dates1[i][this.choosedDate] = datum
            this.noContract = dates1;
          }
          if(this.tommorow) {
            dates1[i] = ''
            dates1[i] = {}
            dates1[i][this.choosedDate] = datum
            this.noContract = dates1;
          }
          if(this.rest_days) {
            for (let n in dates1[i]) {
              let price = 0
              dates1[i][n].filter(order => {
                order.orders_guest.filter(o => {
                  o.order_guest_transactions.filter(ord => {
                    if (ord.prilog.length) {
                      ord.prilog.filter(pr => {
                        if (pr.quantity > 0) {
                          price += pr.price * pr.quantity
                        } else {
                          price += pr.price
                        }
                      })
                    }
                    price += ord.price * ord.quantity
                    mealFood.push(ord)
                  })
                })
                order.orders = mealFood
              })
              dates1[i][n].push({
                'orders_guest': [{
                  'id': this.translate('УКУПНО'),
                  'order_guest_transactions': []
                }],
                'num': 'УКУПНО',
                'total_price': true,
                'name': '',
                'lastname': '',
                'total': 'UKUPNO',
                'price': price
              })
            }
            this.noContract = dates1;
          }
        }
      })
    },
    ordersForSelectedRestaurants() {
      if (this.restaurant_ids.length > 0) {
        axios.get(
      "/reports/daily/multiple-restaurant-guest?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + "&restaurants=" + this.restaurant_ids
        ).then((res) => {
          this.report = res.data;
        });

        axios.get(
      "/reports/daily/multiple-restaurant-guest/companies?date=" + moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + "&restaurants=" + this.restaurant_ids
        ).then((res) => {
          this.report2 = res.data;
        });

        axios.get(
      "/reports/daily/multiple-total-guest?date=" + moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + "&restaurants=" + this.restaurant_ids
        ).then((res) => {
          this.total = res.data;
        });

        let mealFood = []
        let dates1 = {}
        this.incomeReport = []
          axios.get("/reports/daily/company/multiple-no-contract?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + '&status=' + this.status_id.id + "&restaurants=" + this.restaurant_ids).then((res) => {
          let datum = ''
          let totalNarudzbe = 0
          let totalCena = 0
          for (let i in res.data) {
            dates1[i] = ''
            dates1[i] = this.sort(res.data[i])
            for (let n in dates1[i]) {
              let price = 0
              let id = ''
              let porudzbe = []
              let children = []
              totalNarudzbe += dates1[i][n].length
              dates1[i][n].filter(order => {
                order.orders_guest.filter(o => {
                  datum = o.date
                  id = o.id
                  o.order_number = o.id
                  children.push(o)
                  porudzbe.push(o.order_guest_transactions)
                  let cena = 0
                  o.order_guest_transactions.filter(ord => {
                    //ord.porudzba = o
                    if (ord.prilog.length) {
                      ord.prilog.filter(pr => {
                        if (pr.quantity > 0) {
                          price += pr.price * pr.quantity
                          totalCena += pr.price * pr.quantity
                          cena += pr.price * pr.quantity
                        } else {
                          price += pr.price
                          totalCena += pr.price
                          cena += pr.price
                        }
                      })
                    }
                    mealFood.push(ord)
                    price += ord.price * ord.quantity
                    cena += ord.price * ord.quantity
                    totalCena += ord.price * ord.quantity
                  })
                  o.price = cena
                })
                order.orders = mealFood
              })
              this.incomeReport.push({
                'day': moment(datum).format('DD'),
                'date': datum,
                'order_id': id,
                'order_number': dates1[i][n].length,
                'price': price.toFixed(2),
                'orders': porudzbe,
                'children': children,
              })
            }
          }
          this.incomeReport.push({
            'date': 'UKUPNO',
            'order_number': totalNarudzbe,
            'price': totalCena.toFixed(2),
            'action': 0,
          })
          this.noContract = dates1;
          this.noContractOriginal = dates1;
        });

        axios.get(
      "/reports/daily/restaurant/multiple-rezervni-obroci?company=" + this.search5 + "&date=" + moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + "&restaurants=" + this.restaurant_ids
        ).then((data) => {
          this.reservedItemsData = data.data
        })
        axios.get(
      "/reports/daily/restaurant/multiple-child-restaurant?date=" + moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + "&restaurants=" + this.restaurant_ids
        ).then((data) => {
          let ne = true
          if (data.data.data != 'Restoran nema decu') {
            this.items1.filter(itm => {
              if (itm.tab == "Ресторани") {
                ne = false
              }
            })
            if (ne) {
              this.items1.push(
                  {
                    tab: this.$t('restaurants'),
                    content: "Tab 9 Content",
                    print: "table9",
                    icon: "mdi-shopping-outline",
                  },
              )
            }
          }
          this.childRestorans = data.data
        })

        axios.get("/companies/companies-delivery").then((res) => {
          this.companies_delivery = res.data
        });
        this.delivery_company_monthly_report = ''
        let dates = {}
        dates['date'] = moment(this.date[0]).format("YYYY-MM-DD")
        dates['date2'] = moment(this.date[1]).format("YYYY-MM-DD")
        axios.post("/orders/get-delivery-company-monthly-report", dates).then(({data}) => {
          this.delivery_company_monthly_report = data
        })
      }
    },
    load() {
      this.search = "";
      this.search2 = "";
      this.search3 = "";
      this.search4 = "";
      this.search5 = "";
      this.meals = [];
      if (this.date && this.date.length == 1) {
        this.date.push(this.date[0]);
      }

      // axios.get('/orders/delivery-user-orders?user_id=' + this.$store.state.user.id).then(({data}) => {
      //
      // })
      if (this.date && this.date[0] > this.date[1])
        [this.date[0], this.date[1]] = [this.date[1], this.date[0]];
      if (this.restaurant_ids.length && this.$store.state.user.type == 'admin') {
        this.ordersForSelectedRestaurants()
        return
      } else
      if (this.$store.state.restaurant) {
        if (this.$store.state.user.type != 'report_guest') {
          axios.get(
              "/reports/daily/restaurant-guest/" +
              this.$store.state.restaurant.id +
              "?date=" +
              moment(this.date[0]).format("YYYY-MM-DD") +
              "&date2=" +
              moment(this.date[1]).format("YYYY-MM-DD")
          )
              .then((res) => {
                //console.log(this.report);
                this.report = res.data;
              });
        }

        if (this.$store.state.user.type != 'report_guest') {
          axios.get(
              "/reports/daily/restaurant-guest/" +
              this.$store.state.restaurant.id +
              "/companies?date=" +
              moment(this.date[0]).format("YYYY-MM-DD") +
              "&date2=" +
              moment(this.date[1]).format("YYYY-MM-DD")
          )
              .then((res) => {
                this.report2 = res.data;
              });

          axios.get(
            "/reports/daily/total-guest/" +
            this.$store.state.restaurant.id +
            "?date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")
          )
          .then((res) => {
            this.total = res.data;
          });
        }
        let mealFood = []
        let dates1 = {}
        this.incomeReport = []
        axios.get("/reports/daily/company/" +
          this.$store.state.restaurant.id +
          "/no-contract?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + '&status=' + this.status_id.id).then((res) => {
          let datum = ''
          let totalNarudzbe = 0
          let totalCena = 0
          let ukupnaCena = 0
          for (let i in res.data) {
            dates1[i] = ''
            dates1[i] = this.sort(res.data[i])
            for (let n in dates1[i]) {
              let price = 0
              let id = ''
              let porudzbe = []
              let children = []
              totalNarudzbe += dates1[i][n].length
              dates1[i][n].filter(order => {
                order.orders_guest.filter(o => {
                  datum = o.date
                  id = o.id
                  o.order_number = o.id
                  children.push(o)
                  porudzbe.push(o.order_guest_transactions)
                  let cena = 0
                  o.order_guest_transactions.filter(ord => {
                    //ord.porudzba = o
                    if (ord.prilog.length) {
                      ord.prilog.filter(pr => {
                        if (pr.quantity > 0) {
                          price += pr.price * pr.quantity
                          totalCena += pr.price * pr.quantity
                          cena += pr.price * pr.quantity
                        }
                        else {
                          price += pr.price
                          totalCena += pr.price
                          cena += pr.price
                        }
                      })
                    }
                    if (ord.meals || ord.food) {
                      ukupnaCena += ord.price * ord.quantity
                    }
                    mealFood.push(ord)
                    price += ord.price * ord.quantity
                    cena += ord.price * ord.quantity
                    totalCena += ord.price * ord.quantity
                  })
                  o.price = cena
                })
                order.orders = mealFood
              })
              dates1[i][n].push({
                'orders_guest': [{
                  'id': this.translate('УКУПНО'),
                  'order_guest_transactions': []
                }],
                'num': 'УКУПНО',
                'total_price': true,
                'name': '',
                'lastname': '',
                'total': 'UKUPNO',
                'price': price
              })
              this.incomeReport.push({
                'day': moment(datum).format('DD'),
                'date': datum,
                'order_id': id,
                'order_number': dates1[i][n].length,
                'price': price.toFixed(2),
                'orders': porudzbe,
                'children': children,
              })
            }
          }

          this.incomeReport.push({
            'date': 'UKUPNO',
            'order_number': totalNarudzbe,
            'price': totalCena.toFixed(2),
            'action': 0,
          })
          this.noContract = dates1;
          this.noContractOriginal = dates1;
          this.$forceUpdate()
        });

        // axios.get("/reports/daily/company/" +
        //     this.$store.state.restaurant.id +
        //     "/no-contract-next-day?date=" + moment(this.date[0]).format("YYYY-MM-DD") + '&status=' + this.status_id.id).then((res) => {
        //       console.log(res.data)
        // });
        axios.get(
            "/reports/daily/restaurant/" +
            this.$store.state.restaurant.id +
            "/rezervni-obroci?company=" + this.search5 + "&date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")
        ).then((data) => {
          this.reservedItemsData = data.data
        })
        axios.get(
            "/reports/daily/restaurant/" +
            this.$store.state.restaurant.id +
            "/child-restaurant?date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")
        ).then((data) => {
          let ne = true
          if (data.data.data != 'Restoran nema decu') {
            this.items1.filter(itm => {
              if (itm.tab == "Ресторани") {
                ne = false
              }
            })
            if (ne) {
              this.items1.push(
                {
                  tab: this.$t('restaurants'),
                  content: "Tab 9 Content",
                  print: "table9",
                  icon: "mdi-shopping-outline",
                },
              )
            }
          }
          this.childRestorans = data.data
        })
        this.getTableData()
        if (this.$store.state.user.type != 'report_guest') {
          axios.get("/companies/companies-delivery").then((res) => {
            this.companies_delivery = res.data
          });
        }
        this.delivery_company_monthly_report = ''
        let dates = {}
        dates['date'] = moment(this.date[0]).format("YYYY-MM-DD")
        dates['date2'] = moment(this.date[1]).format("YYYY-MM-DD")
        axios.post("/orders/get-delivery-company-monthly-report", dates).then(({data}) => {
          this.delivery_company_monthly_report = data
        })
      }
    },
    findShift(num) {
      switch (num) {
        case "1-smena":
          return "shift_one";
          break;
        case "2-smena":
          return "shift_two";
          break;
        case "3-smena":
          return "shift_three";
          break;
      }
    },
    groupBy(array, key){
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]){
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    }
  },

  computed: {
    ukupnoPorudzbi() {
      if (this.noContract) {
        let porudzbe = []
        for (let i in this.noContract) {
          for (let n in this.noContract[i]) {
            this.noContract[i][n].filter(item => {
              if (!item.total) {
                porudzbe.push(item)
              }
            })
          }
        }
        return porudzbe
      }
    },
    month() {
      return parseInt(this.date[0].split('-')[1])
    },
    year() {
      return parseInt(this.date[0].split('-')[0])
    },
    dessertsDeliveryCompany() {
      let report = []
      if (this.delivery_company_monthly_report) {
        for (let i in this.delivery_company_monthly_report) {
          let isporucene = 0
          let preuzete = 0
          let rezervisane = 0
          let vracane = 0
          if (this.delivery_company_monthly_report[i]) {
            isporucene += this.delivery_company_monthly_report[i].isporucene_narudzbe ? this.delivery_company_monthly_report[i].isporucene_narudzbe.length : 0
            preuzete += this.delivery_company_monthly_report[i].preuzete_narudzbe ? this.delivery_company_monthly_report[i].preuzete_narudzbe.length : 0
            rezervisane += this.delivery_company_monthly_report[i].rezervisane_narudzbe ? this.delivery_company_monthly_report[i].rezervisane_narudzbe.length : 0
            vracane += this.delivery_company_monthly_report[i].vracane ? this.delivery_company_monthly_report[i].vracane.length : 0
            report.push({
              'name': i,
              'total_isporucene': isporucene,
              'total_preuzete': preuzete,
              'total_rezervisane': rezervisane,
              'total_vracene': vracane,
              'total': isporucene + preuzete + rezervisane + vracane,
              'company_id': this.delivery_company_monthly_report[i].company
            })
          }
        }
      }
      return report
    },
    containerClass() {
      if (window.innerWidth <= 768) {
        return '85%';
      } else if (window.innerWidth <= 1024) {
        return '75%';
      } else {
        return '75%';
      }
    },
    containerClassTable() {
      if (window.innerWidth <= 768) {
        return '75%';
      } else if (window.innerWidth <= 1024) {
        return '65%';
      } else {
        return '65%';
      }
    },
    ordersClass() {
      if (window.innerWidth <= 768) {
        return '95%';
      }
      else if (window.innerWidth <= 820) {
        return '75%';
      }
      else if (window.innerWidth <= 1024) {
        return '45%';
      } else {
        return '47%';
      }
    },
    desserts6() {
      let arr2 = [];
      if (this.reservedItemsData) {
        for (let company in this.reservedItemsData) {
          arr2.push({ company: company, foods: [] });
          for (let shift in this.reservedItemsData[company]) {
            for (let shift2 in this.reservedItemsData[company][shift]) {
              let index = arr2.findIndex((i) => i.company === company);
              let index2 = arr2[index].foods.findIndex(
                  (i) => i.name === shift2
              );
              if (index2 > -1) {
                arr2[index].foods[index2].shift_one +=
                    shift === "1-smena"
                        ? this.reservedItemsData[company][shift][shift2].quantity
                        : 0;
                arr2[index].foods[index2].shift_one_payed +=
                    shift === "1-smena"
                        ? this.reservedItemsData[company][shift][shift2].quantity_payed
                        : 0;
                arr2[index].foods[index2].shift_two +=
                    shift === "2-smena"
                        ? this.reservedItemsData[company][shift][shift2].quantity
                        : 0;
                arr2[index].foods[index2].shift_two_payed +=
                    shift === "2-smena"
                        ? this.reservedItemsData[company][shift][shift2].quantity_payed
                        : 0;
                (arr2[index].foods[index2].shift_three +=
                    shift === "3-smena"
                        ? this.reservedItemsData[company][shift][shift2].quantity
                        : 0),
                    (arr2[index].foods[index2].shift_three_payed +=
                        shift === "3-smena"
                            ? this.reservedItemsData[company][shift][shift2].quantity_payed
                            : 0),
                    (arr2[index].foods[index2].total += this.reservedItemsData[company][
                        shift
                        ][shift2].quantity),
                    (arr2[index].foods[index2].total_payed += this.reservedItemsData[company][
                        shift
                        ][shift2].quantity_payed);
              } else {
                arr2[index].foods.push({
                  name: shift2,
                  id: this.reservedItemsData[company][shift][shift2].id,
                  shift_one:
                      shift === "1-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity
                          : 0,
                  shift_one_payed:
                      shift === "1-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity_payed
                          : 0,
                  shift_two:
                      shift === "2-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity
                          : 0,
                  shift_two_payed:
                      shift === "2-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity_payed
                          : 0,
                  shift_three:
                      shift === "3-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity
                          : 0,
                  shift_three_payed:
                      shift === "3-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity_payed
                          : 0,
                  total: this.reservedItemsData[company][shift][shift2].quantity,
                  total_payed: this.reservedItemsData[company][shift][shift2].quantity_payed,
                });
              }
            }
          }
        }

        arr2.forEach((company, index) => {
          company.foods.push({
            name: "УКУПНО",
            shift_one: company.foods.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0),
            shift_one_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_one_payed;
            }, 0),
            shift_two: company.foods.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0),
            shift_two_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_two_payed;
            }, 0),
            shift_three: company.foods.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
            shift_three_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_three_payed;
            }, 0),
            total:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three;
                }, 0),
            total_payed:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three_payed;
                }, 0),
          });
        });
      }

      return arr2;
    },
    desserts5() {
      let arr2 = [];
      let a = {}
      if (this.reservedItems) {
        this.reservedItems.filter((item) => {
          a[item.name] = item
        })
      }
      for(let i in a) {
        arr2.push({'company': i})
      }

      return arr2;
    },
    noContractCompanies() {
      let data = []
      for (let i in this.noContract) {
        data.push({company: i})
      }
      return data
    },
    noContractDelivery() {
      let test = []
      let meals = []
      for (let i in this.noContract) {
        if (i == 'IsporukaZaNeregistrovane') {
          this.noContract[i][0].orders_guest.filter( meal => {
            test.push(meal)
            meal.order_guest_transactions.filter(food => {
              food.date = meal.date + ' | ' + meal.delivery_time
              meals.push(food)
            })
          })
        }
      }
      let qty = 0;
      let pr = 0;
      test.filter(itm => {
        itm.order_guest_transactions.filter(q => {
          qty += q.quantity
          pr += q.price * q.quantity
        })
      })
      test.push({
        name: "УКУПНО",
        price: '',
        quantity: qty,
        qty: pr
      })
      // meals.push({
      //   name: "УКУПНО",
      //   price: '',
      //   quantity: meals.reduce((sum, item) => {
      //     return sum + item.quantity;
      //   }, 0),
      //   total: meals.reduce((sum, item) => {
      //     return sum + (item.price * item.quantity);
      //   }, 0)
      // })
      return test
    },
    filterReservedMeals() {
      let arr2 = []
      if (this.search5.length > 0) {
        if (this.reservedItemsData) {
          this.search5.filter((company) => {
            this.reservedItemsData.filter(item => {
              if(item.company.name == company) {
                item.meals.foods.filter((food,ind) => {
                  arr2.push({
                    'id': food.id,
                    'name': food.name,
                    'shift_one': item.shift == '1-smena' ? item.meals.shift_one : 0,
                    'shift_two': item.shift == '2-smena' ? item.meals.shift_two : 0,
                    'shift_three': item.shift == '3-smena' ? item.meals.shift_three : 0,
                    'total': food.total
                  })
                })
              }
            })
          })
        }
      }

      let newArr = this.groupBy(arr2, 'name')

      let name = ''
      let id = ''
      let finalArray = []
      for (let i in newArr) {
        let shift_one = 0
        let shift_two = 0
        let shift_three = 0
        let totalN = 0
        newArr[i].filter((item) => {
          if (item.name == i) {
            name = i;
            id = item.id;
            shift_one += item.shift_one;
            shift_two += item.shift_two;
            shift_three += item.shift_three;
            totalN += item.shift_one + item.shift_two + item.shift_three;
          }
        })
        finalArray.push({
          'id': id,
          'name': name,
          'shift_one': shift_one,
          'shift_two': shift_two,
          'shift_three': shift_three,
          'total': totalN,
        })

      }
      let result = [...finalArray];
      let total_shift_one = 0
      let total_shift_two = 0
      let total_shift_three = 0
      let total_totalN = 0
      result.filter(itm => {
        total_shift_one += itm.shift_one;
        total_shift_two += itm.shift_two;
        total_shift_three += itm.shift_three;
        total_totalN += itm.shift_one + itm.shift_two + itm.shift_three;
      })
      result.push({
        'id': '',
        'name': 'УКУПНО',
        'shift_one': total_shift_one,
        'shift_two': total_shift_two,
        'shift_three': total_shift_three,
        'total': total_totalN,
      })

      // const index = result.findIndex(e => e.name === 'УКУПНО')
      // result.push(result.splice(index, 1)[0])
      return result
    },
    filterMealsTotal() {
      let arr2 = []
      if (this.search.length > 0) {
        this.search.filter((company) => {
          this.desserts4.filter(item => {
            if(item.company == company) {
              item.foods.filter((food,ind) => {
                arr2.push({
                  'id': food.id,
                  'name': food.name,
                  'shift_one': food.shift_one,
                  'shift_two': food.shift_two,
                  'shift_three': food.shift_three,
                  'total': food.total
                })
             })
            }
          })
        })
      }
      let newArr = this.groupBy(arr2, 'name')

      let name = ''
      let id = ''
      let finalArray = []
      for (let i in newArr) {
        let shift_one = 0
        let shift_two = 0
        let shift_three = 0
        let totalN = 0
        newArr[i].filter((item) => {
          if (item.name == i) {
            name = i;
            id = item.id;
            shift_one += item.shift_one;
            shift_two += item.shift_two;
            shift_three += item.shift_three;
            totalN += item.total;
          }
        })
        finalArray.push({
          'id': id,
          'name': name,
          'shift_one': shift_one,
          'shift_two': shift_two,
          'shift_three': shift_three,
          'total': totalN,
        })
      }
      let result = [...finalArray];

      const index = result.findIndex(e => e.name === 'УКУПНО')
      result.push(result.splice(index, 1)[0])
      return result
    },
    filterObrok() {
      let arr2 = []
      if (this.search4.length > 0) {
        this.search4.filter((company) => {
          this.obroci.filter(item => {
            if(item.company == company) {
              item.foods.filter((food,ind) => {
                arr2.push({
                  'id': food.id,
                  'name': food.name,
                  'shift_one': food.shift_one,
                  'shift_two': food.shift_two,
                  'shift_three': food.shift_three,
                  'total': food.total
                })
              })
            }
          })
        })
      }
      let newArr = this.groupBy(arr2, 'name')

      let name = ''
      let id = ''
      let finalArray = []
      for (let i in newArr) {
        let shift_one = 0
        let shift_two = 0
        let shift_three = 0
        let totalN = 0
        newArr[i].filter((item) => {
          if (item.name == i) {
            name = i;
            id = item.id;
            shift_one += item.shift_one;
            shift_two += item.shift_two;
            shift_three += item.shift_three;
            totalN += item.total;
          }
        })
        finalArray.push({
          'id': id,
          'name': name,
          'shift_one': shift_one,
          'shift_two': shift_two,
          'shift_three': shift_three,
          'total': totalN,
        })
      }
      let result = [...finalArray];

      const index = result.findIndex(e => e.name === 'УКУПНО')
      result.push(result.splice(index, 1)[0])
      return result
    },
    dateRangeText() {
      if (this.date.length == 1 || this.date[0] == this.date[1]) {
        return moment(this.date[0]).format("DD.MM.YYYY");
      }
      return (
        moment(this.date[0]).format("DD.MM.YYYY") +
        " - " +
        moment(this.date[1]).format("DD.MM.YYYY")
      );
    },
    desserts() {
      let arr = [];
      if (this.report) {
        for (let shift in this.report) {
          for (let shift2 in this.report[shift]) {
            let index = arr.findIndex((i) => i.menu === shift2);

            if (index > -1) {
              arr[index].shift_one +=
                shift === "1-smena" ? this.report[shift][shift2].quantity : 0;
              arr[index].shift_two +=
                shift === "2-smena" ? this.report[shift][shift2].quantity : 0;
              arr[index].shift_three +=
                shift === "3-smena" ? this.report[shift][shift2].quantity : 0;
              arr[index].total += this.report[shift][shift2].quantity;
            } else {
              arr.push({
                menu: shift2,
                id: this.report[shift][shift2].id,
                shift_one:
                  shift === "1-smena" ? this.report[shift][shift2].quantity : 0,
                shift_two:
                  shift === "2-smena" ? this.report[shift][shift2].quantity : 0,
                shift_three:
                  shift === "3-smena" ? this.report[shift][shift2].quantity : 0,
                total: this.report[shift][shift2].quantity,
              });
            }
          }
        }

        arr.push({
          menu: "УКУПНО",
          shift_one: arr.reduce((sum, item) => {
            return sum + item.shift_one;
          }, 0),
          shift_two: arr.reduce((sum, item) => {
            return sum + item.shift_two;
          }, 0),
          shift_three: arr.reduce((sum, item) => {
            return sum + item.shift_three;
          }, 0),
          total:
            arr.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0) +
            arr.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0) +
            arr.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
        });
      }
      //console.log(arr);
      return arr;
    },
    desserts2() {
      let arr = [];
      let arr8 = [];
      if (this.report) {
        for (let shift in this.report) {
          for (let shift2 in this.report[shift]) {
            this.report[shift][shift2].foods.forEach((food) => {
              let index = arr.findIndex((i) => i.menu === food.name);
              if (index > -1) {
                arr[index].shift_one += shift === "1-smena" ? this.report[shift][shift2].quantity : 0;
                arr[index].shift_two += shift === "2-smena" ? this.report[shift][shift2].quantity : 0;
                arr[index].shift_three += shift === "3-smena" ? this.report[shift][shift2].quantity : 0;
                arr[index].total += this.report[shift][shift2].quantity;
              } else {
                arr.push({
                  id: food.id,
                  menu: food.name,
                  shift_one: shift === "1-smena" ? this.report[shift][shift2].quantity : 0,
                  shift_two: shift === "2-smena" ? this.report[shift][shift2].quantity : 0,
                  shift_three: shift === "3-smena" ? this.report[shift][shift2].quantity : 0,
                  total: this.report[shift][shift2].quantity,
                });
              }
            });
            if (this.report[shift][shift2].prilog.length) {
              this.report[shift][shift2].prilog.filter(item => {
                arr8.push({
                  'name' : item.food.name,
                  'price' : item.food.price,
                  'quantity' : item.quantity,
                })
              })
            }
          }
        }
        let prilozi = this.groupBy(arr8, 'name')
        let prepArr = []
        for (let i in prilozi) {
          let qty = 0
          prilozi[i].filter(item => {
            qty += item.quantity
          })
          prepArr.push({
            'menu': i,
            'shift_one': qty,
            'shift_two': 0,
            'shift_three': 0,
            'total': qty,
          })
        }
        if (this.prilog) {
          prepArr.filter(item => {
            arr.push(item)
          })
        }
        arr.push({
          menu: "УКУПНО",
          shift_one: arr.reduce((sum, item) => {
            return sum + item.shift_one;
          }, 0),
          shift_two: arr.reduce((sum, item) => {
            return sum + item.shift_two;
          }, 0),
          shift_three: arr.reduce((sum, item) => {
            return sum + item.shift_three;
          }, 0),
          total:
            arr.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0) +
            arr.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0) +
            arr.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
        });
      }
      return arr;
    },
    desserts3() {
      let arr = [];

      if (this.report) {
        for (let shift in this.report) {
          for (let shift2 in this.report[shift]) {
            this.report[shift][shift2].foods.forEach((food) => {
              if (
                typeof food.groceries_total != "undefined" &&
                Array.isArray(food.groceries_total)
              ) {
                food.groceries_total.forEach((grocery) => {
                  let index = arr.findIndex((i) => i.menu === grocery.name);

                  if (index > -1) {
                    arr[index].shift_one +=
                      shift === "1-smena" ? grocery.quantity : 0;
                    arr[index].shift_two +=
                      shift === "2-smena" ? grocery.quantity : 0;
                    arr[index].shift_three +=
                      shift === "3-smena" ? grocery.quantity : 0;
                    arr[index].total += grocery.quantity;
                  } else {
                    arr.push({
                      menu: grocery.name,
                      shift_one: shift === "1-smena" ? grocery.quantity : 0,
                      shift_two: shift === "2-smena" ? grocery.quantity : 0,
                      shift_three: shift === "3-smena" ? grocery.quantity : 0,
                      total: grocery.quantity,
                    });
                  }
                });
              }
            });
          }
        }
      }

      return arr;
    },
    desserts4() {
      let arr2 = [];
      if (this.report2) {
        for (let company in this.report2) {
          arr2.push({ company: company, foods: [] });
          for (let shift in this.report2[company]) {
            for (let shift2 in this.report2[company][shift]) {
              let index = arr2.findIndex((i) => i.company === company);
              let index2 = arr2[index].foods.findIndex(
                (i) => i.name === shift2
              );
              if (index2 > -1) {
                arr2[index].foods[index2].shift_one +=
                  shift === "1-smena"
                    ? this.report2[company][shift][shift2].quantity
                    : 0;
                arr2[index].foods[index2].shift_one_payed +=
                  shift === "1-smena"
                    ? this.report2[company][shift][shift2].quantity_payed
                    : 0;
                arr2[index].foods[index2].shift_two +=
                  shift === "2-smena"
                    ? this.report2[company][shift][shift2].quantity
                    : 0;
                arr2[index].foods[index2].shift_two_payed +=
                  shift === "2-smena"
                    ? this.report2[company][shift][shift2].quantity_payed
                    : 0;
                (arr2[index].foods[index2].shift_three +=
                  shift === "3-smena"
                    ? this.report2[company][shift][shift2].quantity
                    : 0),
                    (arr2[index].foods[index2].shift_three_payed +=
                  shift === "3-smena"
                    ? this.report2[company][shift][shift2].quantity_payed
                    : 0),
                  (arr2[index].foods[index2].total += this.report2[company][
                    shift
                  ][shift2].quantity),
                  (arr2[index].foods[index2].total_payed += this.report2[company][
                    shift
                  ][shift2].quantity_payed);
              } else {
                arr2[index].foods.push({
                  name: shift2,
                  id: this.report2[company][shift][shift2].id,
                  shift_one:
                    shift === "1-smena"
                      ? this.report2[company][shift][shift2].quantity
                      : 0,
                  shift_one_payed:
                    shift === "1-smena"
                      ? this.report2[company][shift][shift2].quantity_payed
                      : 0,
                  shift_two:
                    shift === "2-smena"
                      ? this.report2[company][shift][shift2].quantity
                      : 0,
                  shift_two_payed:
                    shift === "2-smena"
                      ? this.report2[company][shift][shift2].quantity_payed
                      : 0,
                  shift_three:
                    shift === "3-smena"
                      ? this.report2[company][shift][shift2].quantity
                      : 0,
                  shift_three_payed:
                    shift === "3-smena"
                      ? this.report2[company][shift][shift2].quantity_payed
                      : 0,
                  total: this.report2[company][shift][shift2].quantity,
                  total_payed: this.report2[company][shift][shift2].quantity_payed,
                });
              }
            }
          }
        }

        arr2.forEach((company, index) => {
          company.foods.push({
            name: "УКУПНО",
            shift_one: company.foods.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0),
            shift_one_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_one_payed;
            }, 0),
            shift_two: company.foods.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0),
            shift_two_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_two_payed;
            }, 0),
            shift_three: company.foods.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
            shift_three_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_three_payed;
            }, 0),
            total:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
            total_payed:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three_payed;
              }, 0),
          });
        });
      }

      return arr2;
    },
  },

  mounted() {
    this.getNotifications()
  }
};
</script>
<style>

</style>
<style scoped>
.location {
  font-weight: 500;display: block;text-align: center
}
.v-data-table >>> tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05) !important;
}
table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05) !important;
}
div.v-select__selections span.v-size--small span.v-chip__content {
  font-size: 10px !important;
}
.v-alert__icon.v-icon {
  font-size: 15px !important;
}
.dialog-status {
    font-size:15px;color: rgba(129,129,129,0.94);padding: 20px;text-decoration: none;margin: 7px;display: flex;align-items: center;justify-content: center;border-radius: 12px;
}
</style>
<style>
.font15 {
  font-size: 15px;
}
.activeTab {
  background: #fb6d3b;
  color: #fff !important;
}
.rest-days {
  border: 1px solid rgb(251, 109, 59);
  padding: 3px 3px;
  font-size: 10px;
  border-radius: 9px;
  text-decoration: none
}
.status-change {
  text-decoration: none;display: inline-block;text-align:center;border-radius: 12px;padding: 4px 0;width: 150px
}
.all-orders {
  border-bottom: 1px dashed #cfcfcf;width: 98%;margin-right: -35px;position: relative
}
.all-orders-item {
  width: 85%;padding-bottom: 2px;font-size: 15px;color: #000000;font-weight: 500
}
.all-orders-items {
  width: 100%;padding-bottom: 2px;font-size: 15px;color: #000000;font-weight: 500
}
.order-address {
  font-size: 11px;font-weight: 500;text-align: left;width: 145px;display: block
}

.all-orders-item-prilog {
  width: 50%;text-align: right;font-size: 15px;padding-bottom: 2px;padding-right: 15px
}
.all-orders-qty {
  width: 15%;text-align: right;font-size: 15px;padding-bottom: 2px;
}
.all-order-else {
  width: 100%;margin-right: -35px;align-items: center;justify-content: space-between
}






@media(max-width: 767px) {
  #izv .v-data-table__mobile-row__cell {
    text-align: right !important;
    width: 100%;
    font-size: 10px;
  }
  #korisnik > div {
    padding: 0 !important;
  }
  #korisnik .v-card__title, #korisnik .v-card__text {
    font-size: 16px !important;
    padding: 0 !important;
  }
  #stampaj {
    display: none;
  }
  body {
    font-size: 11px !important;
  }
}
@media(max-width: 830px) {
  .tablet_view_item {
    width: 48% !important;
  }
  #ord_num {
    margin-top: -7px !important;
  }
  body, .v-tabs:not(.v-tabs--vertical) .v-tab, .v-btn__content, .v-card__text, .v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
    font-size: 11px !important;
  }
  .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th, .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 9px !important;
  }
  .status-change {
    width: 100px;
  }
  .all-orders {
    width: 300px;
  }
  .all-order-else {
    width: 203px;
  }
  .all-orders-item {
    font-size: 9px !important;
    width: 80%;
  }
  .all-orders-items {
    font-size: 9px !important;
    width: 80%;
  }
  .order-address {
    width: 100%;
    font-size: 10px !important;
    text-align: left;
  }
  .location {
    text-align: left !important;
    font-size: 10px;
  }
  .all-orders-item-prilog {
    width: 80% !important;
    font-size: 12px;
  }
  .all-orders-qty {
    padding-right: 11px;
  }
  #promena-statusa {
    width: 45% !important;
    margin-bottom: 1px !important;
  }
  table tr td {
    font-size: 20px !important;
  }

}
@media(max-width: 1025px) {
 #stampa {
   margin-top: 4px !important;
   margin-bottom: 20px !important;
 }
}
@media(max-width: 1200px) {
  #promena-statusa {
    width: 45% !important;
    margin-bottom: 1px !important;
  }
  #rest-days-main {
    display: block !important;
  }
  #rest-days-main a {
    display: block;
    margin-bottom: 3px;
  }
}
@media(max-width: 800px) {
  .font-ikonice {
    display: none;
  }
  #order-status {
    display: block;
  }
  #order-status a {
    width: 100% !important;
    margin: 4px 10px !important;
  }
  #order-status a .dialog-status {
    margin: 0 !important;
    padding: 10px;
  }
}
@media(max-width: 800px) {
  .tablet_view_item {
    width: 48% !important;
  }
  #ord_num {
    margin-top: 1px !important;
  }
  .font-ikonice {
    display: none;
  }
  #order-status {
    display: block;
  }
  #order-status a {
    width: 100% !important;
    margin: 4px 10px !important;
  }
  #order-status a .dialog-status {
    margin: 0 !important;
    padding: 10px;
  }
  #rest-days-main {
    display: block !important;
  }
  #rest-days-main a {
    display: block;
  }
  #promena_statusa {
    font-size: 15px;
  }
}
@media(max-width: 599px) {
  .tablet_view {
    display: block !important;
  }
  .tablet_view_item {
    width: 100% !important;
  }
  #ord_num {
    margin-top: 5px !important;
  }
  #izv .v-data-table__mobile-row__cell {
    width: 100%;
  }
  .all-orders {
    width: 100% !important;
  }
  .all-orders .all-orders-qty {
    padding-right: 4px !important;
  }
  .all-orders .all-orders-item, .all-orders .all-orders-qty {
    width: 50%;
  }
  .all-orders .all-orders-item {
    text-align: left;
  }
  .font-ikonice {
    display: none;
  }
  #stampa {
    margin-top: 30px !important;
  }
  #rest-days-main {
    display: flex !important;
    margin-top: 10px;
  }
  #pretraga-ikonice div {
    margin-top: 1px !important;
    padding-top: 1px !important;
  }
  .ikonice-font {
    text-align: left !important;
  }
  .v-data-table__mobile-row__cell {
    text-align: right !important;
  }
  .v-data-table__mobile-row__cell, .v-data-table__mobile-row__cell span, .v-data-table__mobile-row__cell div {
    font-size: 17px !important;
  }
  .v-data-table__mobile-row {
    margin: 10px 0;
  }
}
@media(max-width: 400px) {
  .all-orders {
    width: 100% !important;
  }
  .all-orders-qty {
    padding-right: 6px
  }
  #rest-days-main {
    display: flex !important;
  }
}
@media print {
  div.test_print {
    display: none;
  }
  .v-main__wrap {
    max-width: 100vW;
  }
  table {
    padding: 0;
    margin-top: 40px;
    font-size: 80% !important;
    max-width: 100vW;
    border-collapse: collapse;
  }
  th, td {
    font-size: 10px !important;
    word-break: break-all;
    padding: 5px;
    word-wrap: break-word;
    white-space: normal;
    font-weight: 400;
  }
  .no-print, .v-data-footer {
    display: none;
  }
  .table-wrapper {
    overflow-x: auto;
  }

  .v-data-table {
    min-width: 600px; /* Adjust this to the minimum width you need */
  }
}
</style>
